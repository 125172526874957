import React, { useState } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { UserAddOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import { getAllCountries } from "API/supplierApi";
import LoadingOverlay from "components/Overlay";

import {
  getModemList,
  getServicePartner,
  deleteModemList,
  editModemList,
} from "API/modemListApi";
import ConfirmModal from "components/Modal/Modal";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";

const ModemListTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged,setPermissionChanged]=useState(true)
  const [loadings, setLoading] = useState(false);

  const modemListModuleID = parseInt(
    process.env.REACT_APP_MODEM_LIST_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    term_filter: selectedTerm,
    search_filter: searchInput,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: modemLists,
    refetch,
  } = useQuery(["modemLists", queryParams], getModemList, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingPaymentTypes,
    isError: isErrorPaymentTypes,
    error: errorPaymentTypes,
    data: servicePartnerTypes,
  } = useQuery("servicePartner", getServicePartner, {
    cacheTime: cacheDuration,
  });
  const paymentTypesData = { id: "", name: "All" };

  const servicePartnerChoices = servicePartnerTypes?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.short_name,
    };
  });

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesTransformed = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });
  const allData = { id: "", name: "All" };

  const termOptions = [
    { id: "1", name: "Short term" },
    { id: "2", name: "Long term" },
  ];
  const spTypesOptions = [
    { id: "1", name: "Main" },
    { id: "2", name: "Second" },
    { id: "3", name: "Third" },
  ];
  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  const modemEditor = useMutation(editModemList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      
      refetch();
      setLoading(false);
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      await refetch();
      await permissionRefetch();
      setPermissionChanged(true)
      setLoading(false);

    },
  });
  const removeModem = useMutation(deleteModemList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      refetch();
      setLoading(false);

    },
  });

  const handleEdit = (user) => {
    navigate("/edit-modem-list", { state: { data: user } });
  };
  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen&& selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: modemListModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
    setLoading(false);

  };
  const handleDelete = async (modem) => {
    const deleteApi = async () => {
      try {
        const response = await removeModem.mutateAsync({ id: modem.id });
        return response;
      } catch (error) {
      setLoading(false);

        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: deleteApi,
      deleteErrorText: "Modem is in use!",
      deleteSuccessMessage: "Modem deleted successfully",
    });
  };

  const handleToggle = async (id, status) => {
    const response = await modemEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };

  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[modemListModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }
    if (permissions.permissions[modemListModuleID].delete_access) {
      buttons.push(
        <DeleteButton
          key={`delete-${record?.id}`}
          onChangeHandler={() => handleDelete(record)}
          record={record}
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    {
      perm_id: fieldIds.modemList.name,
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      perm_id: fieldIds.modemList.defective_port,
      title: "Defective ports",
      dataIndex: "defective_port",
      render: (defective_port) => (
        <div
          style={{
            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {defective_port ? defective_port : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemList.term,
      title: "Term",
      dataIndex: "term",
      sorter: (a, b) =>
        String(a.term ?? "").localeCompare(String(b.term ?? "")),
    },
    {
      perm_id: fieldIds.modemList.status,
      title: "Status",
      dataIndex: "status",
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];
  const childColumns = [
    {
      perm_id: fieldIds.modemList.type,
      title: "Service Partner type",
      dataIndex: "type",
      width: "600px",
      render: (type) => (
        <div
          style={{
            width: "400px",
            whiteSpace: "pre-wrap",
          }}
        >
          {type ? type : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemList.service_partner_id,
      title: "Service Partner",
      dataIndex: "service_partner_id",
    },

    {
      perm_id: fieldIds.modemList.name_from_sp,
      title: "Name from Service Partner",
      dataIndex: "name_from_sp",
      width: "600px",
      render: (name_from_sp) => (
        <div
          style={{
            width: "600px",
            whiteSpace: "pre-wrap",
          }}
        >
          {name_from_sp ? name_from_sp : "-"}
        </div>
      ),
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      width: 150,
      maxWidth: 150,
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: modemLists?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        data={modemLists?.results}
        columns={columns}
        permissionSet={permissions.permissions[modemListModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        choiceData={{
          country: allCountriesTransformed,
          term: termOptions,
          service_partner_id: servicePartnerTypes ? servicePartnerChoices : "",
          type: spTypesOptions,
        }}
        expandableRow={true}
        childColumns={childColumns}
        showColumnState={showColumnState}
      />
    );
  }

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> Modem List
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[modemListModuleID].fields || []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={modemListModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged} 
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    
                    {permissions?.permissions[modemListModuleID].fields[66]
                      ?.view_access ? (
                      <DropdownFilter
                        title={selectedTerm ? selectedTerm.label : "Term"}
                        selectedValue={selectedTerm}
                        items={[allData, ...termOptions]}
                        onSelect={(e) => setSelectedTerm(e)}
                        filterName="Term"
                        enableSearch={true}
                      />
                    ) : (
                      ""
                    )}

                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | Name "
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center top-table-buttons"
                  >
                 
                    {isAddAllowed(
                      permissions.permissions[modemListModuleID]
                    ) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-modem-list")}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ModemListTable;
