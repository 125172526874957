import React from "react";
import AddModemListForm from "components/ModemList/AddModemListForm";
const AddModemList = () => {
  return (
    <div>
      <AddModemListForm />
    </div>
  );
};

export default AddModemList;
