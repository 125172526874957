import React from "react";
import ExpirationListTable from "components/Table/ExpirationListTable";
const ExpirationListView = () => {
  return (
    <div>
      <ExpirationListTable />
    </div>
  );
};

export default ExpirationListView;
