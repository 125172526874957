import React from "react";
import AddPCModemView from "views/PCModem/AddPCModemView";
const AddPCModem = () => {
  return (
    <div>
      <AddPCModemView />
    </div>
  );
};

export default AddPCModem;
