import { makeRequest } from "helpers/apiHelper";

const baseURL = process.env.REACT_APP_API_BASE_URL;


export const getStorageBoxes = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/storagebox/storage-box/?${queryParamsString}`;
  return makeRequest("get", url);
};


export const createStorageBox = async (data) => {
  return makeRequest("post", "/storagebox/storage-box/", data);
};

export const editStorageBox = async ({ id, data }) => {
  return makeRequest("patch", `/storagebox/storage-box/${id}/`, data);
};

export const deleteStorageBox = ({id}) => {
  return makeRequest("delete", `/storagebox/storage-box/${id}/`,);
};