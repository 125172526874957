// ModalComponent.jsx
import React, { useState } from "react";
import { Modal } from "antd";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import CancelButton from "components/CancelButton/CancelButton";
import usePermissions from "customHooks/usePermissions";
import { addBulkExpiration } from "API/expirationListAPI";
import { useMutation } from "react-query";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import showToast from "components/Toast/Toast";
import { LoadingOutlined } from "@ant-design/icons";
import LoadingOverlay from "components/Overlay";

const AddBulkFileForm = ({ visible, onOk, onCancel, expirationListID }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [loadings, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const modemDashModuleID = parseInt(
    process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID,
    10
  );

  const addBulkEditor = useMutation(addBulkExpiration, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      await showToast({
        icon: "success",
        title: "Bulk add with excel completed successfully!",
        position: "top-end",
        timer: 1500,
      });
      setConfirmLoading(false);
      onOk();
      reset();
    },
  });

  const onSubmit = async (data) => {
    setApiError(false);
    setConfirmLoading(true);
    const formData = new FormData();

    formData.append("csv_file", data.fileUpload ? data.fileUpload[0] : "");

    try {
      const response = await addBulkEditor.mutateAsync(formData);
    } catch (error) {
      setConfirmLoading(false);
      if (error.response?.status == 400) {
      setLoading(false);

        setApiError(error.response?.data?.message);
      } else {
        setLoading(false);
        setConfirmLoading(false);
        showServerMessage();
      }
    }
  };

  return (
    <Modal
      title="Add excel file"
      visible={visible}
      confirmLoading={confirmLoading}
      footer={[]}
      onCancel={(e) => {
        onCancel();
        reset();
      }}
    >
       <LoadingOverlay isLoading={loadings} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="user-fields-wrapper modal-form">
            <Row className="">
              {
                <Col className="" md="12">
                  <Form.Group className="mb-3">
                    {/* <Form.Label>Select excel file</Form.Label> */}
                    <Form.Control
                      type="file"
                      accept=".xlsx"
                      {...register("fileUpload", {
                        validate: (file) => {
                          if (!file) return true; // No file selected, no error

                          const fileName = file[0]?.name || "";
                          const allowedFormats = [".xlsx"];
                          const isValidFormat = allowedFormats.some((format) =>
                            fileName.toLowerCase().endsWith(format)
                          );

                          return (
                            isValidFormat ||
                            "Invalid file format. Please select a .xlsx file."
                          );
                        },
                      })}
                    />
                    <span className="error-message">
                      {errors.fileUpload && errors.fileUpload.message}
                    </span>
                  </Form.Group>
                </Col>
              }
            </Row>
          </Col>
        </Row>

        {(apiError || displayServerMessage) &&(<Col md="12">
          {apiError && <FormAlertMessage message={apiError} type="error" />}
          {displayServerMessage && (
            <FormAlertMessage message={serverErrorMessage} type="error" />
          )}
        </Col>)}
        <div className="mt-3">
          <Button
            className="btn-fill pull-right  add-user-button"
            type="submit"
            variant="info"
            disabled={confirmLoading}
          >
            {confirmLoading ? (
              <>
                <LoadingOutlined /> Uploading
              </>
            ) : (
              "Save"
            )}
          </Button>
          <CancelButton onClickHandler={onCancel} />
        </div>

        <div className="clearfix"></div>
      </Form>
    </Modal>
  );
};

export default AddBulkFileForm;
