import React from "react";
import ModemListView from "views/ModemList/ModemListView";
const ModemList = () => {
  return (
    <div>
      <ModemListView />
    </div>
  );
};

export default ModemList;
