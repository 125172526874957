import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { getProfileDetails } from "API/authAPI";
import { getUserID } from "helpers/authHelper";
import { UserOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Avatar, Space } from "antd";
import { useMutation } from "react-query";
import LoadingOverlay from "components/Overlay";

import routes from "routes.js";
import { userLogout } from "API/authAPI";
import { clearLocalStorage } from "helpers/authHelper";
import { cacheDuration } from "helpers/apiHelper";

const Header = () => {
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [userRole, setUserRole] = useState("");
  const [loadings, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();

    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "";
  };

  const {
    data: userDetails,
    isLoading,
    isError,
    refetch,
  } = useQuery(["profile", getUserID()], () => getProfileDetails(getUserID()), {
    cacheTime: cacheDuration,
  });

  useEffect(() => {
    if (userDetails) {
      setUserName(userDetails.first_name);
      setUserImage(userDetails.user_image);
      setUserRole(userDetails.role_name);
    }
  }, [userDetails]);

  const logoutApi = useMutation(userLogout, {
    onSuccess: (response) => {
      setLoading(false);
      clearLocalStorage();
      navigate("/login");
    },
  });

  const logout = async () => {
    try {
      const response = await logoutApi.mutateAsync();
    } catch (error) {     setLoading(false);}
  };

  return (
    // <Navbar bg="light" expand="lg" className="inner-navbar"></Navbar>
    <Navbar bg="light" expand="lg" className="inner-navbar">
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <MenuUnfoldOutlined />
          </Button>
          <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
            {getBrandText()}
          </Navbar.Brand>
        </div>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="mr-2 no-border"
        >
          {userImage === "" || userImage == null ? (
            <div className="header-avatar">
              <Space direction="vertical">
                <Space className="gap-2">
                  <Avatar size="large" icon={<UserOutlined />} />{" "}
                </Space>
              </Space>
            </div>
          ) : (
            <div className="header-avatar">
              <Space direction="vertical">
                <Space className="gap-2">
                  <Avatar src={userImage} />{" "}
                  <div className="flex flex-column"></div>
                </Space>
              </Space>
            </div>
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="nav mr-auto" navbar>
            <Dropdown as={Nav.Item}>
              <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
                <Dropdown.Item as={Link} to="/update-profile">
                  Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/change-password">
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item  as={Link} to="/settings">
                  Settings
                </Dropdown.Item>
                <Dropdown.Item href="" onClick={logout}>
                  Logout
                </Dropdown.Item>
                {/* <div className="divider"></div> */}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
          <Nav className="ml-auto" navbar>
            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                aria-expanded={false}
                aria-haspopup={true}
                as={Nav.Link}
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                variant="default"
                className="m-0  justify-self-end"
              >
                {userImage === "" || userImage == null ? (
                  <div className="header-avatar">
                    <Space direction="vertical">
                      <Space className="gap-2">
                        <Avatar
                          size="large"
                          icon={<UserOutlined />}
                          className="user-avatar-icon"
                        />{" "}
                        <div className="flex flex-column">
                          <span className="no-icon">{userName}</span>
                          <span className="no-icon font-80">{userRole}</span>
                        </div>
                      </Space>
                    </Space>
                  </div>
                ) : (
                  <div className="header-avatar">
                    <Space direction="vertical">
                      <Space className="gap-2">
                        <Avatar src={userImage} />{" "}
                        <div className="flex flex-column">
                          <span className="no-icon">{userName}</span>
                          <span className="no-icon font-80">{userRole}</span>
                        </div>
                      </Space>
                    </Space>
                  </div>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu
                aria-labelledby="navbarDropdownMenuLink"
                className="text-end"
              >
                <Dropdown.Item as={Link} to="/update-profile">
                  Profile
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/change-password">
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/settings">
                  Settings
                </Dropdown.Item>
                <Dropdown.Item href="" onClick={logout}>
                  Logout
                </Dropdown.Item>
                {/* <div className="divider"></div> */}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );

};

export default Header;
