import { makeRequest } from "helpers/apiHelper";

export const getPCModems = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/pcmodem/pc-modem/?${queryParamsString}`;
  return makeRequest("get", url);
};


export const createPCModem = async (data) => {
  return makeRequest("post", "/pcmodem/pc-modem/", data);
};

export const getAllModem = async () => {
  return makeRequest("get", "/pcmodem/pc-modem/get-all-modem");
};

export const editPCModem = async ({ id, data }) => {
  return makeRequest("patch", `/pcmodem/pc-modem/${id}/`, data);
};

export const deletePcModem = ({ id }) => {
  return makeRequest("delete", `/pcmodem/pc-modem/${id}/`);
};
