import { makeRequest } from "helpers/apiHelper";

export const getNetworkProvider = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/networkprovider/network-provider/?${queryParamsString}`;
  return makeRequest("get", url);
};

export const getNetworkQualChoice = async () => {
  const url = `/networkprovider/network-provider/get-network-quality-type/`;
  return makeRequest("get", url);
};

export const getExpiredIfChoice = async () => {
  const url = `/networkprovider/network-provider/get-expired-if-type/`;
  return makeRequest("get", url);
};


export const getActivationType = async () => {
  const url = `/networkprovider/network-provider/get-activation-type/`;
  return makeRequest("get", url);
};

export const getAvoidDeactivationType = async () => {
  const url = `/networkprovider/network-provider/get-avoid-deactivation-type/`;
  return makeRequest("get", url);
};

export const createNetworkProvider = async (data) => {
  return makeRequest(
    "post",
    "/networkprovider/network-provider/",
    data,
  );
};

export const editNetworkProvider = async ({ id, data }) => {
  return makeRequest(
    "patch",
    `/networkprovider/network-provider/${id}/`,
    data,
  );
};

export const deleteNetworkProvider = ({ id }) => {
  return makeRequest("delete", `/networkprovider/network-provider/${id}/`);
};
