import React from "react";

import { Form, Button } from "react-bootstrap";
import { CloseCircleOutlined } from "@ant-design/icons";
import "./ImageInput.css";

const ImageInput = ({
  selectedImage,
  handleImageSelect,
  handleImageRemove,
}) => {
  return (
    <div>
        <Form.Group>
          <div className="image-upload-container">
            <label htmlFor="image-input" className="image-label">
              <img  
                src={selectedImage?selectedImage:require("assets/img/default-avatar.png")}
                alt=""
                className="profile-image"
                accept=".png, .jpg, .jpeg"
              />
            </label>
            <button type="button" className="clear-button ml-3" onClick={handleImageRemove}>
              <CloseCircleOutlined className="cross-button" />
            </button>
            <input
              type="file"
              id="image-input"
              accept=".png, .jpg, .jpeg"
              onChange={handleImageSelect}
              style={{ display: "none" }}
            />
          </div>
        </Form.Group>
    </div>
  );
};

export default ImageInput;
