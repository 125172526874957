import React from "react";

import Page404View from "views/Page404/Page404";

const Page404 = () => {
  return (
    <div>
      <Page404View />
    </div>
  );
};

export default Page404;
