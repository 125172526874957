import { useState } from 'react';

const useErrorMessage = () => {
  const errorMessageDuration = parseInt(process.env.REACT_APP_FAILED_MESSAGE_DURATION, 10);
  const [displayErrorMessage, setShowMessage] = useState(false);

  const showErrorMessage = () => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, errorMessageDuration);
  };

  return [displayErrorMessage, showErrorMessage];
};

export default useErrorMessage;
