import React from "react";
import ModemListTable from "components/Table/ModemListTable";
const ModemListView = () => {
  return (
    <div>
      <ModemListTable />
    </div>
  );
};

export default ModemListView;
