import React ,{useState} from "react";
import { Table } from "antd";
import { getSimPackExpire } from "API/dashboardAPI";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { cacheDuration } from "helpers/apiHelper";
import {  Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import LoadingOverlay from "components/Overlay";

const SimPackageExpire = () => {
  const [loadings, setLoading] = useState(false);

  const {
    isLoading: isLoadingSimExpireData,
    isError: isErrorSimExpireData,
    error: errorSimExpireData,
    data: simExpireData,
  } = useQuery("simExpireData", getSimPackExpire, {
    cacheTime: cacheDuration,
  });
  const navigate = useNavigate();
  

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Stored at",
      dataIndex: "storage_before_use__name",
    },
    {
      title: "Expire on",
      dataIndex: "expire_date_after_activation",
      key: "expire_date_after_activation",
      render: (expire_date_after_activation , index) => (
        <div key={index} className="center-aligned-text">
          {dayjs(expire_date_after_activation).format("D MMM YYYY")}
        </div>
      ),
    },
  ];

  // Ensure each data item has a unique key
  const dataSource = simExpireData?.data?.map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Card className="">
        <Card.Header className="">
          <Card.Title className="dashboard-element-title" as={"h5"}>SIM expire</Card.Title>
        </Card.Header>
        <Card.Body className="sim-expire-body">
          <Table dataSource={simExpireData?.data} columns={columns} pagination={false} rowKey={record => record.id}/>
          <div className="pt-1 pb-1 pr-2 text-right see-more-btn" style={{ cursor: "pointer",  textDecoration: "underline", color: "blue", }}>
            <a onClick={(e)=> navigate("/sim-package", { state: { simExpireFilter: true } })}>See more cards</a>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SimPackageExpire;

