import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./slices/UserSlice";
import { roleSlice } from "./slices/permissionsSlice";

const store=configureStore({
    reducer:{
        editUsers:userSlice.reducer,
        rolePermissions: roleSlice.reducer,
    },
    devTools:true
})
export default store;
