import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "components/Overlay";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getAllCountries } from "API/supplierApi";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "./AddModemListForm.css";
import { Breadcrumbs } from "Breadcrumb";
import { cacheDuration } from "helpers/apiHelper";
import CustomMultipleDropdown from "components/CustomDropdown/MultipleDropdown";
import {
  getServicePartner,
  createModemList,
  editModemList,
} from "API/modemListApi";
import { fieldIds } from "constants/moduleFields";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
import ServicePartnerDropdown from "components/CustomDropdown/ServicePartnerDropdown";


const AddModemListForm = () => {
  const {
    watch,
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const location = useLocation();
  const [loadings, setLoading] = useState(false);

  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [selectedExtraOption, setSelectedExtraOption] = useState({
    label: "Select All",
    value: "select",
  });
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selectedDefPort, setSelectedDefPort] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedFirstSP, setSelectedFirstSP] = useState(null);
  const [selectedFirstCountry, setSelectedFirstCountry] = useState(null);
  const [selectedSecondtSP, setSelectedSecondSP] = useState(null);
  const [selectedSecondCountry, setSelectedSecondCountry] = useState(null);
  const [selectedThirdSP, setSelectedThirdSP] = useState(null);
  const [selectedThirdCountry, setSelectedThirdCountry] = useState(null);

  const modemListModuleID = parseInt(
    process.env.REACT_APP_MODEM_LIST_MODULE_ID,
    10
  );
  dayjs.extend(customParseFormat);
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesData = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingServicePartner,
    isError: isErrorServicePartner,
    error: errorServicePartner,
    data: servicePartner,
  } = useQuery("servicePartner", getServicePartner, {
    cacheTime: cacheDuration,
  });

  const spTypeChoices = servicePartner?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.short_name,
      username: innerArray.username,
      country:innerArray.country
    };
  });

  const port = Array.from({ length: 64 }, (_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
  }));

  const termOptions = [
    { id: "1", name: "Short term" },
    { id: "2", name: "Long term" },
  ];

  
  useEffect(() => {
    if (location.state) {
      const modemData = location.state.data;
   

      const termVal = termOptions?.find((val) => {
        return !isNaN(Number(modemData.term))
          ? val.id == modemData.term
          : val.name == modemData.term;
      });
      // const spVal = (id) => {
      //   const value = servicePartner?.find((val) => {
      //     return !isNaN(Number(modemData.child[id]?.service_partner_id))
      //       ? val.id == modemData.child[id]?.service_partner_id
      //       : val.name == modemData.child[id]?.service_partner_id;
      //   });
      //   return value;
      // };
      const spVal = (id) => {
        const value = servicePartner?.find(
          (val) => val.id == modemData.child[id]?.service_partner_id
        );
        return value;
      };

      setValue("name", modemData.name);

      const outputArray = modemData?.defective_port?.split(",");

      if (outputArray?.length == 64) {
        setSelectedExtraOption({ label: "UnSelect All", value: "unselect" });
      }
      setSelectedDefPort(outputArray);
      setValue("defective_port", outputArray);

      setSelectedTerm(termVal);
      setValue("term", termVal.id);

      setSelectedFirstSP(spVal(1));
      setValue("mainServiceParter", spVal(1)?.id);
      setValue("nameMainSP", modemData.child[1]?.name_from_sp);

      setSelectedSecondSP(spVal(2));
      setValue("secondServiceParter", spVal(2)?.id);
      setValue("nameSecondSP", modemData.child[2]?.name_from_sp);

      setSelectedThirdSP(spVal(3));
      setValue("thirdServiceParter", spVal(3)?.id);
      setValue("nameThirdSP", modemData.child[3]?.name_from_sp);

      setIsEdit(true);
    }
  }, [location.state, servicePartner]);

  const modemCreator = useMutation(createModemList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/modem-list");
      await showToast({
        icon: "success",
        title: "Modem added successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const modemEditor = useMutation(editModemList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/modem-list");
      await showToast({
        icon: "success",
        title: "Modem edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleReset = () => {
    // Reset the form fields
    setSelectedDefPort(null);
    setSelectedFirstCountry(null);
    setSelectedFirstSP(null);
    setSelectedSecondSP(null);
    setSelectedTerm();

    setSelectedSecondCountry(null);
    setSelectedThirdCountry(null);
    setSelectedThirdSP(null);
    reset();
  };

  const onSubmit = async (data) => {
    setApiError(false);
    
    var defective_port_string = selectedDefPort
      ? selectedDefPort?.join(",")
      : null;

    const modulePermission = permissions?.permissions[modemListModuleID];
    const dataObject = {};
    dataObject.child = {};

    for (let i = 1; i <= 3; i++) {
      if (i == 2 && !selectedSecondtSP) {
        continue;
      }
      if (i == 3 && !selectedThirdSP) {
        continue;
      }
      dataObject.child[i] = dataObject.child[i] || {};
    }

    if (
      isFieldAddableOrEditable(fieldIds.modemList.name, modulePermission) &&
      data.name
    ) {
      dataObject.name = data.name;
    }

    if (
      isFieldAddableOrEditable(
        fieldIds.modemList.defective_port,
        modulePermission
      )
    ) {
      dataObject.defective_port =
        data.defective_port?.length > 0 ? defective_port_string : null;
    }

    if (
      isFieldAddableOrEditable(fieldIds.modemList.term, modulePermission) &&
      selectedTerm
    ) {
      dataObject.term = selectedTerm.id;
    }

    if (
      isFieldAddableOrEditable(
        fieldIds.modemList.service_partner_id,
        modulePermission
      ) &&
      selectedFirstSP
    ) {
      dataObject.child[1].service_partner_id = selectedFirstSP.id;
    }

    if (
      isFieldAddableOrEditable(
        fieldIds.modemList.name_from_sp,
        modulePermission
      ) &&
      data.nameMainSP
    ) {
      dataObject.child[1].name_from_sp = data.nameMainSP;
    }

    if (
      isFieldAddableOrEditable(
        fieldIds.modemList.service_partner_id,
        modulePermission
      ) &&
      selectedSecondtSP?.id
    ) {
      dataObject.child[2].service_partner_id = selectedSecondtSP.id;
    }

    if (
      isFieldAddableOrEditable(
        fieldIds.modemList.name_from_sp,
        modulePermission
      ) &&
      data.nameSecondSP
    ) {
      dataObject.child[2].name_from_sp = data.nameSecondSP;
    }

    if (
      isFieldAddableOrEditable(
        fieldIds.modemList.service_partner_id,
        modulePermission
      ) &&
      selectedThirdSP?.id
    ) {
      dataObject.child[3].service_partner_id = selectedThirdSP.id;
    }

    if (
      isFieldAddableOrEditable(
        fieldIds.modemList.name_from_sp,
        modulePermission
      ) &&
      data.nameThirdSP
    ) {
      dataObject.child[3].name_from_sp = data.nameThirdSP;
    }

    if (isEdit) {
      dataObject.child[1].id = location.state.data.child[1].id;
      selectedSecondtSP &&
        location.state.data?.child[2]?.id &&
        (dataObject.child[2].id = location.state.data.child[2].id);
      selectedThirdSP &&
        location.state.data?.child[3]?.id &&
        (dataObject.child[3].id = location.state.data.child[3].id);

      if (selectedSecondtSP && selectedSecondtSP.id == "") {
        dataObject.child[2] = {};
      }
      if (selectedThirdSP && selectedThirdSP.id == "") {
        dataObject.child[3] = {};
      }
    }

    try {
      let response;
      if (isEdit) {
        response = await modemEditor.mutateAsync({
          id: location.state.data.id,
          data: dataObject,
        });
      } else {
        response = await modemCreator.mutateAsync(dataObject);
      }
    } catch (error) {
      if (error.response.status == 400) {
      setLoading(false);

        setApiError(error.response.data.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };

  const handleMultiplePorts = (e) => {
    const selectAll = e.find((option) => option.value == "select");
    const unselectAll = e.find((option) => option.value == "unselect");

    if (selectAll) {
      const arrayOfValues = port.map((obj) => obj.value);
      setSelectedDefPort(arrayOfValues);
      setSelectedExtraOption({ label: "Unsselect All", value: "unselect" });
    } else if (unselectAll) {
      setSelectedDefPort("");
      setSelectedExtraOption({ label: "Select All", value: "select" });
    } else {
      const arrayOfValues = e.map((obj) => obj.value);
      setSelectedDefPort(arrayOfValues);
    }
  };

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">{isEdit ? "Edit" : "Add"} Modem</Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          <fieldset>
                            <legend>Modem details</legend>

                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.modemList.name,
                                permissions?.permissions[modemListModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Name">
                                      <span className="label-text">Name</span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("name", {
                                        required: true,
                                        maxLength: 50,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.name?.type === "required" &&
                                        "Name is required."}
                                      {errors.name?.type === "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.name && errors.name.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.modemList.defective_port,
                                permissions?.permissions[modemListModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <CustomMultipleDropdown
                                    label="Defective ports"
                                    defaultValue={selectedDefPort}
                                    selectedOption={selectedDefPort}
                                    options={
                                      port ? [selectedExtraOption, ...port] : []
                                    }
                                    handleSelect={handleMultiplePorts}
                                    control={control}
                                    errors={errors}
                                    name="defective_port"
                                    showSelectAllOption={true}
                                  />
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.modemList.term,
                                permissions?.permissions[modemListModuleID]
                              ) &&
                                allCountries && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Term"
                                      selectedOption={selectedTerm}
                                      options={termOptions}
                                      handleSelect={(e) => setSelectedTerm(e)}
                                      control={control}
                                      errors={errors}
                                      name="term"
                                      rules={{ required: "Term is required." }}
                                    />
                                  </Col>
                                )}
                            </Row>
                          </fieldset>
                          <fieldset>
                            <legend>For main Service Partner</legend>

                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.modemList.service_partner_id,
                                permissions?.permissions[modemListModuleID]
                              ) &&
                                servicePartner && (
                                  <Col className="" md="4">
                                    <ServicePartnerDropdown
                                      label="Service Partner"
                                      selectedOption={selectedFirstSP}
                                      options={[
                                        ...(spTypeChoices.filter(sp => sp.id !== selectedFirstSP?.id && sp.id !== selectedSecondtSP?.id).length > 0 
                                            ? [{ id: "", name: "None of these" }] 
                                            : []),
                                        ...spTypeChoices.filter(sp => sp.id !== selectedThirdSP?.id && sp.id !== selectedSecondtSP?.id)
                                    ]}
                                      handleSelect={(e) =>
                                        setSelectedFirstSP(e)
                                      }
                                      control={control}
                                      errors={errors}
                                      name="mainServiceParter"
                                      rules={{
                                        required:
                                          "Service Partner is required.",
                                      }}
                                    />
                                  </Col>
                                )}

                              {isFieldAddableOrEditable(
                                fieldIds.modemList.name_from_sp,
                                permissions?.permissions[modemListModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Name from Service Partner">
                                      <span className="label-text">
                                        Name from Service Partner
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("nameMainSP", {
                                        required: true,
                                        maxLength: 255,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.nameMainSP?.type === "required" &&
                                        "Name from service partner is required."}
                                      {errors.nameMainSP?.type === "maxLength" &&
                                        "It accepts maximum 255 characters."}
                                      {errors.nameMainSP &&
                                        errors.nameMainSP.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                          <fieldset>
                            <legend>For second Service Partner</legend>
                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.modemList.service_partner_id,
                                permissions?.permissions[modemListModuleID]
                              ) &&
                                servicePartner && (
                                  <Col className="" md="4">
                                    <ServicePartnerDropdown
                                      label="Service Partner"
                                      selectedOption={selectedSecondtSP}
                                      options={[
                                        ...(spTypeChoices.filter(sp => sp.id !== selectedFirstSP?.id && sp.id !== selectedSecondtSP?.id).length > 0 
                                            ? [{ id: "", name: "None of these" }] 
                                            : []),
                                        ...spTypeChoices.filter(sp => sp.id !== selectedFirstSP?.id && sp.id !== selectedThirdSP?.id)
                                    ]}
                                      handleSelect={(e) => {
                                        setSelectedSecondSP(e);
                                        if (e.id == "") {
                                          setValue("nameSecondSP", "");
                                        }
                                      }}
                                      control={control}
                                      errors={errors}
                                      name="secondServiceParter"
                                      // rules={{
                                      //   required:
                                      //     "Service Partner is required.",
                                      // }}
                                    />
                                  </Col>
                                )}

                              {isFieldAddableOrEditable(
                                fieldIds.modemList.name_from_sp,
                                permissions?.permissions[modemListModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Name from Service Partner">
                                      <span className="label-text">
                                        Name from Service Partner
                                      </span>
                                      {watch("secondServiceParter") && (
                                        <span className="error-message">
                                          {" "}
                                          *
                                        </span>
                                      )}
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      disabled={
                                        selectedSecondtSP &&
                                        selectedSecondtSP.id != ""
                                          ? false
                                          : true
                                      }
                                      type="text"
                                      {...register("nameSecondSP", {
                                        required:
                                          watch("secondServiceParter") && true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.nameSecondSP?.type ===
                                        "required" &&
                                        "Name from service partner is required."}

                                      {errors.nameSecondSP &&
                                        errors.nameSecondSP.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                          <fieldset>
                            <legend>For third Service Partner</legend>
                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.modemList.service_partner_id,
                                permissions?.permissions[modemListModuleID]
                              ) &&
                                servicePartner && (
                                  <Col className="" md="4">
                                    <ServicePartnerDropdown
                                      label="Service Partner"
                                      selectedOption={selectedThirdSP}
                                      options={[
                                        ...(spTypeChoices.filter(sp => sp.id !== selectedFirstSP?.id && sp.id !== selectedSecondtSP?.id).length > 0 
                                            ? [{ id: "", name: "None of these" }] 
                                            : []),
                                        ...spTypeChoices.filter(sp => sp.id !== selectedFirstSP?.id && sp.id !== selectedSecondtSP?.id)
                                    ]}
                                      handleSelect={(e) => {
                                        setSelectedThirdSP(e);
                                        if (e.id == "") {
                                          setValue("nameThirdSP", "");
                                        }
                                      }}
                                      control={control}
                                      errors={errors}
                                      name="thirdServiceParter"
                                      // rules={{
                                      //   required:
                                      //     "Service Partner is required.",
                                      // }}
                                    />
                                  </Col>
                                )}

                              {isFieldAddableOrEditable(
                                fieldIds.modemList.name_from_sp,
                                permissions?.permissions[modemListModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Name from Service Partner">
                                      <span className="label-text">
                                        Name from Service Partner
                                      </span>
                                      {watch("thirdServiceParter") && (
                                        <span className="error-message">
                                          {" "}
                                          *
                                        </span>
                                      )}
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      disabled={
                                        selectedThirdSP &&
                                        selectedThirdSP.id != ""
                                          ? false
                                          : true
                                      }
                                      {...register("nameThirdSP", {
                                        required:
                                          watch("thirdServiceParter") && true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.nameThirdSP?.type ===
                                        "required" &&
                                        "Name from service partner is required."}

                                      {errors.nameThirdSP &&
                                        errors.nameThirdSP.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}

                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right  add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right  mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddModemListForm;
