import CryptoAES from "crypto-js/aes";
import { setAccessToken } from "./tokenManager";
import { setRefreshToken } from "./tokenManager";

const secretKey = process.env.REACT_APP_SECRET_KEY;

export const isAuthenticated = () => {
  const token = localStorage.getItem("accessToken");
  return !!token;
};

export const storeData = (userID, accessToken, refreshToken, role) => {
  localStorage.setItem("userID", userID);
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  localStorage.setItem("userRole", role);
};

export const getUserID = () => {
  return localStorage.getItem("userID");
};

export const getUserRole = () => {
  return localStorage.getItem("userRole");
};

export const clearLocalStorage = () => {
  const keysToRemove = ["accessToken", "refreshToken", "userID", "userRole"];

  // Loop through the keys to remove
  for (const key of keysToRemove) {
    localStorage.removeItem(key);
  }
};

const hexToUtf8 = (hex) => {
  return decodeURIComponent(hex.replace(/(..)/g, "%$1"));
};

export const decryptAndRetrieve = (storageKey) => {
  try {
    let ciphertext = localStorage.getItem(storageKey);
    if (ciphertext) {
      const decryptedText = CryptoAES.decrypt(ciphertext.toString(), secretKey);
      // Convert decrypted email to UTF-8
      const decrypted = hexToUtf8(decryptedText.toString(CryptoAES.Utf8));
      return decrypted;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const encryptedAndStore = (data, storageKey) => {
  let encryptedData = CryptoAES.encrypt(data, secretKey);
  localStorage.setItem(storageKey, encryptedData);
};
