import React, { useState, useEffect } from "react";

//react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import LoadingOverlay from "components/Overlay";
import ImageInput from "components/ImageInput/ImageInput";
import { getProfileDetails } from "API/authAPI";
import { getUserID } from "helpers/authHelper";
import { updateProfileDetails } from "API/authAPI";
import useSuccessMessage from "customHooks/useSuccessMessage";
import { isValidDateFormat, convertDate } from "helpers/dateValidator";
import CancelButton from "components/CancelButton/CancelButton";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import { Breadcrumbs } from "Breadcrumb";
import { cacheDuration } from "helpers/apiHelper";
import { handleKeyPress } from "helpers/numberValidator";

const UpdateProfileForm = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const [dob, setDob] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [newImageSelected, setNewImageSelected] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [showMessage, showSuccessMessage] = useSuccessMessage();
  const [loadings, setLoading] = useState(false);
  const [imageRemoval, setImageRemoval] = useState();
  dayjs.extend(customParseFormat);
  const {
    data: userDetails,
    isLoading,
    isError,
    refetch,
  } = useQuery(["profile", getUserID()], () => getProfileDetails(getUserID()), {
    cacheTime: cacheDuration,
  });

  useEffect(() => {
    if (userDetails) {
      setValue("email", userDetails.email);
      setValue("address", userDetails.address);
      setValue("firstName", userDetails.first_name);
      setValue("lastName", userDetails.last_name);
      setValue("dob", dayjs(userDetails.date_of_birth));
      setValue("phoneNumber", userDetails.phone_number);
      setSelectedImage(userDetails.user_image);
      setDob(userDetails.date_of_birth);
      setImageRemoval(0);
    }
  }, [userDetails]);

  const handleImageSelect = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      setSelectedImage(URL.createObjectURL(imageFile));
      setNewImageSelected(imageFile);
    }
  };
  const handleImageRemove = (event) => {
    setSelectedImage();
    setImageRemoval(1);
  };
  const profileUpdater = useMutation(updateProfileDetails, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      setLoading(false);
      refetch();
      showSuccessMessage();
    },
  });

  const onSubmit = async (data) => {
    setApiError(false);
    const formData = new FormData();
    // formData.append("email", data.email);
    formData.append("first_name", data.firstName);
    formData.append("last_name", data.lastName);
    formData.append("address", data.address ? data.address : "");
    formData.append("phone_number", data?.phoneNumber ? data.phoneNumber : "");
    if (isValidDateFormat(data.dob)) {
      formData.append("date_of_birth", data.dob);
    } else {
      formData.append("date_of_birth", convertDate(data.dob));
    }
    formData.append("user_image", newImageSelected ? newImageSelected : "");
    formData.append("image_remove", imageRemoval == 1 ? 1 : 0);

    try {
      const response = await profileUpdater.mutateAsync({
        id: getUserID(),
        data: formData,
      });
    } catch (error) {
      if (error.response.status == 400) {
        setLoading(false);
        setApiError(error.response.data.message);
      }
    }
  };

  // Define a function to check if a date should be disabled
  // Function to disable future dates
  const disabledDate = (current) => {
    return current && current.isAfter(dayjs().endOf("day"));
  };

  if (isLoading) {
    return <p>Loading user details...</p>;
  } else if (isError) {
    return <p>Error fetching user details.</p>;
  }

  return (
    <div>
      <Container fluid>
       <LoadingOverlay isLoading={loadings} />

        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">Update Profile</Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Row>
                    <Col md="12" className="user-fields-wrapper">
                      <Row className="">
                        <Col className="" md="4">
                          <ImageInput
                            selectedImage={selectedImage}
                            handleImageSelect={handleImageSelect}
                            handleImageRemove={handleImageRemove}
                          />
                        </Col>

                        <Col className="" md="4">
                          <Form.Group>
                            <label title="First name">
                              <span className="label-text">First name</span>
                              <span className="error-message"> *</span>
                            </label>
                            <Form.Control
                              defaultValue=""
                              placeholder=""
                              type="text"
                              {...register("firstName", {
                                required: true,
                                maxLength: 50,
                                minLength: 3,
                                pattern: {
                                  value: /^[A-Za-z0-9\s]+$/,
                                },
                              })}
                            ></Form.Control>
                            <span className="error-message">
                              {errors.firstName?.type === "required" &&
                                "First name is required."}
                              {errors.firstName?.type === "maxLength" &&
                                "It accepts maximum 50 characters."}
                              {errors.firstName?.type === "minLength" &&
                                "Enter minimum 3 characters."}
                              {errors.firstName?.type === "pattern" &&
                                "Special characters  are not allowed."}
                              {errors.firstName && errors.firstName.message}
                            </span>
                          </Form.Group>
                        </Col>

                        <Col className="" md="4">
                          <Form.Group>
                            <label title="Last name">
                              <span className="label-text">Last name</span>
                              <span className="error-message"> *</span>
                            </label>

                            <Form.Control
                              defaultValue=""
                              placeholder=""
                              type="text"
                              {...register("lastName", {
                                required: true,
                                maxLength: 50,
                                minLength: 3,
                                pattern: {
                                  value: /^[A-Za-z0-9\s]+$/,
                                },
                              })}
                            ></Form.Control>
                            <span className="error-message">
                              {errors.lastName?.type === "required" &&
                                "Last name is required."}
                              {errors.lastName?.type === "maxLength" &&
                                "It accepts maximum 50 characters."}
                              {errors.lastName?.type === "minLength" &&
                                "Enter minimum 3 characters."}
                              {errors.lastName?.type === "pattern" &&
                                "Special characters are not allowed."}
                              {errors.lastName && errors.lastName.message}
                            </span>
                          </Form.Group>
                        </Col>

                        <Col className="" md="4">
                          <Form.Group>
                            <label title="Email">Email</label>{" "}
                            <Form.Control
                              defaultValue=""
                              placeholder=""
                              type="email"
                              {...register("email", {
                                required: true,
                                maxLength: 100,
                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                                },
                              })}
                              readOnly
                            ></Form.Control>
                            <span className="error-message">
                              {errors.email?.type === "required" &&
                                "Email is required."}
                              {errors.email?.type === "maxLength" &&
                                "It accepts maximum 50 characters."}
                              {errors.email?.type === "pattern" &&
                                "Enter a valid email."}
                              {errors.email && errors.email.message}
                            </span>
                          </Form.Group>
                        </Col>

                        <Col className="" md="4">
                          <Form.Group>
                            <label title="Phone number">Phone number</label>
                            <Form.Control
                              defaultValue=""
                              placeholder=""
                              type="number"
                              onKeyPress={handleKeyPress}
                              {...register("phoneNumber", {
                                maxLength: 20,
                                minLength: 10,
                                pattern: {
                                  value: /^[0-9]+$/,
                                },
                              })}
                            ></Form.Control>
                            <span className="error-message">
                              {errors.phoneNumber?.type === "pattern" &&
                                "Please enter a valid number."}
                              {errors.phoneNumber?.type === "maxLength" &&
                                "It accepts maximum 20 characters."}
                              {errors.phoneNumber?.type === "minLength" &&
                                "Enter minimum 10 characters."}
                              {errors.phoneNumber && errors.phoneNumber.message}
                            </span>
                          </Form.Group>
                        </Col>

                        <Col className="" md="4">
                          <Form.Group>
                            <label title="Date of birth">
                              <span className="label-text">Date of birth</span>
                              <span className="error-message"> *</span>
                            </label>

                            <br />
                            <Controller
                              name="dob" // Field name in the form data
                              control={control}
                              defaultValue={dob}
                              render={({ field }) => (
                                <DatePicker
                                  value={field.value}
                                  onChange={(date) => field.onChange(date)}
                                  disabledDate={disabledDate}
                                  format="DD/MM/YYYY"
                                  allowClear={false}
                                />
                              )}
                              rules={{
                                required: "Date of birth is required.",
                              }} // Validation rules
                            />
                            {errors.dob && (
                              <span className="error-message">
                                {errors.dob.message}
                              </span>
                            )}
                          </Form.Group>
                        </Col>

                        <Col className="" md="12">
                          <Form.Group>
                            <label title="Address">Address</label>{" "}
                            <Form.Control
                              defaultValue=""
                              placeholder=""
                              as="textarea"
                              rows={5}
                              {...register("address", {
                                maxLength: 200,
                                minLength: 3,
                              })}
                            ></Form.Control>
                            <span className="error-message">
                              {errors.address?.type === "maxLength" &&
                                "It accepts maximum 200 characters."}
                              {errors.address?.type === "minLength" &&
                                "Enter minimum 3 characters."}
                              {errors.address && errors.address.message}
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {(apiError || showMessage) &&(<Col md="12">
                    {showMessage && (
                      <FormAlertMessage
                        message="User updated successfully!"
                        type="success"
                      />
                    )}
                    {apiError && (
                      <FormAlertMessage message={apiError} type="error" />
                    )}
                  </Col>)}

                  <div className="mt-3 mr-3">
                    <Button
                      className="btn-fill pull-right add-user-button"
                      type="submit"
                      variant="info"
                    >
                      Update
                    </Button>
                    <CancelButton />
                  </div>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UpdateProfileForm;
