import React, { useState } from "react";

import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "components/Overlay";

import { sendEmailLink } from "API/authAPI";
import Loader from "components/Loader/Loader";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import showToast from "components/Toast/Toast";

const EmailForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm();
  const navigate = useNavigate();
  const [apiError, setApiError] = useState(false);
  const [loadings, setLoading] = useState(false);

  const [displayServerMessage, showServerMessage] = useErrorMessage();

  const emailSender = useMutation(sendEmailLink, {

    onMutate: () => {
      setLoading(true);
    },    onSuccess: async (response) => {
      const email = getValues("email");
      setLoading(false);

      await showToast({
        icon: "success",
        title: response.message,
        position: "top-end",
        timer: 2000,
      });
      navigate("/send-again", { state: { email } });
    },
  });
  const onSubmit = async (data) => {
    setApiError(false);
    setLoading(true);
    try {
      const response = await emailSender.mutateAsync({
        email: data.email,
      });
    } catch (error) {
      setLoading(false);
      if (error.response.status == 400) {
        setApiError(error.response.data.message);
      } else {
        showServerMessage();
      }
    }
  };
  return (
    <>
      <div className="container mx-auto px-4">
      <LoadingOverlay isLoading={loadings} />
        <div className="flex content-center items-center justify-center">
          <div className="w-full  px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg border-0">
              <div className="flex-auto px-4 lg:px-10 py-10  user-fields-wrapper form-field">
                <Form
                  className="flex flex-col gap-2 "
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <>
                    <div className="text-blueGray-400 text-center mb-3 font-bold pt-1">
                      <h4 className="mb-4 text-center login-title">
                        Reset Password
                      </h4>
                    </div>
                    <div className="relative w-full mb-2">
                      <Form.Group
                        controlId="formBasicEmail"
                        className="d-flex flex-column "
                      >
                        <Form.Label className="" title="Email address">
                          <span className="label-text">Email address</span>{" "}
                          <span className="error-message">*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          className=""
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                            },
                          })}
                        />
                        <span className="error-message">
                          {errors.email?.type === "required" &&
                            "Email address is required."}
                          {errors.email?.type === "pattern" &&
                            "Please enter a valid email."}
                          {errors.email && errors.email.message}
                        </span>
                      </Form.Group>
                    </div>
                    <h5 className="mt-4">
                      Note: Password reset link will be sent to this email.
                    </h5>
                    {apiError && (
                      <FormAlertMessage
                        type="error"
                        message={apiError}
                        className="alert-message-without-space"
                      />
                    )}
                    {displayServerMessage && (
                      <FormAlertMessage
                        type="error"
                        message={serverErrorMessage}
                        className="alert-message-without-space"
                      />
                    )}

                    <Button
                      variant="primary"
                      type="submit"
                      block="true"
                      className="mt-3 mb-4 submit-btn"
                    >
                      Confirm
                    </Button>
                  </>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailForm;
