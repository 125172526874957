import React from "react";
import AddModemLogView from "views/ModemDashboard/AddModemLogView";

const AddModemLog = () => {
  return (
    <div>
      <AddModemLogView />
    </div>
  );
};

export default AddModemLog;
