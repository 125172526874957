import React from "react";

import "./page404.css";

const Page404View = () => {
  return (
    <div>
      <div className="not-found-container">
        <h1 className="not-found-title">404 - Page Not Found</h1>
        <p className="not-found-message">
          The page you are looking for does not exist.
        </p>
      </div>
    </div>
  );
};

export default Page404View;
