import React, { useState } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import {
  UserAddOutlined,
  ExportOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import "./TableComponent.css";
import LoadingOverlay from "components/Overlay";

import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { getAllCountries } from "API/supplierApi";
import { fieldIds } from "constants/moduleFields";
import ConfirmModal from "components/Modal/Modal";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import { getExpirationList, deleteExpirationList } from "API/expirationListAPI";
import dayjs from "dayjs";
import GenericIconButton from "components/Buttons/GenericIconButton";
import AddBalanceForm from "components/Modal/AddBalanceForm";
import AddCallForm from "components/Modal/AddCallForm";
import AddTopupForm from "components/Modal/AddTopupForm";
import GenericButton from "components/Buttons/GenericButton";
import { exportToExcel } from "helpers/excelExporter";
import AddBulkFileForm from "components/Modal/AddBulkFileForm";
import showToast from "components/Toast/Toast";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";

const ExpirationListTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [addBalanceModalVisible, setaddBalanceModalVisible] = useState(false);
  const [addCallModalVisible, setaddCallModalVisible] = useState(false);
  const [addTopupModalVisible, setaddTopupModalVisible] = useState(false);
  const [addBulkModalVisible, setaddBulkModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState("");
  const [csvData, setCsvData] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged, setPermissionChanged] = useState(true);
  const [loadings, setLoading] = useState(false);

  const expirationListModuleID = parseInt(
    process.env.REACT_APP_EXPIRATION_LIST_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    search_filter: searchInput,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: expirationList,
    refetch,
  } = useQuery(["expirationList", queryParams], getExpirationList, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesTransformed = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };

  const handleEdit = (data) => {
    navigate("/edit-expiration-list", { state: { data: data } });
  };

  const removeExpirationList = useMutation(deleteExpirationList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      refetch();
    },
  });
  const handleDelete = async (row) => {
    const deleteApi = async () => {
      try {
        const response = await removeExpirationList.mutateAsync({ id: row.id });
        return response;
      } catch (error) {
        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: deleteApi,
      deleteErrorText: "Unable to delete list!",
      deleteSuccessMessage: "Expiration list data deleted successfully",
    });
  };

  const handleToggle = async (id, status) => {
    const response = await servicePartnerEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
  };

  const showAddBalanceModal = () => {
    setaddBalanceModalVisible(true);
  };
  const handleAddBalanceOk = () => {
    setaddBalanceModalVisible(false);
  };
  const handleAddBalanceCancel = () => {
    setaddBalanceModalVisible(false);
  };
  const showAddCallModal = () => {
    setaddCallModalVisible(true);
  };
  const handleAddCallOk = () => {
    setaddCallModalVisible(false);
  };
  const handleAddCallCancel = () => {
    setaddCallModalVisible(false);
  };
  const showAddTopupModal = () => {
    setaddTopupModalVisible(true);
  };
  const handleAddTopupOk = () => {
    setaddTopupModalVisible(false);
  };
  const handleAddTopupCancel = () => {
    setaddTopupModalVisible(false);
  };
  const showAddBulkModal = () => {
    setaddBulkModalVisible(true);
  };
  const handleAddBulkOk = () => {
    setaddBulkModalVisible(false);
  };
  const handleAddBulkCancel = () => {
    setaddBulkModalVisible(false);
  };
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      await refetch();
      await permissionRefetch();
      setPermissionChanged(true);
    },
  });

  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen && selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: expirationListModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
    setLoading(false);
  };
  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[expirationListModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }
    if (permissions.permissions[expirationListModuleID].delete_access) {
      buttons.push(
        <DeleteButton
          key={`delete-${record?.id}`}
          onChangeHandler={() => handleDelete(record)}
          record={record}
        />
      );
    }
    buttons.push(
      <GenericIconButton
        key={`add-balance-${record?.id}`}
        index={record?.id}
        onChangeHandler={() => {
          showAddBalanceModal();
          setSelectedRecord(record?.id);
        }}
        icon={"fa-solid fa-plus"}
        name={"Add balance"}
      />
    );
    buttons.push(
      <GenericIconButton
        key={`add-call-${record?.id}`}
        index={record?.id}
        onChangeHandler={() => {
          showAddCallModal();
          setSelectedRecord(record?.id);
        }}
        icon={"fa-solid fa-plus"}
        name={"Add call"}
      />
    );
    buttons.push(
      <GenericIconButton
        key={`add-top-up-${record?.id}`}
        index={record?.id}
        onChangeHandler={() => {
          showAddTopupModal();
          setSelectedRecord(record?.id);
        }}
        icon={"fa-solid fa-plus"}
        name={"Add top-up"}
      />
    );

    return (
      <div className="action-buttons">
        {buttons}
        {selectedRecord && (
          <>
            <AddBalanceForm
              key={selectedRecord.id}
              visible={addBalanceModalVisible}
              onOk={handleAddBalanceOk}
              onCancel={handleAddBalanceCancel}
              expirationListID={selectedRecord}
            />
            <AddCallForm
              key={selectedRecord.id}
              visible={addCallModalVisible}
              onOk={handleAddCallOk}
              onCancel={handleAddCallCancel}
              expirationListID={selectedRecord}
            />
            <AddTopupForm
              key={selectedRecord.id}
              visible={addTopupModalVisible}
              onOk={handleAddTopupOk}
              onCancel={handleAddTopupCancel}
              expirationListID={selectedRecord}
            />
          </>
        )}
      </div>
    );
  };

  const columns = [
    // {
    //   perm_id: 0,
    //   title: "Index",
    //   dataIndex: "index",
    //   sorter: (a, b) => a.index - b.index,
    //   width: 10,
    // },
    {
      perm_id: fieldIds.expirationList.package_id,
      title: "Package name",
      dataIndex: "sim_package_name",
      render: (sim_package_name, extra) => (
        <div>
          {sim_package_name ? sim_package_name : extra.sub_package_name}
        </div>
      ),
      sorter: (a, b) =>
        String(a.sim_package_name ?? a.sub_package_name).localeCompare(
          String(b.sim_package_name ?? b.sub_package_name)
        ),
    },
    {
      perm_id: fieldIds.expirationList.modem_id,
      title: "Modem name",
      dataIndex: "modem_name",
      sorter: (a, b) => {
        return a.modem_name.localeCompare(b.modem_name);
      },
    },
    {
      perm_id: fieldIds.expirationList.port,
      title: "Port",
      dataIndex: "port",
    },
    {
      perm_id: fieldIds.expirationList.service_partner_id,
      title: "Service Partner name",
      dataIndex: "service_partner_short_name",
      render: (service_partner_short_name) => (
        <div>
          {service_partner_short_name ? service_partner_short_name : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.service_partner_short_name ?? "").localeCompare(
          String(b.service_partner_short_name ?? "")
        ),
    },
    {
      perm_id: fieldIds.expirationList.start_datetime,
      title: "Start date",
      dataIndex: "start_datetime",
      render: (start_datetime) => (
        <div className="center-aligned-text">
          {start_datetime ? dayjs(start_datetime).format("D MMM YYYY") : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.start_datetime);
        const dateB = dayjs(b.start_datetime);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.end_datetime,
      title: "End date",
      dataIndex: "end_datetime",
      render: (end_datetime) => (
        <div className="center-aligned-text">
          {end_datetime ? dayjs(end_datetime).format("D MMM YYYY") : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.end_datetime);
        const dateB = dayjs(b.end_datetime);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.number,
      title: "Number",
      dataIndex: "number",
      render: (number) => (
        <div className="center-aligned-text">{number ? number : "-"}</div>
      ),
      sorter: (a, b) => Number(a.number) - Number(b.number),
    },
    {
      perm_id: fieldIds.expirationList.iccid,
      title: "ICCID",
      dataIndex: "iccid",
      render: (iccid) => (
        <div className="center-aligned-text">{iccid ? iccid : "-"}</div>
      ),
      sorter: (a, b) => Number(a.iccid) - Number(b.iccid),
    },
    {
      perm_id: fieldIds.expirationList.provider_id,
      title: "Provider",
      dataIndex: "provider_name",
      sorter: (a, b) =>
        String(a.provider_name ?? "").localeCompare(
          String(b.provider_name ?? "")
        ),
    },
    {
      perm_id: fieldIds.expirationList.activation_status,
      title: "Activation status",
      dataIndex: "activation_status",
      sorter: (a, b) =>
        String(a.activation_status ?? "").localeCompare(
          String(b.activation_status ?? "")
        ),
    },
    {
      perm_id: fieldIds.expirationList.activation_date,
      title: "Activation date",
      dataIndex: "activation_date",
      render: (activation_date) => (
        <div className="center-aligned-text">
          {activation_date ? dayjs(activation_date).format("D MMM YYYY") : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.activation_date);
        const dateB = dayjs(b.activation_date);
        return dateA - dateB;
      },
    },

    {
      perm_id: fieldIds.expirationList.expiration_date_for_activation,
      title: "Expiration date for activation",
      dataIndex: "expiration_date_for_activation",
      render: (expiration_date_for_activation) => (
        <div className="center-aligned-text">
          {expiration_date_for_activation
            ? dayjs(expiration_date_for_activation).format("D MMM YYYY")
            : "-"}
        </div>
      ),

      sorter: (a, b) => {
        const dateA = dayjs(a.expiration_date_for_activation);
        const dateB = dayjs(b.expiration_date_for_activation);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.expiration_date_after_activation,
      title: "Expiration date after activation",
      dataIndex: "expiration_date_after_activation",
      render: (expiration_date_after_activation) => (
        <div className="center-aligned-text">
          {expiration_date_after_activation
            ? dayjs(expiration_date_after_activation).format("D MMM YYYY")
            : "-"}
        </div>
      ),

      sorter: (a, b) => {
        const dateA = dayjs(a.expiration_date_after_activation);
        const dateB = dayjs(b.expiration_date_after_activation);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.actual_until,
      title: "Actual until",
      dataIndex: "actual_until",
      render: (actual_until) => (
        <div className="center-aligned-text">
          {actual_until ? dayjs(actual_until).format("D MMM YYYY") : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.actual_until);
        const dateB = dayjs(b.actual_until);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.latest_balance_amount,
      title: "Latest balance amount",
      dataIndex: "latest_balance_amount",
      render: (latest_balance_amount) => (
        <div className="right-aligned-text">
          {latest_balance_amount ? latest_balance_amount : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.latest_balance_amount) - Number(b.latest_balance_amount),
    },
    {
      perm_id: fieldIds.expirationList.balance_date,
      title: "Latest balance date",
      dataIndex: "balance_date",
      render: (balance_date) => (
        <div className="center-aligned-text">
          {balance_date ? dayjs(balance_date).format("D MMM YYYY") : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.balance_date);
        const dateB = dayjs(b.balance_date);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.days_from_last_balance,
      title: "Days since last balance date",
      dataIndex: "days_from_last_balance",
      render: (days_from_last_balance) => (
        <div className="center-aligned-text">
          {days_from_last_balance ? days_from_last_balance : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.days_from_last_balance) - Number(b.days_from_last_balance),
    },
    {
      perm_id: fieldIds.expirationList.latest_topup_amount,
      title: "Latest topup amount",
      dataIndex: "latest_topup_amount",
      render: (latest_topup_amount) => (
        <div className="right-aligned-text">
          {latest_topup_amount ? latest_topup_amount : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.latest_topup_amount) - Number(b.latest_topup_amount),
    },
    {
      perm_id: fieldIds.expirationList.topup_date,
      title: "Latest topup date",
      dataIndex: "topup_date",
      render: (topup_date) => (
        <div className="center-aligned-text">
          {topup_date ? dayjs(topup_date).format("D MMM YYYY") : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.topup_date);
        const dateB = dayjs(b.topup_date);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.total_topup_amount,
      title: "Total topup amount",
      dataIndex: "total_topup_amount",
      render: (total_topup_amount) => (
        <div className="right-aligned-text">
          {total_topup_amount ? total_topup_amount : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.total_topup_amount) - Number(b.total_topup_amount),
    },
    {
      perm_id: fieldIds.expirationList.next_needed_call_date,
      title: "Next needed call date",
      dataIndex: "next_needed_call_date",
      render: (next_needed_call_date) => (
        <div className="center-aligned-text">
          {next_needed_call_date
            ? dayjs(next_needed_call_date).format("D MMM YYYY")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.next_needed_call_date);
        const dateB = dayjs(b.next_needed_call_date);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.rem_days_for_needed_call,
      title: "Remaining days for needed call",
      dataIndex: "rem_days_for_needed_call",
      render: (rem_days_for_needed_call) => (
        <div className="center-aligned-text">
          {rem_days_for_needed_call ? rem_days_for_needed_call : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.rem_days_for_needed_call) - Number(b.rem_days_for_needed_call),
    },
    {
      perm_id: fieldIds.expirationList.next_needed_topup_date,
      title: "Next needed topup date",
      dataIndex: "next_needed_topup_date",
      render: (next_needed_topup_date) => (
        <div className="center-aligned-text">
          {next_needed_topup_date
            ? dayjs(next_needed_topup_date).format("D MMM YYYY")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.next_needed_topup_date);
        const dateB = dayjs(b.next_needed_topup_date);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.rem_days_for_needed_topup,
      title: "Remaining days for needed topup",
      dataIndex: "rem_days_for_needed_topup",
      render: (rem_days_for_needed_topup) => (
        <div className="center-aligned-text">
          {rem_days_for_needed_topup ? rem_days_for_needed_topup : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.rem_days_for_needed_topup) -
        Number(b.rem_days_for_needed_topup),
    },
    {
      perm_id: fieldIds.expirationList.possible_call_without_further_topup,
      title: "Possible calls without further topup",
      dataIndex: "possible_call_without_further_topup",
      render: (possible_call_without_further_topup) => (
        <div className="center-aligned-text">
          {possible_call_without_further_topup
            ? possible_call_without_further_topup
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.rem_days_for_needed_topup) -
        Number(b.rem_days_for_needed_topup),
    },
    {
      perm_id: fieldIds.expirationList.latest_call_without_further_topup,
      title: "Latest possible calls without further topup",
      dataIndex: "latest_call_without_further_topup",
      render: (latest_call_without_further_topup) => (
        <div className="center-aligned-text">
          {latest_call_without_further_topup
            ? latest_call_without_further_topup
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.rem_days_for_needed_topup) -
        Number(b.rem_days_for_needed_topup),
    },
    {
      perm_id: fieldIds.expirationList.remider_date_for_call,
      title: "Reminder date for call",
      dataIndex: "remider_date_for_call",
      render: (remider_date_for_call) => (
        <div className="center-aligned-text">
          {remider_date_for_call
            ? dayjs(remider_date_for_call).format("D MMM YYYY")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.remider_date_for_call);
        const dateB = dayjs(b.remider_date_for_call);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.remider_date_for_topup,
      title: "Reminder date for topup",
      dataIndex: "remider_date_for_topup",
      render: (remider_date_for_topup) => (
        <div className="center-aligned-text">
          {remider_date_for_topup
            ? dayjs(remider_date_for_topup).format("D MMM YYYY")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.remider_date_for_topup);
        const dateB = dayjs(b.remider_date_for_topup);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.expirationList.created_by_id,
      title: "Created by",
      dataIndex: "created_by_name",
      render: (created_by_name) => (created_by_name ? created_by_name : "-"),
    },

    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const filteredCsvData = csvData?.filter(
        (row) => selectedRowKeys.includes(row.id) // Assuming id is a unique identifier for each row
      );
      setFilteredData(filteredCsvData);
    },
    getCheckboxProps: (record) => ({
      sim_package_name: record.sim_package_name,
    }),
  };

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: expirationList?.count,
    onPageChange: handlePageChange,
  };
  const handleExportCSV = (headers, childHeaders, data) => {
    setCsvData(data);
    setCsvHeaders(headers);
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        handleExportCSV={handleExportCSV}
        data={expirationList?.results}
        columns={columns}
        permissionSet={permissions.permissions[expirationListModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        showColumnState={showColumnState}
        choiceData={{
          country: allCountriesTransformed,
        }}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
      />
    );
  }
  const handleExportSheet = () => {
    if (filteredData?.length > 0) {
      
      // Map the names with the excel sheet
      const columnsToConsider = {
        id: "ID",
        modem_id: "Modem ID",
        sub_package_name: "Sub package name",
        port: "Port",
        number: "Sim card number",
        iccid: "ICCID",
        actual_until: "Actual until",
        is_called: "Is called",
        call_date: "Call date",
        latest_balance_amount: "Latest balance amount",
        balance_date: "Balance date",
        latest_topup_amount: "Latest topup amount",
        topup_date: "Topup date",
      };

      // Filter data to include only selected columns
      const selectedColumnData = filteredData?.map((row) => {
        const filteredRow = {};
        Object.keys(columnsToConsider).forEach((columnKey) => {
          filteredRow[columnKey] = row[columnKey];
        });

        // Additional data transformations
        filteredRow.is_called = row.is_called === true ? "Yes" : "No";
        filteredRow.call_date = row.call_date
          ? dayjs(row.call_date).format("DD.MM.YYYY")
          : "";
        filteredRow.actual_until = row.actual_until
          ? dayjs(row.actual_until).format("DD.MM.YYYY")
          : "";
        filteredRow.balance_date = row.balance_date
          ? dayjs(row.balance_date).format("DD.MM.YYYY")
          : "";
        filteredRow.topup_date = row.topup_date
          ? dayjs(row.topup_date).format("DD.MM.YYYY")
          : "";

        return filteredRow;
      });

      // The excel will contains the capital and more human like column names
      const renamedData = selectedColumnData.map((item) => {
        return Object.keys(item).reduce((acc, key) => {
          const newKey = columnsToConsider[key] || key; // Use new key if exists, else original key
          acc[newKey] = item[key];
          return acc;
        }, {});
      });

      exportToExcel(renamedData, "Expiration List");
    } else {
      showToast({
        icon: "error",
        title: "Please select row to export excel sheet!",
        position: "top-end",
        timer: 1500,
      });
    }
  };
  return (
    <Container fluid>
      <LoadingOverlay isLoading={loadings} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> Expiration List
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[expirationListModuleID]
                          .fields || []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={expirationListModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged}
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | package name ..."
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center"
                  >
                    <GenericButton
                      onClickHandler={handleExportSheet}
                      name="Export"
                      icon={<ExportOutlined />}
                      className="export-btn"
                    />
                    <AddBulkFileForm
                      key={"addBulkButton"}
                      visible={addBulkModalVisible}
                      onOk={handleAddBulkOk}
                      onCancel={handleAddBulkCancel}
                    />
                    <GenericButton
                      onClickHandler={showAddBulkModal}
                      name="Bulk add"
                      icon={<FileAddOutlined />}
                      className="export-btn"
                    />

                    {isAddAllowed(
                      permissions.permissions[expirationListModuleID]
                    ) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-expiration-list")}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ExpirationListTable;
