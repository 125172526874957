import React from "react";
import ServicePartnerView from "views/ServicePartner/ServicePartnerView";

const ServicePartner = () => {
  return (
    <div>
      <ServicePartnerView />
    </div>
  );
};

export default ServicePartner;
