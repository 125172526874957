import React from "react";

import SendAgainView from "views/ResetPassword/SendAgain";

const SendAgain = () => {
  return (
    <div>
      <SendAgainView />
    </div>
  );
};

export default SendAgain;
