// ConfirmModal.jsx
import Swal from "sweetalert2";

const ConfirmModal = ({ title, text, onConfirm, deleteErrorText,deleteSuccessMessage,confirmButtonText, successTitle,failedTitle }) => {
  Swal.fire({
    title: title,
    html: text,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3f93fa",
    confirmButtonText: confirmButtonText||"Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      // Wait for the onConfirm callback to complete
      const response = await onConfirm();
      if (response.response && response?.response?.status != 200) {
        Swal.fire({
          title: failedTitle||"Not Deleted",
          text: deleteErrorText,
          icon: "error",
        });
      } else {
        await Swal.fire({
          title: successTitle||"Deleted!",
          text: deleteSuccessMessage,
          icon: "success",
        });
      }
    }
  });
};

export default ConfirmModal;
