import { makeRequest } from "helpers/apiHelper";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const headers = {
  "Content-Type": "multipart/form-data",
};



export const getExpirationList = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/expiration/expiration/?${queryParamsString}`;
  return makeRequest("get", url);
};

export const getModemDetails = async () => {
  const url = `/expiration/expiration/get-modems/`;
  return makeRequest("get", url);
};

export const getSimPackageDetails = async () => {
  const url = `/expiration/expiration/get-sim-pacakges/`;
  return makeRequest("get", url);
};

export const createExpirationList = async (data) => {
  return makeRequest("post", "/expiration/expiration/", data);
};

export const editExpirationList = async ({ id, data }) => {
  return makeRequest("patch", `/expiration/expiration/${id}/`, data);
};

export const addBalance = async ({ id, data }) => {
  return makeRequest("patch", `/expiration/add-balance/${id}/`, data);
};

export const addCall = async ({ id, data }) => {
  return makeRequest("patch", `/expiration/add-call/${id}/`, data);
};

export const addTopup = async ({ id, data }) => {
  return makeRequest("patch", `/expiration/add-topup/${id}/`, data);
};

export const addBulkExpiration = async (data) => {
  return makeRequest("post", "/expiration/bulk-add/", data, headers);
};

export const deleteExpirationList = async ({ id }) => {
  return makeRequest("delete", `/expiration/expiration/${id}/`);
};
