import React from "react";
import PCModemView from "views/PCModem/PCModemView";
const PCModem= () => {
  return (
    <div>
      <PCModemView />
    </div>
  );
};

export default PCModem;
