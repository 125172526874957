import React from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from "antd";

const GiveBackButton = ({ onChangeHandler, record }) => {
  return (
    <div key={`delete-${record?.id}`}>
      <Tooltip title={record.is_returned ? "Returned" : "Give Back"}>
        <Button
          key={`giveBack-${record?.id}`}
          variant="primary"
          block="true"
          className="mt-3 mb-4 btn btn-three give-back-btn"
          onClick={onChangeHandler}
          disabled={record.is_returned}
        >
          {record.is_returned ? (
            <i className="fas fa-undo"></i>
          ) : (
            <i className="fas fa-reply"></i>
          )}
        </Button>
      </Tooltip>
    </div>
  );
};

export default GiveBackButton;
