import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { addPermissions } from "store/slices/permissionsSlice";

import { getPermissions } from "API/userRoleAPI";
import { cacheDuration } from "helpers/apiHelper";

const usePermissions = (key) => {
  const dispatch = useDispatch();

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error : permissionsError,
    data: permissionsData,
    refetch: permissionRefetch,
  } = useQuery("permissions", getPermissions, {
    cacheTime: cacheDuration,
  });

  useEffect(() => {
    if (!permissionsLoading && !isPermissionsError) {
      // Dispatch the permissions data to the Redux store
      dispatch({ type: "SET_PERMISSIONS", payload: permissionsData });
    }
  }, [permissionsLoading, isPermissionsError, permissionsData, dispatch]);
  
  return {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions: permissionsData,
    refetch: permissionRefetch,
  };
};

export default usePermissions;
