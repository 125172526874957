import React, { useState } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { UserAddOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import { getAllCountries } from "API/supplierApi";
import LoadingOverlay from "components/Overlay";
import {
  deleteModemList,
  editModemList,
} from "API/modemListApi";
import {
  getModemDashData,
  getTrafficLights,
  startAction,
  stopAction,
  finishAction,
} from "API/modemDashApi";
import ConfirmModal from "components/Modal/Modal";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import ModemDashButtons from "components/Buttons/ModemDashButtons";
import FinishButton from "components/Buttons/FinishButton";
import ModemLogButton from "components/Buttons/ModemLogButton";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";
import { useLocation } from "react-router-dom";

const ModemDashboardTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedTerm, setSelectedTerm] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedTraffic, setSelectedTraffic] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged, setPermissionChanged] = useState(true);
  const [loadings, setLoading] = useState(false);

  const location = useLocation();

  const { RangePicker } = DatePicker;

  const modemDashModuleID = parseInt(
    process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    traffic_light_filter: selectedTraffic,
    search_filter: searchInput,
    start_date: startDate,
    end_date: endDate,
  };

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: modemDashData,
    refetch,
  } = useQuery(["modemDashData", queryParams], getModemDashData, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingTrafficLights,
    isError: isErrorTrafficLights,
    error: errorTrafficLights,
    data: trafficLights,
  } = useQuery("servicePartner", getTrafficLights, {
    cacheTime: cacheDuration,
  });
  const allTrafficLightsData = { id: "", name: "All" };

  const trafficLightsChoices = trafficLights?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesTransformed = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });
  const allData = { id: "", name: "All" };

  const termOptions = [
    { id: "1", name: "Short term" },
    { id: "2", name: "Long term" },
  ];
  const spTypesOptions = [
    { id: "1", name: "Main" },
    { id: "2", name: "Second" },
    { id: "3", name: "Third" },
  ];
  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  const modemEditor = useMutation(editModemList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      
      refetch();
      setLoading(false);
    },
  });
  const modemStopper = useMutation(stopAction, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      
      refetch();
      setLoading(false);
    },
  });
  const modemFinisher = useMutation(finishAction, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      
      refetch();
      setLoading(false);
    },
  });
  const removeModem = useMutation(deleteModemList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      
      refetch();
      setLoading(false);
    },
  });
  const modemStarter = useMutation(startAction, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      
      refetch();
      setLoading(false);
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {

    onMutate: () => {
      setLoading(true);
    },    onSuccess: async (response) => {
      
      await refetch();
      await permissionRefetch();
      setLoading(false);
      setPermissionChanged(true);
    },
  });


  // useEffect(() => {
  //   if (location.state?.greenModemFilter) {
  //    setSelectedTraffic(
  //    3)
  //   }
  // }, [location.state]);

  const handleEdit = (user) => {
    navigate("/edit-modem-list", { state: { data: user } });
  };

  const handleStart = async (record, sp) => {
    const startActionApi = async () => {
      try {
        const response = await modemStarter.mutateAsync({
          modem_id: record.id,
          service_partner_id: sp.id,
        });
        return response;
      } catch (error) {
      setLoading(false);

        return error; // Throw the error to be caught by the ConfirmModal
      }
    };
    let response
    await ConfirmModal({
      title: "Are you sure you want to start modem log?",
      text: sp?.remaining_interval_days_msg ? `<b style="color:red">${sp?.remaining_interval_days_msg}</b>` : "You won't be able to revert this!",
      onConfirm: async () => {
        const response = await startActionApi();
        return response;
      },
      confirmButtonText: "Yes, start it!",
      successTitle: "Modem log started successfully!",
      failedTitle: "Can't start!",
      deleteErrorText:
        response?.response?.data?.message || "Unable to start modem log! ", // Display the error message
      deleteSuccessMessage: "Modem log started successfully!",
    });
  };

  const handleStop = async (record, sp) => {
    const stopActionApi = async () => {
      try {
        const response = await modemStopper.mutateAsync({
          modem_id: record.id,
          service_partner_id: sp.id,
        });
        return response;
      } catch (error) {
      setLoading(false);

        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure you want to stop modem ?",
      text: "You won't be able to revert this!",
      onConfirm: stopActionApi,
      confirmButtonText: "Yes, stop it!",
      successTitle: "Modem log stopped successfully!",
      failedTitle: "Can't stop!",
      deleteErrorText: "Unable to stop modem log!",
      deleteSuccessMessage: "Modem log stopped successfully!",
    });
  };

  const handleFinish = async (record, sp) => {
    const finishActionApi = async () => {
      try {
        const response = await modemFinisher.mutateAsync({
          modem_id: record.id,
        });
        return response;
      } catch (error) {
      setLoading(false);

        return error;
      }
    };
    await ConfirmModal({
      title: "Are you sure you want to finish modem logs?",
      text: "You won't be able to revert this!",
      onConfirm: finishActionApi,
      confirmButtonText: "Yes, finish it!",
      successTitle: "Modem log finished successfully!",
      failedTitle: "Can't finish!",
      deleteErrorText: "Unable to finish log!",
      deleteSuccessMessage: "Modem log stopped successfully!",
    });
  };

  const handleToggle = async (id, status) => {
    const response = await modemEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };

  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen && selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: modemDashModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
    setLoading(false);

  };

  const actionButtons = (record) => {
    const buttons = [];

    if (
      permissions.permissions[modemDashModuleID].fields[
        fieldIds.modemDashboard.start_btn
      ]
    ) {
      // Check if service_partners is not null
      if (record?.service_partners !== null) {
        // Loop through service_partners keys
        for (let key = 1; key <= 3; key++) {
          const keyString = key.toString();
          // Check if the key exists in service_partners
          if (record?.service_partners.hasOwnProperty(keyString)) {
            const buttonStatus = record?.service_partners[keyString].status;

            buttons.push(
              <ModemDashButtons
                key={`button-${record?.id}-${key}`}
                record={record}
                name={
                  buttonStatus == "Not Running"
                    ? `Start ${key}`
                    : buttonStatus == "start event in process"
                    ? `Stop ${key}`
                    : buttonStatus == "stop event in queue"
                    ? `Stop ${key}`
                    : buttonStatus == "start event in queue"
                    ? `Start ${key}`
                    : buttonStatus == "Stopped"
                    ? `Start ${key}`
                    : buttonStatus == "started"
                    ? `Stop ${key}`
                    : ""
                }
                partner={record?.service_partners[keyString]}
                onClickHandler={() =>
                  buttonStatus == "Not Running" || buttonStatus == "Stopped"
                    ? handleStart(record, record?.service_partners[keyString])
                    : handleStop(record, record?.service_partners[keyString])
                }
              />
            );
          }
        }
      }

      //     // Check if service_partners is not null
      // if (record?.service_partners !== null) {
      //   // Loop through service_partners keys
      //   for (let key = 1; key <= 3; key++) {
      //     const keyString = key.toString();
      //     // Check if the key exists in service_partners
      //     if (record?.service_partners.hasOwnProperty(keyString)) {
      //       // Check conditions to determine if it's a Start or Stop button
      //       const buttonStatus = /* Your condition to determine if it's a Start button */
      //       const buttonText = buttonStatus ? "Start" : "Stop";
      //       const onClickHandler = buttonStatus
      //         ? () => handleStart(record, record?.service_partners[keyString])
      //         : () => handleStop(record, record?.service_partners[keyString]);

      //       buttons.push(
      //         <ModemDashButtons
      //           key={`button-${record?.id}-${key}`}
      //           record={record}
      //           name={buttonText}
      //           partner={record?.service_partners[keyString]}
      //           onClickHandler={onClickHandler}
      //         />
      //       );
      //     }
      //   }
      // }
    }

    if (
      permissions.permissions[modemDashModuleID].fields[
        fieldIds.modemDashboard.finish_btn
      ] &&
      record?.service_partners !== null
    ) {
      buttons.push(
        <FinishButton
          key={`button-finish-${record?.id}`}
          record={record}
          name="Finish"
          partner={record?.service_partners[1]}
          onClickHandler={() => handleFinish(record)}
        />
      );
    }

    return <div className="action-buttons">{buttons}</div>;
  };

  const logButton = (record) => {
    const buttons = [];
    if (
      permissions.permissions[modemDashModuleID].fields[
        fieldIds.modemDashboard.log_btn
      ]
    ) {
      buttons.push(
        <ModemLogButton
          key={`log-${record?.id}`}
          record={record}
          onClickHandler={() =>
            navigate("/modem-log", { state: { data: record } })
          }
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };
  const columns = [
    
    {
      perm_id: fieldIds.modemDashboard.modem_id,
      title: "Modem name",
      dataIndex: "modem_name",
      sorter: (a, b) => {
        return a.modem_name.localeCompare(b.modem_name);
      },
    },
    {
      perm_id: fieldIds.modemDashboard.start_date,
      title: "Start date of last/latest log",
      dataIndex: "start_date",
      render: (start_date) => (
        <div
          className="center-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {start_date ? dayjs(start_date).format("D MMM YYYY") : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.start_date);
        const dateB = dayjs(b.start_date);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.modemDashboard.duration,
      title: "Duration in minutes",
      dataIndex: "duration_in_min",
      render: (duration_in_min) => (
        <div
          className="center-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {duration_in_min ? duration_in_min.toFixed(2) : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.duration_in_min) - Number(b.duration_in_min),
    },
    
    {
      perm_id: fieldIds.modemDashboard.duration,
      title: "Duration in days",
      dataIndex: "duration_in_days",
      render: (duration_in_days) => (
        <div
          className="center-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {duration_in_days ? duration_in_days.toFixed(2) : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.duration_in_days) - Number(b.duration_in_days),
    },
    {
      perm_id: fieldIds.modemDashboard.actual_profit,
      title: "Actual profit (original currency)",
      dataIndex: "actual_profit",
      render: (actual_profit,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
         {actual_profit!=null
            ? (more.orignal_currency == "USD"
                ? "$"
                : more.orignal_currency == "EUR"
                ? "€"
                : more.orignal_currency == "CZK"
                ? "Kč"
                : more.orignal_currency == "RUB"
                ? "₽"
                : "") + ((actual_profit % 1 !== 0) ? actual_profit.toFixed(4):actual_profit)
            : "-"}
   
        </div>
      ),
      sorter: (a, b) => Number(a.actual_profit) - Number(b.actual_profit),
    },
    {
      perm_id: fieldIds.modemDashboard.actual_profit,
      title: "Actual profit (USD)",
      dataIndex: "actual_profit_usd",
      render: (actual_profit_usd) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {actual_profit_usd!=null ? `$${(actual_profit_usd % 1 !== 0) ? actual_profit_usd.toFixed(4):actual_profit_usd}` : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.actual_profit_usd) - Number(b.actual_profit_usd),
    },
    {
      perm_id: fieldIds.modemDashboard.actual_profit,
      title: "Actual profit (EUR)",
      dataIndex: "actual_profit_eur",
      render: (actual_profit_eur) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {actual_profit_eur!=null ? `€${(actual_profit_eur % 1 !== 0) ? actual_profit_eur.toFixed(4):actual_profit_eur}` : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.actual_profit_eur) - Number(b.actual_profit_eur),
    },
    {
      perm_id: fieldIds.modemDashboard.avg_profit_per_sim,
      title: "Average profit per sim (original currency)",
      dataIndex: "avg_profit_per_sim",
      render: (avg_profit_per_sim,more) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {avg_profit_per_sim!=null
            ? (more.orignal_currency == "USD"
                ? "$"
                : more.orignal_currency == "EUR"
                ? "€"
                : more.orignal_currency == "CZK"
                ? "Kč"
                : more.orignal_currency == "RUB"
                ? "₽"
                : "") + ((avg_profit_per_sim % 1 !== 0) ? avg_profit_per_sim.toFixed(4):avg_profit_per_sim)
            : "-"}

        </div>
      ),
      sorter: (a, b) => Number(a.avg_profit_per_sim) - Number(b.avg_profit_per_sim),
    },
    {
      perm_id: fieldIds.modemDashboard.avg_profit_per_sim,
      title: "Average profit per sim (USD)",
      dataIndex: "avg_profit_per_sim_usd",
      render: (avg_profit_per_sim_usd) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {avg_profit_per_sim_usd!=null ? `$${(avg_profit_per_sim_usd % 1 !== 0) ? avg_profit_per_sim_usd.toFixed(4):avg_profit_per_sim_usd}` : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.avg_profit_per_sim_usd) - Number(b.avg_profit_per_sim_usd),
    },
    {
      perm_id: fieldIds.modemDashboard.avg_profit_per_sim,
      title: "Average profit per sim (EUR) ",
      dataIndex: "avg_profit_per_sim_eur",
      render: (avg_profit_per_sim_eur) => (
        <div
          className="right-aligned-text"
          style={{
            whiteSpace: "pre-wrap",
          }}
        >
          {avg_profit_per_sim_eur!=null ? `€${(avg_profit_per_sim_eur % 1 !== 0) ? avg_profit_per_sim_eur.toFixed(4):avg_profit_per_sim_eur}` : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.avg_profit_per_sim_eur) - Number(b.avg_profit_per_sim_eur),
    },
    {
      perm_id: fieldIds.modemDashboard.last_updated_rate_date,
      title: "Last profit updated at",
      dataIndex: "last_updated_rate_date",
      render: (last_updated_rate_date) => (
        <div
          className="center-aligned-text "
          style={{
            whiteSpace: "pre-wrap",
            width: "max-content",
          }}
        >
          {last_updated_rate_date ? dayjs(last_updated_rate_date).format("D MMM YYYY, h:mm:ss A"): "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.modemDashboard.traffic_light,
      title: "Traffic light",
      dataIndex: "traffic_light",
      render: (traffic_light) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "100px",
            minWidth: "50px",

            whiteSpace: "pre-wrap",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {["Yellow", "Gray", "Green"].includes(traffic_light) ? (
            <div
              style={{
                height: "30px",
                width: "50px",
                background:
                  traffic_light === "Yellow"
                    ? "yellow"
                    : traffic_light === "Gray"
                    ? "grey"
                    : traffic_light === "Green"
                    ? "green"
                    : "N/A",
                border: "1px solid black",
              }}
            ></div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "black",
              }}
            >
              N/A
            </div>
          )}
        </div>
      ),
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      buttons: actionButtons(),
      render: (_, record) => {
        return actionButtons(record);
      },
    },
    {
      perm_id: fieldIds.modemDashboard.log_btn,
      title: "Logs",
      dataIndex: "log_btn",
      buttons: logButton(),
      render: (_, record) => logButton(record),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: modemDashData?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        data={modemDashData?.results}
        columns={columns}
        permissionSet={permissions.permissions[modemDashModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        showColumnState={showColumnState}
      />
    );
  }

  const handleDateChange = (dates, dateStrings) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> Modem Dashboard
              </Card.Title>
              <Breadcrumbs/>
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="8"
                    className="d-flex align-items-center gap-2 filter-col-wrapper"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[modemDashModuleID].fields || []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={modemDashModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged}
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    
                    {permissions?.permissions[modemDashModuleID].fields[
                      fieldIds.modemDashboard.avg_profit_per_sim
                    ]?.view_access &&
                      trafficLights && (
                        <DropdownFilter
                          title="Traffic Light"
                          selectedValue={selectedTraffic}
                          items={[{id:"",name:"All"},...trafficLightsChoices]}
                          onSelect={(e) => {setSelectedTraffic(e?.toString())
                          }}
                          filterName="Traffic Light"
                          defaultValue={location.state?.greenModemFilter?{
                            key: 2,
                            label: "Green",
                            value: 3,
                          }:""}
                        />
                      )}

                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | Name "
                    />
                    <RangePicker onChange={handleDateChange} />
                  </Col>
                  <Col
                    md="2"
                    className="d-flex justify-content-end align-items-center"
                  >
                    {isAddAllowed(
                      permissions.permissions[modemDashModuleID]
                    ) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-modem-list")}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                {/* <Row className="mb-3 gap-2">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper"
                  >
                    <RangePicker onChange={handleDateChange} />
                  </Col>
                </Row> */}
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ModemDashboardTable;
