import React from "react";
import AddServicePartnerView from "views/ServicePartner/AddServicePartnerView";
const AddServicePartner = () => {
  return (
    <div>
      <AddServicePartnerView />
    </div>
  );
};

export default AddServicePartner;
