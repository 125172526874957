import React from "react";
import SimPackageView from "views/SimPackage/SimPackageView";
const SimPackage = () => {
  return (
    <div>
      <SimPackageView />
    </div>
  );
};

export default SimPackage;
