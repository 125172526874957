import React from "react";
import SoftwareLogView from "views/SoftwareLog/SoftwareLogView";

const SoftwareLog = () => {
  return (
    <div>
      <SoftwareLogView />
    </div>
  );
};

export default SoftwareLog;
