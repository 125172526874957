import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "../../styles/Ccm.css";
import { Breadcrumbs } from "Breadcrumb";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";

import { cacheDuration } from "helpers/apiHelper";
import { fieldIds } from "constants/moduleFields";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
import moment from "moment";
import LoadingOverlay from "components/Overlay";
import { DatePicker, Space } from "antd";
import { createCCMList, editCCMList, getCCMid } from "API/ccmlistApI";
import { getCurrencyChoices } from "API/simPackageAPI";
const AddCCMListForm = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [currencydate, setcurrencydate] = useState(null);
  const [loadings, setLoading] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  // const [isLoading, setIsLoading] = useState(true);
  const ccmid = location.state?.id;
  const ccmListModuleID = parseInt(
    process.env.REACT_APP_CCM_LIST_MODULE_ID,
    10
  );
  const {
    isLoading,
    isError,
    error,
    data: Ccmlist,
    refetch,
  } = useQuery(["ccmListsd", ccmid ?? ""], getCCMid, {
    cacheTime: cacheDuration,
  });
  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingCurrency,
    isError: isErrorCurrency,
    error: errorCurrency,
    data: currencyChoices,
  } = useQuery("currencyChoices", getCurrencyChoices, {
    cacheTime: cacheDuration,
  });

  const currencyChoicesData = currencyChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  useEffect(() => {
    if (location.state) {
      setIsEdit(true);
      setValue(
        "currencydate",
        moment(moment(location.state.currency_date).format("YYYY-MM-DD"), "YYYY-MM-DD")
      );
      setValue(
        "currency_rate_eur",
        `${
          location?.state.currency_rate_eur == null
            ? ""
            : location?.state.currency_rate_eur
        }`
      );
      setValue(
        "currency_rate_rub",
        `${
          location?.state.currency_rate_rub == null
            ? ""
            : location?.state.currency_rate_rub
        }`
      );
      setValue(
        "currency_rate_czk",
        `${
          location?.state.currency_rate_czk == null
            ? ""
            : location?.state.currency_rate_czk
        }`
      );
      setcurrencydate(
        moment(moment(location.state.date).format("YYYY-MM-DD"), "YYYY-MM-DD")
      );
    }
  }, [location.state]);

  const modemCreator = useMutation(createCCMList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);
      navigate("/ccm-list");
      await showToast({
        icon: "success",
        title: "CCM added successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const modemEditor = useMutation(editCCMList, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);
      navigate("/ccm-list");
      await showToast({
        icon: "success",
        title: "CCM edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleReset = () => {
    // Reset the form fields
    reset();
    setValue("currencydate", "");
    setValue("currency_rate_eur", "");
    setValue("currency_rate_rub", "");
    setValue("currency_rate_czk", "");
  };

  const onSubmit = async (data) => {
    const dataObject = {
      currency_rate_eur:
        data.currency_rate_eur == "" ? null : data.currency_rate_eur, // Renamed key
      currency_rate_rub:
        data.currency_rate_rub == "" ? null : data.currency_rate_rub,
      currency_rate_czk: data.currency_rate_czk == "" ? null : data.currency_rate_czk,
      currency_date: moment(data.currencydate).format("YYYY-MM-DD"),
    };

    try {
      let response;
      if (isEdit) {
        response = await modemEditor.mutateAsync({
          id: ccmid,
          data: dataObject,
        });
      } else {
        response = await modemCreator.mutateAsync(dataObject);
      }
    } catch (error) {
      if (error.response.status == 400) {
        setLoading(false);
        setApiError(error.response.data.message);
      } else {
        setLoading(false);

        showServerMessage();
      }
    }
  };
  const handleDateChange = (date, dateString) => {
    setcurrencydate(date);
    setValue("currencydate", moment(date).format("YYYY-MM-DD"));
  };

  return (
    <div>
      <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">{isEdit ? "Edit" : "Add"} CCM</Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          <fieldset>
                            <legend>CCM details</legend>

                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.custom_Currency.currency_rate_eur,
                                permissions?.permissions[ccmListModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="currency_rate_eur">
                                      <span className="label-text">
                                        Currency rate (EUR/USD)
                                      </span>
                                    </label>

                                    <Controller
                                      control={control}
                                      name="currency_rate_eur"
                                      rules={{
                                        required: false,
                                        pattern: {
                                          value: /^[0-9]*\.?[0-9]{0,2}$/,
                                          message:
                                            "Valid number and decimal are allowed up to 2 decimal places only",
                                        },
                                      }}
                                      render={({ field }) => (
                                        <Form.Control
                                          defaultValue={
                                            isEdit
                                              ? Ccmlist?.currency_rate_eur
                                              : ""
                                          }
                                          placeholder=""
                                          type="text"
                                          {...field}
                                        ></Form.Control>
                                      )}
                                    />
                                    <span className="error-message">
                                      {errors.currency_rate_eur?.type ===
                                        "pattern" &&
                                        errors.currency_rate_eur.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.custom_Currency.currency_rate_rub,
                                permissions?.permissions[ccmListModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="currency_rate_rub">
                                      <span className="label-text">
                                        Currency rate (RUB/USD)
                                      </span>
                                    </label>

                                    <Controller
                                      control={control}
                                      name="currency_rate_rub"
                                      rules={{
                                        required: false,
                                        pattern: {
                                          value: /^[0-9]*\.?[0-9]{0,2}$/,
                                          message:
                                            "Valid number and decimal are allowed up to 2 decimal places only",
                                        },
                                      }}
                                      render={({ field }) => (
                                        <Form.Control
                                          defaultValue={
                                            isEdit
                                              ? Ccmlist?.currency_rate_rub
                                              : ""
                                          }
                                          placeholder=""
                                          type="text"
                                          {...field}
                                        ></Form.Control>
                                      )}
                                    />
                                    <span className="error-message">
                                      {errors.currency_rate_rub?.type ===
                                        "pattern" &&
                                        errors.currency_rate_rub.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.custom_Currency.currency_rate_czk,
                                permissions?.permissions[ccmListModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="currency_rate_czk">
                                      <span className="label-text">
                                        Currency rate (CZK/USD)
                                      </span>
                                    </label>

                                    <Controller
                                      control={control}
                                      name="currency_rate_czk"
                                      rules={{
                                        required: false,
                                        pattern: {
                                          value: /^[0-9]*\.?[0-9]{0,2}$/,
                                          message:
                                            "Valid number and decimal are allowed up to 2 decimal places only",
                                        },
                                      }}
                                      render={({ field }) => (
                                        <Form.Control
                                          defaultValue={
                                            isEdit
                                              ? Ccmlist?.currency_rate_czk
                                              : ""
                                          }
                                          placeholder=""
                                          type="text"
                                          {...field}
                                        ></Form.Control>
                                      )}
                                    />
                                    <span className="error-message">
                                      {errors.currency_rate_czk?.type ===
                                        "pattern" &&
                                        errors.currency_rate_czk.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.custom_Currency.currency_date,
                                permissions?.permissions[ccmListModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="currencydate">
                                      <span className="label-text">
                                        Currency date
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Controller
                                      name="currencydate"
                                      control={control}
                                      rules={{
                                        required: "Currency Date is required.",
                                        validate: (value) => {
                                          if (value === "") {
                                            return "Currency Date is required.";
                                          }
                                          const date = moment(
                                            value,
                                            "YYYY-MM-DD"
                                          );
                                          return !date.isValid()
                                            ? "Date format should be YYYY-MM-DD"
                                            : null;
                                        },
                                      }}
                                      render={({
                                        field,
                                        formState: { errors },
                                      }) => (
                                        <DatePicker
                                          value={
                                            field.value
                                              ? moment(field.value).startOf(
                                                  "day"
                                                )
                                              : ""
                                          }
                                          onChange={(date, dateString) => {
                                            handleDateChange(date, dateString);
                                            field.onChange(
                                              dateString
                                                ? moment(dateString).format(
                                                    "YYYY-MM-DD"
                                                  )
                                                : ""
                                            );
                                          }}
                                          format="YYYY-MM-DD"
                                          className={
                                            errors.currencydate ? "error" : ""
                                          }
                                        />
                                      )}
                                    />
                                    {errors.currencydate && (
                                      <span className="error-message">
                                        {errors.currencydate.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) && (
                      <Col md="12">
                        {apiError && (
                          <FormAlertMessage message={apiError} type="error" />
                        )}
                        {displayServerMessage && (
                          <FormAlertMessage
                            message={serverErrorMessage}
                            type="error"
                          />
                        )}
                      </Col>
                    )}

                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right  add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right  mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddCCMListForm;
