import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { getPieChartData } from "API/dashboardAPI";
import { useQuery } from "react-query";
import { cacheDuration } from "helpers/apiHelper";
import "./charts.css";
import { Card } from "react-bootstrap";
import ChartDataLabels from "chartjs-plugin-datalabels";
import LoadingOverlay from "components/Overlay";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const SimCardsPieChart = () => {
  const [loadings, setLoading] = useState(false);

  const {
    isLoading: isLoadingSimData,
    isError: isErrorSimData,
    error: errorSimData,
    data: simData,
  } = useQuery("simData", getPieChartData, {
    cacheTime: cacheDuration,
  });

  const data = {
    labels: ["In use", "Defective", "Unused"],
    datasets: [
      {
        label: "No. of SIM",

        data: [
          simData?.used || 0,
          simData?.defective || 0,
          simData?.unused || 0,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
        datalabels: {
          anchor: "end",
          align: "end",
        },
      },
    ],
  };
  const option = {
    maintainAspectRatio: false, // This ensures that width and height options are respected
    layout: {
      padding: 50,
    },
    plugins: {
      legend: {
        position: "right",
      },

      datalabels: {
        // backgroundColor: function(context) {
        //   return context.dataset.backgroundColor;
        // },
        // borderColor: 'black',
        // borderRadius: 25,
        // borderWidth: 2,
        display: true,
        color: "black",
        display: function (context) {
          var dataset = context.dataset;
          var value = dataset.data[context.dataIndex];
          return value;
        },
        font: {
          weight: "bold",
        },
        padding: 6,
        // formatter: Math.round(2),
        formatter: function (value, context) {
          var sum = context.dataset.data.reduce((a, b) => a + b, 0);
          let percentage;
          if (sum === 0) {
            percentage = "0%";
          } else {
            percentage = ((value / sum) * 100).toFixed(2) + "%";
          }
          return value + " (" + percentage + ")";
        },
      },

      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || "";
            let value = context.formattedValue || 0;
            // Ensure value is converted to a number
            if (typeof value === "string") {
              value = parseFloat(value.replace(/[^0-9.-]+/g, ""));
            }
            let sum = context.dataset.data.reduce((a, b) => a + b, 0);
            let percentage;
            if (sum === 0) {
              percentage = "0%";
            } else {
              percentage = ((value / sum) * 100).toFixed(2) + "%";
            }
            // return value + " (" + percentage + ")";
            return `${label}: ${value + " (" + percentage + ")"}`;
          },
        },
      },
    },
  };

  return (
    <div className="piechart-container-wrap">
      <LoadingOverlay isLoading={loadings} />
      <div className="piechart-container">
        <Card className="pie-chart-card">
          <Card.Header className="pier-chart-header">
            <Card.Title className="dashboard-element-title">
              SIM cards
            </Card.Title>
          </Card.Header>
          <Card.Body className="pie-chart-card-body">
            <Pie data={data} options={option} />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default SimCardsPieChart;
