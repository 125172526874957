import { makeRequest } from "helpers/apiHelper";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const getSuppliers = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/supplier/supplier/?${queryParamsString}`;
  return makeRequest("get", url);
};
export const createSupplier = async (data) => {
  return makeRequest("post", "/supplier/supplier/", data);
};

export const editSupplier = async ({ id, data }) => {
  return makeRequest("patch", `/supplier/supplier/${id}/`, data);
};

export const getUsersList = async () => {
  const url = `/supplier/supplier/get-all-users`;
  return makeRequest("get", url);
};


export const getPaymentType = async () => {
  const url = `/supplier/supplier/get-payment-type`;
  return makeRequest("get", url);
};

export const getAllCountries = async () => {
  const url = `/get-all-countries/`;
  return makeRequest("get", url);
};

export const deleteSupplier = ({id}) => {
  return makeRequest("delete", `/supplier/supplier/${id}/`,);
};