import { makeRequestWithoutToken, makeRequest } from "helpers/apiHelper";
import {  clearLocalStorage } from "helpers/authHelper";
import { getRefreshToken } from "helpers/tokenManager";

const baseURL = process.env.REACT_APP_API_BASE_URL;

export const getRoles = async () => {
  const url = `/registration/user-role/`;
  return makeRequest("get", url);
};

export const getPermissions = async () => {
  const url = `/access`;
  const data = await makeRequest("get", url);
  if(!data.user_status){
    const refresh={"refresh":getRefreshToken()}
    const logoutData = await makeRequestWithoutToken("post", `/auth/logout/`, refresh);
    clearLocalStorage();
    navigate("/login");
  }
  return data

};

export const retrieveRolePermissions = async (id) => {
  const url = `/registration/user-role/${id.queryKey[1]}/`;
  return makeRequest("get", url);
};
export const editRole = async ({ id, permissions, data }) => {
  return makeRequest("patch", `/registration/user-role/${id}/`, data?data:permissions);
};
export const addRole = async (data) => {
  return makeRequest("post", `/registration/user-role/`,data);
};

export const deleteUserRole = ({id}) => {
  return makeRequest("delete", `/registration/user-role/${id}/`,);
};

export const changeShowHideColumns= async ({ id, permissions, data }) => {
  return makeRequest("patch", `/default-visibility/${id}/`, data?data:permissions);
};