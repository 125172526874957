import React from "react";
import CcmListview from "views/ccmList/CcmListview";
const CCMList = () => {
  return (
    <div>
      <CcmListview />
    </div>
  );
};

export default CCMList;
