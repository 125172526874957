import React from "react";
import AddExpirationListView from "views/ExpirationList/AddExpirationListView";
const AddExpirationList = () => {
  return (
    <div>
      <AddExpirationListView />
    </div>
  );
};

export default AddExpirationList;
