import React from "react";
import AddServicePartnerForm from "components/ServicePartner/AddServicePartnerForm";
const AddServicePartnerView = () => {
  return (
    <div>
      <AddServicePartnerForm />
    </div>
  );
};

export default AddServicePartnerView;
