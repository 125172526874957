import React from "react";

import ChangePasswordView from "views/ChangePassword/ChangePassword";

const ChangePassword = () => {
  return (
    <div>
      <ChangePasswordView />
    </div>
  );
};

export default ChangePassword;
