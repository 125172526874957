import React from "react";
import SimPackageTable from "components/Table/SimPackageTable";
const SimPackageView = () => {
  return (
    <div>
      <SimPackageTable />
    </div>
  );
};

export default SimPackageView;
