import React from "react";
import PCModemTable from "components/Table/PCModemTable";
const PCModemView = () => {
  return (
    <div>
      <PCModemTable />
    </div>
  );
};

export default PCModemView;
