import React from "react";
import NetworkProviderTable from "components/Table/NetworkProviderTable";
const NetworkProvider = () => {
  return (
    <div>
      <NetworkProviderTable />
    </div>
  );
};

export default NetworkProvider;
