import Swal from "sweetalert2";
import "./toast.css";

const showToast = ({ icon, title, position = "center-end", timer = 200 }) => {
  const Toast = Swal.mixin({
    toast: true,
    position: position,
    iconColor: "white",
    customClass: {
      popup: "colored-toast",
    },
    showConfirmButton: false,
    timer: timer,
    timerProgressBar: true,
  });

  Toast.fire({
    icon: icon,
    title: title,
  });
};

export default showToast;

