import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "./AddPCModemForm.css";
import { Breadcrumbs } from "Breadcrumb";
import LoadingOverlay from "components/Overlay";
import { cacheDuration } from "helpers/apiHelper";
import { getActivationType } from "API/networkProviderApi";
import { createPCModem, editPCModem, getAllModem } from "API/pcModemApi";
import { fieldIds } from "constants/moduleFields";
import showToast from "components/Toast/Toast";
import PasswordInput from "components/PasswordInput/PasswordInput";
import CancelButton from "components/CancelButton/CancelButton";
const AddPCModemForm = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm();

  const location = useLocation();
  const [loadings, setLoading] = useState(false);

  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();

  const [selectedSoft1Prep, setSelectedSoft1Prep] = useState(null);
  const [selectedSoft2Prep, setSelectedSoft2Prep] = useState(null);
  const [selectedSoft3Prep, setSelectedSoft3Prep] = useState(null);
  const [selectedModem, setSelectedModem] = useState(null);
  const [selectedConfigStatus, setSelectedConfigStatus] = useState(null);
  const pcModemModuleID = parseInt(
    process.env.REACT_APP_PC_MODEM_MODULE_ID,
    10
  );
  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingModems,
    isError: isErrorModems,
    error: errorModems,
    data: allModems,
  } = useQuery("modemList", getAllModem, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingActivationType,
    isError: isErrorActivation,
    error: errorActivation,
    data: activationType,
  } = useQuery("activationType", getActivationType, {
    cacheTime: cacheDuration,
  });

  const activationTypeChoices = activationType?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const configOption = [
    {
      id: "1",
      name: "Ready",
    },
    {
      id: "2",
      name: "Not Ready",
    },
  ];

  const booleanOptions = [
    { id: "1", name: "Yes" },
    { id: "0", name: "No" },
  ];

  useEffect(() => {
    if (location.state && allModems) {
      const pcModemData = location.state.data;
      const statusVal = configOption?.find((value) => {
        return !isNaN(Number(pcModemData.config_status))
          ? value.id == pcModemData.config_status
          : value.name == pcModemData.config_status;
      });
      const modemVal = allModems?.find(
        (value) => value.id == pcModemData.modem_id
      );

      setValue("pcName", pcModemData.pc_name);
      setSelectedModem(modemVal);
      setValue("modem", modemVal.id);
      setValue("anydesId", pcModemData.anydesk_id);
      setValue("anydeskPassFull", pcModemData.password_full_access);
      setValue("anydeskPassLim", pcModemData.password_for_limited_access);
      setValue("sellerName", pcModemData.password_for_limited_access);
      setValue("windowsLicKey", pcModemData.windows_license_key);
      setValue("sellerName", pcModemData.seller_shop_name);
      setSelectedConfigStatus(statusVal);
      setValue("configStatus", statusVal.id);
      setSelectedSoft1Prep(
        booleanOptions[pcModemData.is_software_prepared_for_sp1 == true ? 0 : 1]
      );
      setValue(
        "softwareMainSP",
        booleanOptions[pcModemData.is_software_prepared_for_sp1 == true ? 0 : 1]
          .id
      );
      setSelectedSoft2Prep(
        booleanOptions[pcModemData.is_software_prepared_for_sp2 == true ? 0 : 1]
      );
      setValue(
        "softwareSecondSP",
        booleanOptions[pcModemData.is_software_prepared_for_sp2 == true ? 0 : 1]
          .id
      );
      setSelectedSoft3Prep(
        booleanOptions[pcModemData.is_software_prepared_for_sp3 == true ? 0 : 1]
      );
      setValue(
        "softwareThirdSP",
        booleanOptions[pcModemData.is_software_prepared_for_sp3 == true ? 0 : 1]
          .id
      );
      setValue("notes", pcModemData.notes);

      setIsEdit(true);
    }
  }, [location.state, isLoadingModems]);

  const pcModemCreator = useMutation(createPCModem, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      navigate("/pc-modem");
      await showToast({
        icon: "success",
        title: "PC modem added successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const pcModemEditor = useMutation(editPCModem, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);
      navigate("/pc-modem");
      await showToast({
        icon: "success",
        title: "PC modem edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleReset = () => {
    // Reset the form fields
    setSelectedSoft1Prep(null);
    setSelectedSoft2Prep(null);
    setSelectedSoft3Prep(null);
    setSelectedConfigStatus(null);
    setSelectedModem(null);
    reset();
  };

  const onSubmit = async (data) => {
    setApiError(false);
    // var defective_port_string = data.defective_port.join(',');

    const modulePermission = permissions?.permissions[pcModemModuleID];
    const formData = new FormData();

    isFieldAddableOrEditable(fieldIds.pcModem.pc_name, modulePermission) &&
      data.pcName &&
      formData.append("pc_name", data.pcName);

    isFieldAddableOrEditable(fieldIds.pcModem.modem_id, modulePermission) &&
      selectedModem &&
      formData.append("modem_id", selectedModem ? selectedModem.id : "");

    isFieldAddableOrEditable(fieldIds.pcModem.anydesk_id, modulePermission) &&
      data.anydesId &&
      formData.append("anydesk_id", data.anydesId);

    isFieldAddableOrEditable(
      fieldIds.pcModem.password_full_access,
      modulePermission
    ) &&
      formData.append(
        "password_full_access",
        data.anydeskPassLim ? data.anydeskPassLim : ""
      );

    isFieldAddableOrEditable(
      fieldIds.pcModem.password_for_limited_access,
      modulePermission
    ) &&
      formData.append(
        "password_for_limited_access",
        data.anydeskPassFull ? data.anydeskPassFull : ""
      );
    isFieldAddableOrEditable(
      fieldIds.pcModem.seller_shop_name,
      modulePermission
    ) &&
      data.sellerName &&
      formData.append("seller_shop_name", data.sellerName);
    isFieldAddableOrEditable(
      fieldIds.pcModem.windows_license_key,
      modulePermission
    ) &&
      formData.append(
        "windows_license_key",
        data.windowsLicKey ? data.windowsLicKey : ""
      );
    isFieldAddableOrEditable(
      fieldIds.pcModem.config_status,
      modulePermission
    ) &&
      selectedConfigStatus &&
      formData.append(
        "config_status",
        selectedConfigStatus ? selectedConfigStatus.id : ""
      );

    isFieldAddableOrEditable(
      fieldIds.pcModem.is_software_prepared_for_sp1,
      modulePermission
    ) &&
      selectedSoft1Prep &&
      formData.append(
        "is_software_prepared_for_sp1",
        selectedSoft1Prep ? selectedSoft1Prep.id : ""
      );
    isFieldAddableOrEditable(
      fieldIds.pcModem.is_software_prepared_for_sp2,
      modulePermission
    ) &&
      selectedSoft2Prep &&
      formData.append(
        "is_software_prepared_for_sp2",
        selectedSoft2Prep ? selectedSoft2Prep.id : ""
      );
    isFieldAddableOrEditable(
      fieldIds.pcModem.is_software_prepared_for_sp3,
      modulePermission
    ) &&
      selectedSoft3Prep &&
      formData.append(
        "is_software_prepared_for_sp3",
        selectedSoft3Prep ? selectedSoft3Prep.id : ""
      );

    isFieldAddableOrEditable(fieldIds.pcModem.notes, modulePermission) &&
      formData.append("notes", data.notes ? data.notes : "");

    try {
      let response;
      if (isEdit) {
        response = await pcModemEditor.mutateAsync({
          id: location.state.data.id,
          data: formData,
        });
      } else {
        response = await pcModemCreator.mutateAsync(formData);
      }
    } catch (error) {
      if (error.response.status == 400) {
        setLoading(false);
        setApiError(error.response.data.message);
      } else {
      setLoading(false);

        showServerMessage();
      }
    }
  };

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">
                  {isEdit ? "Edit" : "Add"} PC modem
                </Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.pc_name,
                            permissions?.permissions[pcModemModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="PC Name">
                                  <span className="label-text">PC name</span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("pcName", {
                                    required: true,
                                    maxLength: 50,
                                    minLength: 3,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.pcName?.type === "required" &&
                                    "PC name is required."}
                                  {errors.pcName?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.pcName?.type === "minLength" &&
                                    "Enter minimum 3 characters."}
                                  {errors.pcName && errors.pcName.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.modem_id,
                            permissions?.permissions[pcModemModuleID]
                          ) &&
                            allModems && (
                              <Col className="" md="4">
                                <CustomDrodown
                                  label="Modem"
                                  selectedOption={selectedModem}
                                  options={allModems}
                                  handleSelect={(e) => setSelectedModem(e)}
                                  control={control}
                                  errors={errors}
                                  name="modem"
                                  rules={{ required: "Modem is required." }}
                                />
                              </Col>
                            )}

                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.anydesk_id,
                            permissions?.permissions[pcModemModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Anydesk ID">
                                  <span className="label-text">Anydesk ID</span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("anydesId", {
                                    required: true,
                                    maxLength: 20,
                                    minLength: 4,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.anydesId?.type === "required" &&
                                    "Anydesk ID is required."}
                                  {errors.anydesId?.type === "maxLength" &&
                                    "It accepts maximum 20 characters."}
                                  {errors.anydesId?.type === "minLength" &&
                                    "Enter minimum 4 characters."}
                                  {errors.anydesId && errors.anydesId.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.password_full_access,
                            permissions?.permissions[pcModemModuleID]
                          ) && (
                            <Col className="" md="4">
                              <PasswordInput
                                label="Anydesk password for full access"
                                name="anydeskPassFull"
                                register={register}
                                required={false}
                                error={errors.anydeskPassFull}
                                value={getValues("anydeskPassFull")}
                                isDisabled={false}
                              />
                              {/* <Form.Group>
                                <label>Anydesk Password For Full Access</label>{" "}
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("anydeskPassFull", {
                                    maxLength: 50,
                                    pattern: {
                                      value:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                                    },
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.anydeskPassFull?.type ===
                                    "maxLength" &&
                                    "It accepts maximum 20 characters."}
                                  {errors.anydeskPassFull?.type === "pattern" &&
                                    "The password must meet the following criteria: It should be a minimum of 8 characters in length and include at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."}
                                  {errors.anydeskPassFull &&
                                    errors.anydeskPassFull.message}
                                </span>
                              </Form.Group> */}
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.password_for_limited_access,
                            permissions?.permissions[pcModemModuleID]
                          ) && (
                            <Col className="" md="4">
                              <PasswordInput
                                label="Anydesk password for limited access"
                                name="anydeskPassLim"
                                register={register}
                                required={false}
                                error={errors.anydeskPassLim}
                                value={getValues("anydeskPassLim")}
                                isDisabled={false}
                                pattern={
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
                                }
                              />
                              {/* <Form.Group>
                                <label>
                                  Anydesk Password For Limited Access
                                </label>{" "}
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("anydeskPassLim", {
                                    maxLength: 50,
                                    pattern: {
                                      value:
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                                    },
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.anydeskPassLim?.type ===
                                    "maxLength" &&
                                    "It accepts maximum 20 characters."}
                                  {errors.anydeskPassLim?.type === "pattern" &&
                                    "The password must meet the following criteria: It should be a minimum of 8 characters in length and include at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."}
                                  {errors.anydeskPassLim &&
                                    errors.anydeskPassLim.message}
                                </span>
                              </Form.Group> */}
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.seller_shop_name,
                            permissions?.permissions[pcModemModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Seller shop name">
                                  <span className="label-text">
                                    Seller shop name
                                  </span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("sellerName", {
                                    required: true,
                                    maxLength: 50,
                                    minLength: 3,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.sellerName?.type === "required" &&
                                    "Seller name is required."}
                                  {errors.sellerName?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.sellerName?.type === "minLength" &&
                                    "Enter minimum 3 characters."}
                                  {errors.sellerName &&
                                    errors.sellerName.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.windows_license_key,
                            permissions?.permissions[pcModemModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Windows license key">
                                  <span className="label-text">
                                    Windows license key
                                  </span>
                                </label>{" "}
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("windowsLicKey", {
                                    maxLength: 50,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.windowsLicKey?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}

                                  {errors.windowsLicKey &&
                                    errors.windowsLicKey.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.config_status,
                            permissions?.permissions[pcModemModuleID]
                          ) &&
                            configOption && (
                              <Col className="" md="4">
                                <CustomDrodown
                                  label="Config status"
                                  selectedOption={selectedConfigStatus}
                                  options={configOption}
                                  handleSelect={(e) =>
                                    setSelectedConfigStatus(e)
                                  }
                                  control={control}
                                  errors={errors}
                                  name="configStatus"
                                  rules={{
                                    required: "Config status is required.",
                                  }}
                                />
                              </Col>
                            )}
                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.is_software_prepared_for_sp1,
                            permissions?.permissions[pcModemModuleID]
                          ) &&
                            booleanOptions && (
                              <Col className="" md="4">
                                <CustomDrodown
                                  label="Software1 prepared?"
                                  selectedOption={selectedSoft1Prep}
                                  options={booleanOptions}
                                  handleSelect={(e) => setSelectedSoft1Prep(e)}
                                  control={control}
                                  errors={errors}
                                  name="softwareMainSP"
                                  rules={{
                                    required: "Software1 prepared is required.",
                                  }}
                                />
                              </Col>
                            )}
                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.is_software_prepared_for_sp2,
                            permissions?.permissions[pcModemModuleID]
                          ) &&
                            booleanOptions && (
                              <Col className="" md="4">
                                <CustomDrodown
                                  label="Software2 prepared?"
                                  selectedOption={selectedSoft2Prep}
                                  options={booleanOptions}
                                  handleSelect={(e) => setSelectedSoft2Prep(e)}
                                  control={control}
                                  errors={errors}
                                  name="softwareSecondSP"
                                  rules={{
                                    required: "Software2 prepared is required.",
                                  }}
                                />
                              </Col>
                            )}
                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.is_software_prepared_for_sp3,
                            permissions?.permissions[pcModemModuleID]
                          ) &&
                            booleanOptions && (
                              <Col className="" md="4">
                                <CustomDrodown
                                  label="Software3 prepared?"
                                  selectedOption={selectedSoft3Prep}
                                  options={booleanOptions}
                                  handleSelect={(e) => setSelectedSoft3Prep(e)}
                                  control={control}
                                  errors={errors}
                                  name="softwareThirdSP"
                                  rules={{
                                    required: "Software3 prepared is required.",
                                  }}
                                />
                              </Col>
                            )}
                          {isFieldAddableOrEditable(
                            fieldIds.pcModem.notes,
                            permissions?.permissions[pcModemModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Notes">Notes</label>{" "}
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  as="textarea"
                                  row={5}
                                  {...register("notes", {})}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.notes && errors.notes.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}

                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddPCModemForm;
