import React from "react";
import { Button } from "react-bootstrap";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const ShowPasswordButton = ({
  showPassword,
  setShowPassword,
  disabled,
  elementType,
}) => {
  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseUp = () => {
    setShowPassword(false);
  };
  return elementType === "anchor" ? (
    <a
      onMouseDown={handleClick}
      onTouchStart={handleClick}
      onTouchEnd={handleMouseUp}
      onMouseUp={handleMouseUp}
      className="ml-2 showPasswordButton"
      disabled={disabled}
    >
      {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
    </a>
  ) : (
    <Button
      onMouseDown={handleClick}
      onTouchStart={handleClick}
      onTouchEnd={handleMouseUp}
      onMouseUp={handleMouseUp}
      className="ml-2 showPasswordButton"
      disabled={disabled}
    >
      {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
    </Button>
  );
};

export default ShowPasswordButton;
