import React from "react";
import { Form, Select } from "antd";
import { Controller } from "react-hook-form";

const { Option } = Select;

const DynamicPortsDropdown = ({
  label,
  selectedOption,
  options,
  handleSelect,
  control,
  errors,
  name,
  rules,
  defaultValue,
  packageId, // The id to match usedBy property
}) => {
  const preselectedValues = options
    .filter((option) => option.usedBy === packageId)
    .map((option) => option.value);

  return (
    <div>
      <Form.Item required={!!rules}>
        <label className="custom-label" title={label}>
          <span className="label-text">{label}</span>
          {rules && <span className="error-message"> *</span>}
        </label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              id={`${name}-dropdown`}
              className="multiple-dropdown-selector"
              mode="multiple"
              allowClear
              defaultValue={defaultValue}
            
              style={{
                width: "100%",
              }}
              placeholder={`Select ${label}`}
              value={selectedOption ? selectedOption : preselectedValues}
              onChange={(value) => {
                const selectedOptions = options.filter((option) =>
                  value.includes(option.value)
                );
                handleSelect(selectedOptions);
                field.onChange(value);
              }}
              auto
            >
              {options.map((option) => (
                <Option
                  key={option.value}
                  value={option.value}
                  // disabled={
                  //   option.disabled == true || (option.usedBy !="" && option.usedBy != packageId)
                  // }
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          )}
          rules={rules}
        />
        {errors[name] && (
          <span className="error-message">{errors[name].message}</span>
        )}
      </Form.Item>
    </div>
  );
};

export default DynamicPortsDropdown;
