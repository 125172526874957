import React from "react";
import { Button } from "react-bootstrap";

const DeleteButton = ({ onChangeHandler, record }) => {
  return (
    <div key={`delete-${record?.index}`}>
      <Button
        key={`delete-${record?.index}`}
        variant="primary"
        block="true"
        className="mt-3 mb-4 btn btn-three delete"
        onClick={onChangeHandler}
      >
        <i className="fa fa-trash"></i>
      </Button>
    </div>
  );
};

export default DeleteButton;
