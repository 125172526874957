import { makeRequest } from "helpers/apiHelper";

export const getModemList = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/modem/modem/?${queryParamsString}`;
  return makeRequest("get", url);
};

export const getServicePartner = async () => {
  const url = `/modem/modem/get-all-servicepartner/`;
  return makeRequest("get", url);
};

export const createModemList = async (data) => {
  return makeRequest("post", "/modem/modem/", data);
};

export const editModemList = async ({ id, data }) => {
  return makeRequest("patch", `/modem/modem/${id}/`, data);
};

export const deleteModemList = ({ id }) => {
  return makeRequest("delete", `/modem/modem/${id}/`);
};

