// ModalComponent.jsx
import React, { useState } from "react";
import { Modal } from "antd";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import CancelButton from "components/CancelButton/CancelButton";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import CustomMultipleDropdown from "components/CustomDropdown/MultipleDropdown";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import { fieldIds } from "constants/moduleFields";
import usePermissions from "customHooks/usePermissions";
import {
  getNewSimPackages,
  getUsedSimPackages,
  retrieveSimAddData,
  addSim,
} from "API/modemDashApi";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { cacheDuration } from "helpers/apiHelper";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import showToast from "components/Toast/Toast";
import LoadingOverlay from "components/Overlay";


const AddSimModalForm = ({ visible, onOk, onCancel, modemID, modemLogID }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedPorts, setSelectedPorts] = useState();
  const [loadings, setLoading] = useState(false);

  const [selectedExtraOption, setSelectedExtraOption] = useState({
    label: "Select All",
    value: "select",
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingNewSimPack,
    isError: isErrorNewSimPack,
    error: errorNewSimPack,
    data: newSimPackData,
    refetch: refetchNewSimPack,
  } = modemID
    ? useQuery(["newSimPack", modemID], getNewSimPackages, {
        cacheTime: cacheDuration,
      })
    : {
        isLoading: false,
        isError: false,
        error: null,
        data: null,
        refetch: () => {},
      };

  const {
    isLoading: isLoadingUsedSimPack,
    isError: isErrorUsedSimPack,
    error: errorUsedSimPack,
    data: usedSimPackData,
    refetch: refetchUsedSimPack,
  } = modemID
    ? useQuery(["usedSimPack", modemID], getUsedSimPackages, {
        cacheTime: cacheDuration,
      })
    : {
        isLoading: false,
        isError: false,
        error: null,
        data: null,
        refetch: () => {},
      };

  const {
    isLoading: isLoadingRetrievedLogData,
    isError: isErrorRetrievedLogData,
    error: errorRetrievedLogData,
    data: retrievedLogData,
    refetch: refetchRetrievedLogData,
  } = modemLogID
    ? useQuery(["retrievedLogData", modemLogID], retrieveSimAddData, {
        cacheTime: cacheDuration,
      })
    : {
        isLoading: false,
        isError: false,
        error: null,
        data: null,
        refetch: () => {},
      };

  const newSimChoices = newSimPackData?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.name,
      amount: innerArray.amount,
      recommend: innerArray.recommend,
    };
  });

  const usedSimChoices = usedSimPackData?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.name,
      usage_status: "used",
    };
  });
  const modemDashModuleID = parseInt(
    process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID,
    10
  );

  //   const handleOk = () => {
  //     setModalText("The modal will be closed after two seconds");
  //     setConfirmLoading(true);
  //     setTimeout(() => {
  //       onOk();
  //       setConfirmLoading(false);
  //     }, 2000);
  //   };

  //   const handleCancel = () => {
  //     console.log("Clicked cancel button");
  //     onCancel();
  //   };

  const port = retrievedLogData?.empty_ports?.map((portNumber) => ({
    label: portNumber,
    value: portNumber,
  }));
  port?.sort((a, b) => parseInt(a.label) - parseInt(b.label));

  const simAddEditor = useMutation(addSim, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      await showToast({
        icon: "success",
        title: "SIM added successfully!",
        position: "top-end",
        timer: 1500,
      });
      onOk();
      reset();
      setSelectedPackage();
      setSelectedPorts();
    },
  });

  const onSubmit = async (data) => {
    setApiError(false);
    const formData = new FormData();
    var port_string = selectedPorts ? selectedPorts?.join(",") : null;

    formData.append("ports", port_string ? port_string : "");

    formData.append("package_id", selectedPackage ? selectedPackage.id : "");
    formData.append(
      "is_sub_package",
      selectedPackage?.usage_status == "used" ? 1 : 0
    );

    try {
      const response = await simAddEditor.mutateAsync({
        id: modemLogID,
        data: formData,
      });
    } catch (error) {
      if (error.response?.status == 400) {
      setLoading(false);

        setApiError(error.response?.data?.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };

  const handleMultiplePorts = (e) => {
    const selectAll = e.find((option) => option.value == "select");
    const unselectAll = e.find((option) => option.value == "unselect");

    if (selectAll) {
      const arrayOfValues = port.map((obj) => obj.value);
      setSelectedPorts(arrayOfValues);
      setSelectedExtraOption({ label: "Unsselect All", value: "unselect" });
    } else if (unselectAll) {
      setSelectedPorts("");
      setSelectedExtraOption({ label: "Select All", value: "select" });
    } else {
      const arrayOfValues = e.map((obj) => obj.value);
      setSelectedPorts(arrayOfValues);
    }
  };

  const onCancelHandler = () => {
    reset();
    setSelectedPackage();
    setSelectedPorts();
    onCancel();
  };

  return (
    <Modal
      title="Add SIM"
      visible={visible}
      confirmLoading={confirmLoading}
      footer={[]}
      onCancel={onCancelHandler}
    >
       <LoadingOverlay isLoading={loadings} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="user-fields-wrapper modal-form">
            <Row className="">
              {isFieldAddableOrEditable(
                fieldIds.modemDashboard.packages,
                permissions?.permissions[modemDashModuleID]
              ) &&
                usedSimChoices && (
                  <Col className="" md="6">
                    <CustomDrodown
                      label={`Package`}
                      selectedOption={selectedPackage}
                      options={usedSimChoices}
                      handleSelect={(e) => setSelectedPackage(e)}
                      control={control}
                      errors={errors}
                      name={`package`}
                      rules={{
                        required: "Package is required.",
                      }}
                    />
                  </Col>
                )}
              {isFieldAddableOrEditable(
                fieldIds.modemDashboard.ports,
                permissions?.permissions[modemDashModuleID]
              ) && (
                <Col className="" md="6">
                  <CustomMultipleDropdown
                    label="Ports"
                    defaultValue={selectedPorts}
                    selectedOption={selectedPorts}
                    options={port ? [selectedExtraOption, ...port] : []}
                    handleSelect={handleMultiplePorts}
                    control={control}
                    errors={errors}
                    name="defective_port"
                    showSelectAllOption={true}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        {(apiError || displayServerMessage) &&(<Col md="12">
          {apiError && <FormAlertMessage message={apiError} type="error" />}
          {displayServerMessage && (
            <FormAlertMessage message={serverErrorMessage} type="error" />
          )}
        </Col>)}

        <div className="mt-3">
          <Button
            className="btn-fill pull-right  add-user-button"
            type="submit"
            variant="info"
          >
            Save
          </Button>
          <CancelButton onClickHandler={onCancelHandler} />
        </div>

        <div className="clearfix"></div>
      </Form>
    </Modal>
  );
};

export default AddSimModalForm;
