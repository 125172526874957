import React from 'react'
import { Button } from "react-bootstrap";
import { Tooltip } from 'antd';

const ModemLogButton = ({onClickHandler, record}) => {
    return (
        <div key={`modem-log-${record?.index}`}>
          <Tooltip title={"Modem Log"}>
            <Button
              key={`modem-log-${record?.id}`}
              variant="primary"
              block="true"
              className="mt-3 mb-4 btn btn-three edit reset"
              onClick={onClickHandler}
            >
              {/* <i className="fa-solid fa-unlock-keyhole"></i> */}
              {/* <i className="fa-solid fa-file-import"></i> */}
                {"Logs"}
            </Button>
          </Tooltip>
        </div>
      );
}

export default ModemLogButton