import React from "react";
import SupplierView from "views/Supplier/SupplierView";

const Supplier = () => {
  return (
    <div>
      <SupplierView />
    </div>
  );
};

export default Supplier;
