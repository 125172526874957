import React, { useState, useEffect } from "react";

import { Card, Container, Row, Col, Form } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { UserAddOutlined, ExportOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import DeleteButton from "components/Buttons/DeleteButton";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import { getAllCountries } from "API/supplierApi";
import LoadingOverlay from "components/Overlay";

import {
  getSimPacakges,
  getReturnChoices,
  getScanChoices,
  getActivationChoices,
  getCurrencyChoices,
  getSellCurrencyChoices,
  returnSimPackage,
  editSimPackage,
  getStorageChoices,
  getSimPacakgesAll,
  deleteSimPackage,
} from "API/simPackageAPI";
import { useForm } from "react-hook-form";
import { downloadCSV } from "helpers/downloadCSV";
import dayjs from "dayjs";
import ConfirmModal from "components/Modal/Modal";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import GiveBackButton from "components/Buttons/GiveBackButton";
import EditButton from "components/Buttons/EditButton";
import GenericButton from "components/Buttons/GenericButton";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";
import { useLocation } from "react-router-dom";

const SimPackageTable = () => {
  const {
    control,
    formState: { errors },
  } = useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedReturnType, setSelectedReturnType] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [csvHeaders, setCsvHeaders] = useState("");
  const [csvChildHeaders, setCsvChildHeaders] = useState("");
  const [csvData, setCsvData] = useState("");
  const [allSimData, setSimAllData] = useState(false);
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged, setPermissionChanged] = useState(true);
  const [fetchAllData, setFetchAllData] = useState("");
  const location = useLocation();
  const [loadings, setLoading] = useState(false);

  const simPackageModuleID = parseInt(
    process.env.REACT_APP_SIM_PACKAGE_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    return_type_filter: selectedReturnType,
    country_filter: selectedCountry,
    search_filter: searchInput,
    all: fetchAllData,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: simPackages,
    refetch,
  } = useQuery(["simPackage", queryParams], getSimPacakges, {
    cacheTime: cacheDuration,
  });

  const {
    isLoadingAllData,
    isErrorAllData,
    errorAllData,
    data: simPackagesAllData,
    refetch: refetchAllData,
  } = useQuery(["simPackageAll", queryParams], getSimPacakgesAll, {
    cacheTime: cacheDuration,
    enabled: allSimData,
  });

  const {
    isLoading: isLoadingReturnType,
    isError: isErrorReturnType,
    error: errorReturnType,
    data: ReturnTypeChoices,
  } = useQuery("returnTypeChoices", getReturnChoices, {
    cacheTime: cacheDuration,
  });

  const returnTypeData = ReturnTypeChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingActivationType,
    isError: isErrorActivation,
    error: errorActivation,
    data: activationChoices,
  } = useQuery("activationChoices", getActivationChoices, {
    cacheTime: cacheDuration,
  });

  const activationChoicesData = activationChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesTransformed = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingScanType,
    isError: isErrorScanType,
    error: errorScanType,
    data: scanTypeChoices,
  } = useQuery("scanTypeChoices", getScanChoices, {
    cacheTime: cacheDuration,
  });

  const scanTypeData = scanTypeChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingCurrency,
    isError: isErrorCurrency,
    error: errorCurrency,
    data: currencyChoices,
  } = useQuery("currencyChoices", getCurrencyChoices, {
    cacheTime: cacheDuration,
  });

  const currencyChoicesData = currencyChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingSellCurrency,
    isError: isErrorSellCurrency,
    error: errorSellCurrency,
    data: currencySellChoices,
  } = useQuery("currencySellChoices", getSellCurrencyChoices, {
    cacheTime: cacheDuration,
  });

  const currencySellChoicesData = currencySellChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingStorageChoice,
    isError: isErrorStorageChoice,
    error: errorStorageChoice,
    data: storageChoices,
  } = useQuery("storageChoices", getStorageChoices, {
    cacheTime: cacheDuration,
  });

  const allData = { id: "", name: "All" };

  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };
  const handleReturnTypeSelect = (value) => {
    setSelectedReturnType(value);
    refetch();
  };
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    refetch();
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  const simPackageEditor = useMutation(editSimPackage, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      setLoading(false);
      refetch();
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);
      await refetch();
      await permissionRefetch();
      setPermissionChanged(true);
    },
  });
  const giveBackSim = useMutation(returnSimPackage, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: () => {
      setLoading(false);
      refetch();
    },
  });
  const removeModem = useMutation(deleteSimPackage, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      
      refetch();
      setLoading(false);
    },
  });
  const handleDelete = async (sim) => {
    const deleteApi = async () => {
      try {
        const response = await removeModem.mutateAsync({ id: sim.id });
        return response;
      } catch (error) {
      setLoading(false);

        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: deleteApi,
      deleteErrorText: "Sim Package is in use!",
      deleteSuccessMessage: "Sim Package deleted successfully",
    });
  };
  const handleEdit = (user) => {
    navigate("/edit-sim-package", { state: { data: user } });
  };
  const handleGiveBack = async (simPackage) => {
    const handleApi = async () => {
      try {
        const response = await giveBackSim.mutateAsync({ id: simPackage.id });
        return response;
      } catch (error) {
        setLoading(false);
        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: handleApi,
      deleteErrorText: "Can't give back SIM package!",
      deleteSuccessMessage: "SIM package returned successfully",
      confirmButtonText: "Yes, give back",
      failedTitle: "Can't give back",
      successTitle: "Returned",
    });
  };

  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen && selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: simPackageModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
    setLoading(false);

  };

  const handleToggle = async (id, status) => {
    const response = await simPackageEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);
  };

  const handleEditApiCall = async (value, fieldName, id) => {
    const data = { [fieldName]: value };
    const response = await simPackageEditor.mutateAsync({
      id: id,
      data: data,
    });
    setLoading(false);
  };

  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[simPackageModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }

    if (record?.return_type == "Give Back") {
      buttons.push(
        <GiveBackButton
          key={`give-back-${record?.id}`}
          onChangeHandler={() => handleGiveBack(record)}
          record={record}
        />
      );
    }
    if (permissions.permissions[simPackageModuleID].edit_access) {
      buttons.push(
        <DeleteButton
          key={`delete-${record?.id}`}
          onChangeHandler={() => handleDelete(record)}
          record={record}
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    // {
    //   perm_id: 0,
    //   title: "Index",
    //   dataIndex: "index",
    //   sorter: (a, b) => a.index - b.index,
    //   width: 10,
    // },
    {
      perm_id: fieldIds.simPackage.date_of_delevery,
      title: "Package receive date",
      dataIndex: "date_of_delevery",
      width: 200,

      render: (date_of_delevery) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {date_of_delevery
            ? dayjs(date_of_delevery).format("D MMM YYYY")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateOfDelA = dayjs(a.date_of_delevery);
        const dateOfDelB = dayjs(b.date_of_delevery);
        return dateOfDelA - dateOfDelB;
      },
    },
    {
      perm_id: fieldIds.simPackage.name,
      title: "Package name",
      dataIndex: "name",
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      perm_id: fieldIds.simPackage.provider_id,
      title: "Provider",
      dataIndex: "provider",
      sorter: (a, b) =>
        String(a.provider ?? "").localeCompare(String(b.provider ?? "")),
    },
    {
      perm_id: fieldIds.simPackage.country,
      title: "Country",
      dataIndex: "country",
      sorter: (a, b) =>
        String(a.country ?? "").localeCompare(String(b.country ?? "")),
    },
    {
      perm_id: fieldIds.simPackage.supplier_id,
      title: "Supplier",
      dataIndex: "supplier",
      sorter: (a, b) =>
        String(a.supplier ?? "").localeCompare(String(b.supplier ?? "")),
    },
    {
      perm_id: fieldIds.simPackage.amount_of_sim,
      title: (
        <div>
          <div>Total amount (No. of SIM cards)</div>
          <div>(by Supplier)</div>
        </div>
      ),
      dataIndex: "amount_of_sim",
      render: (amount_of_sim) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {amount_of_sim ? amount_of_sim : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.amount_of_sim) - Number(b.amount_of_sim),
    },
    {
      perm_id: fieldIds.simPackage.usage_status,
      title: "Usage status",
      dataIndex: "usage_status",
      render: (usage_status) => (usage_status ? usage_status : "-"),
      sorter: (a, b) =>
        String(a.usage_status ?? "").localeCompare(
          String(b.usage_status ?? "")
        ),
    },
    {
      perm_id: fieldIds.simPackage.more_card,
      title: "More cards",
      dataIndex: "more_card",
      render: (more_card) => (more_card ? more_card : "-"),
      sorter: (a, b) => Number(a.more_card) - Number(b.more_card),
    },
    {
      perm_id: fieldIds.simPackage.missing_cards,
      title: "Missing cards",
      dataIndex: "missing_cards",
      render: (missing_cards) => (
        <div className="center-aligned-text">
          {missing_cards ? missing_cards : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.missing_cards) - Number(b.missing_cards),
    },
    {
      perm_id: fieldIds.simPackage.total_sim_cards,
      title: "Total amount (No. of SIM cards)",
      dataIndex: "amount_of_sim",
      render: (amount_of_sim, extra) => {
        return (
          <div
            className="center-aligned-text"
            style={{
              maxWidth: "150px",
              width: "150px",
              textOverflow: "ellipsis",
            }}
          >
            {extra.more_card !== undefined &&
              extra.missing_cards !== undefined &&
              extra.amount_of_card_given_back !== undefined &&
              extra.thrown_away_card !== undefined
              ? amount_of_sim +
              extra.more_card -
              extra.missing_cards -
              extra.amount_of_card_given_back -
              extra.thrown_away_card
              : amount_of_sim}
          </div>
        );
      },
      sorter: (a, b) => {
        const calculateTotalAmount = (item) =>
          item.amount_of_sim +
          (item.extra?.more_card || 0) -
          (item.extra?.missing_cards || 0);

        const totalAmountA = calculateTotalAmount(a);
        const totalAmountB = calculateTotalAmount(b);

        // Return a negative number if a should be sorted before b,
        // a positive number if a should be sorted after b,
        // or 0 if a and b are equal.
        return totalAmountA - totalAmountB;
      },
    },
    {
      perm_id: fieldIds.simPackage.return_type,
      title: "Return type",
      dataIndex: "return_type",
      render: (return_type) => (return_type ? return_type : "-"),
      sorter: (a, b) =>
        String(a.return_type ?? "").localeCompare(String(b.return_type ?? "")),
    },
    {
      perm_id: fieldIds.simPackage.last_give_back_date,
      title: "Last give back date",
      dataIndex: "last_give_back_date",

      render: (last_give_back_date) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
          }}
        >
          {last_give_back_date
            ? dayjs(last_give_back_date).format("D MMM YYYY")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const lastGiveDateA = dayjs(a.last_give_back_date);
        const lastGiveDateB = dayjs(b.last_give_back_date);
        return lastGiveDateA - lastGiveDateB;
      },
    },
    {
      perm_id: fieldIds.simPackage.priority,
      title: "Priority",
      dataIndex: "priority",
      render: (priority) => (
        <div className="center-aligned-text">{priority ? priority : "-"}</div>
      ),
      sorter: (a, b) => Number(a.priority) - Number(b.priority),
    },
    {
      perm_id: fieldIds.simPackage.is_unpacking_needed,
      title: "Unpacking needed?",
      dataIndex: "is_unpacking_needed",
      render: (is_unpacking_needed) =>
        is_unpacking_needed != undefined
          ? is_unpacking_needed
            ? "Yes"
            : "No"
          : "-",
      sorter: (a, b) =>
        String(a.is_unpacking_needed ?? "").localeCompare(
          String(b.is_unpacking_needed ?? "")
        ),
    },
    {
      perm_id: fieldIds.simPackage.scan,
      title: "Scan",
      dataIndex: "scan",
      render: (scan) => (scan ? scan : "-"),
      sorter: (a, b) =>
        String(a.scan ?? "").localeCompare(String(b.scan ?? "")),
    },
    {
      perm_id: fieldIds.simPackage.scan_phonenumber,
      title: "Scan phone number",
      dataIndex: "scan_phonenumber",

      render: (scan_phonenumber) => (
        <div
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
          }}
        >
          {scan_phonenumber ? scan_phonenumber : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.scan_phonenumber ?? "").localeCompare(
          String(b.scan_phonenumber ?? "")
        ),
    },
    {
      perm_id: fieldIds.simPackage.activation_status,
      title: "Activation status",
      dataIndex: "activation_status",
      render: (activation_status) =>
        activation_status ? activation_status : "-",
      sorter: (a, b) =>
        String(a.activation_status ?? "").localeCompare(
          String(b.activation_status ?? "")
        ),
    },
    {
      perm_id: fieldIds.simPackage.expire_date_to_activate_sim,
      title: "Expiration date for activation",
      dataIndex: "expire_date_to_activate_sim",
      render: (expire_date_to_activate_sim) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {expire_date_to_activate_sim
            ? dayjs(expire_date_to_activate_sim).format("D MMM YYYY")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const expireA = dayjs(a.expire_date_to_activate_sim);
        const expireB = dayjs(b.expire_date_to_activate_sim);
        return expireA - expireB;
      },
    },
    {
      perm_id: fieldIds.simPackage.expire_date_after_activation,
      title: "Expiration date after activation",
      dataIndex: "expire_date_after_activation",
      // sortDirections: ['ascend', 'descend'], // Allow sorting in both directions
      render: (expire_date_after_activation) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {expire_date_after_activation
            ? dayjs(expire_date_after_activation).format("D MMM YYYY")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const expireA = dayjs(a.expire_date_after_activation);
        const expireB = dayjs(b.expire_date_after_activation);

        return expireA - expireB;
      },
      defaultSortOrder: fetchAllData == 1 ? "ascend" : null,
    },
    {
      perm_id: fieldIds.simPackage.storage_before_use_id,
      title: "Storage before usage",
      dataIndex: "storage_before_use",
      render: (storage_before_use, more) => (
        <div
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
          }}
        >
          {storage_before_use
            ? `${storage_before_use} ${more.description_before_use &&
              permissions?.permissions[simPackageModuleID].fields[
                fieldIds.simPackage.description_before_use
              ].column_show
              ? `(${more.description_before_use})`
              : ""
            }`
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.storage_before_use ?? "").localeCompare(
          String(b.storage_before_use ?? "")
        ),
    },

    {
      perm_id: fieldIds.simPackage.storage_after_use_id,
      title: "Storage after usage",
      dataIndex: "storage_after_use",
      render: (storage_after_use, more) => (
        <div
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
          }}
        >
          {storage_after_use
            ? `${storage_after_use} ${more.description_after_use &&
              permissions?.permissions[simPackageModuleID].fields[
                fieldIds.simPackage.description_after_use
              ].column_show
              ? `(${more.description_after_use})`
              : ""
            }`
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.storage_after_use ?? "").localeCompare(
          String(b.storage_after_use ?? "")
        ),
    },
    // {
    //   perm_id: fieldIds.simPackage.description_after_use,
    //   title: "Note for storage after usage",
    //   dataIndex: "description_after_use",
    //   render: (description_after_use) => (
    //     <div
    //       style={{
    //         maxWidth: "300px",
    //         minWidth: "300px",
    //         width: "300px",
    //         whiteSpace: "pre-wrap",
    //       }}
    //     >
    //       {description_after_use ? description_after_use : "-"}
    //     </div>
    //   ),
    //   sorter: (a, b) =>
    //     String(a.description_after_use ?? "").localeCompare(
    //       String(b.description_after_use ?? "")
    //     ),
    // },
    {
      perm_id: fieldIds.simPackage.storage_for_defective_id,
      title: "Storage for defective SIM cards",
      dataIndex: "storage_for_defective",
      render: (storage_for_defective, more) => (
        <div
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
          }}
        >
          {storage_for_defective
            ? `${storage_for_defective} ${more.description_for_defactive &&
              permissions?.permissions[simPackageModuleID].fields[
                fieldIds.simPackage.description_for_defactive
              ].column_show
              ? `(${more.description_for_defactive})`
              : ""
            }`
            : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.storage_for_defective ?? "").localeCompare(
          String(b.storage_for_defective ?? "")
        ),
    },
    // {
    //   perm_id: fieldIds.simPackage.description_for_defactive,
    //   title: "Note for storage for defective",
    //   dataIndex: "description_for_defactive",
    //   render: (description_for_defactive) => (
    //     <div
    //       style={{
    //         maxWidth: "300px",
    //         minWidth: "300px",
    //         width: "300px",
    //         whiteSpace: "pre-wrap",
    //       }}
    //     >
    //       {description_for_defactive ? description_for_defactive : "-"}
    //     </div>
    //   ),
    //   sorter: (a, b) =>
    //     String(a.description_for_defactive ?? "").localeCompare(
    //       String(b.description_for_defactive ?? "")
    //     ),
    // },
    {
      perm_id: fieldIds.simPackage.defective,
      title: "Defective",
      dataIndex: "defective",
      render: (defective) => (
        <div className="center-aligned-text">{defective ? defective : "-"}</div>
      ),
    },
    {
      perm_id: fieldIds.simPackage.how_many_unused,
      title: "How many unused?",
      dataIndex: "how_many_unused",
      render: (how_many_unused) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
          }}
        >
          {how_many_unused ? how_many_unused : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.how_many_unused) - Number(b.how_many_unused),
    },
    {
      perm_id: fieldIds.simPackage.finished_to_send_back,
      title: "Finish to send back",
      dataIndex: "finished_to_send_back",

      render: (finished_to_send_back) => (
        <div
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
          }}
        >
          {finished_to_send_back ? (finished_to_send_back ? "Yes" : "No") : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.finished_to_send_back ?? "").localeCompare(
          String(b.finished_to_send_back ?? "")
        ),
    },
    {
      perm_id: fieldIds.simPackage.amount_of_card_given_back,
      title: "No. of amount given back",
      dataIndex: "amount_of_card_given_back",

      render: (amount_of_card_given_back) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {amount_of_card_given_back ? amount_of_card_given_back : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.amount_of_card_given_back) -
        Number(b.amount_of_card_given_back),
    },
    {
      perm_id: fieldIds.simPackage.delivery_date_cz_de,
      title: "Delievery date CZ-DE",
      dataIndex: "delivery_date_cz_de",

      render: (delivery_date_cz_de) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {delivery_date_cz_de
            ? dayjs(delivery_date_cz_de).format("D MMM YYYY")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.delivery_date_cz_de);
        const dateB = dayjs(b.delivery_date_cz_de);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.simPackage.delivery_date_to_supplier,
      title: "Delievery date to supplier",
      dataIndex: "delivery_date_to_supplier",

      render: (delivery_date_to_supplier) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {delivery_date_to_supplier
            ? dayjs(delivery_date_to_supplier).format("D MMM YYYY")
            : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.delivery_date_to_supplier);
        const dateB = dayjs(b.delivery_date_to_supplier);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.simPackage.thrown_away_card,
      title: "Thrown away cards",
      dataIndex: "thrown_away_card",
      render: (thrown_away_card) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
          }}
        >
          {thrown_away_card ? thrown_away_card : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.thrown_away_card) - Number(b.thrown_away_card),
    },
    {
      perm_id: fieldIds.simPackage.currency,
      title: "Currency",
      dataIndex: "currency",
      sorter: (a, b) =>
        String(a.currency ?? "").localeCompare(String(b.currency ?? "")),
    },
    {
      perm_id: fieldIds.simPackage.price_per_sim,
      title: "Price per SIM in original",
      dataIndex: "price_per_sim",
      render: (price_per_sim, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {price_per_sim != null
            ? (more.currency == "USD"
              ? "$"
              : more.currency == "EUR"
                ? "€"
                : more.currency == "CZK"
                  ? "Kč"
                  : more.currency == "RUB"
                    ? "₽"
                    : "") +((price_per_sim % 1 !== 0) ? price_per_sim.toFixed(4):price_per_sim)
            : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.price_per_sim) - Number(b.price_per_sim),
    },
    {
      perm_id: fieldIds.simPackage.price_per_sim,
      title: "Price per SIM in USD",
      dataIndex: "price_per_sim_in_USD",
      render: (price_per_sim_in_USD, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"
          }}
        >
          {price_per_sim_in_USD != null ? `$${(price_per_sim_in_USD % 1 !== 0) ? price_per_sim_in_USD.toFixed(4):price_per_sim_in_USD}` : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.price_per_sim_in_USD) - Number(b.price_per_sim_in_USD),
    },
    {
      perm_id: fieldIds.simPackage.price_per_sim,
      title: "Price per SIM in EUR",
      dataIndex: "price_per_sim_in_EUR",
      render: (price_per_sim_in_EUR, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_eur_delevery_date == '1' ? '#edf2ff' : more.source_to_eur_delevery_date == '3' ? "#fff0ed" : more.source_to_eur_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_eur_delevery_date == '1' ? '#4183c4' : more.source_to_eur_delevery_date == '3' ? "brown" : more.source_to_eur_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {price_per_sim_in_EUR != null ? `€${(price_per_sim_in_EUR % 1 !== 0) ? price_per_sim_in_EUR.toFixed(4):price_per_sim_in_EUR}` : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.price_per_sim_in_EUR) - Number(b.price_per_sim_in_EUR),
    },
    {
      perm_id: fieldIds.simPackage.cancellation,
      title: "Cancellation in original",
      dataIndex: "cancellation",
      render: (cancellation, more) => (
        <div className="right-aligned-text" style={{
          color: "balck",
          textAlign: "center",
          padding: "5px 10px",
        
          borderRadius: "5px",
          width: "auto !important",
        }}>
          {cancellation != null
            ? (more.currency == "USD"
              ? "$"
              : more.currency == "EUR"
                ? "€"
                : more.currency == "CZK"
                  ? "Kč"
                  : more.currency == "RUB"
                    ? "₽"
                    : "") +((cancellation % 1 !== 0) ? cancellation.toFixed(4):cancellation)
            : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.cancellation) - Number(b.cancellation),
    },
    {
      perm_id: fieldIds.simPackage.cancellation,
      title: "Cancellation in USD",
      dataIndex: "cancellation_in_USD",
      render: (cancellation_in_USD, more) => (
        <div className="right-aligned-text"

          style={{
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"
            , textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",

          }}>
          {cancellation_in_USD != null ? `$${(cancellation_in_USD % 1 !== 0) ? cancellation_in_USD.toFixed(4):cancellation_in_USD}` : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.cancellation_in_USD) - Number(b.cancellation_in_USD),
    },
    {
      perm_id: fieldIds.simPackage.cancellation,
      title: "Cancellation in EUR",
      dataIndex: "cancellation_in_EUR",
      render: (cancellation_in_EUR, more) => (
        <div className="right-aligned-text" style={{
          textAlign: "center",
          padding: "5px 10px",
          background: more.source_to_eur_delevery_date == '1' ? '#edf2ff' : more.source_to_eur_delevery_date == '3' ? "#fff0ed" : more.source_to_eur_delevery_date == '2' ? "#ffedff" : "",
          display: "inline-block",
          borderRadius: "5px",
          width: "auto !important",
          color: more.source_to_eur_delevery_date == '1' ? '#4183c4' : more.source_to_eur_delevery_date == '3' ? "brown" : more.source_to_eur_delevery_date == '2' ? "#a600a4" : "black"

        }
        }>
          {cancellation_in_EUR != null ? `€${(cancellation_in_EUR % 1 !== 0) ? cancellation_in_EUR.toFixed(4):cancellation_in_EUR}` : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.cancellation_in_EUR) - Number(b.cancellation_in_EUR),
    },
    {
      perm_id: fieldIds.simPackage.price_in_total,
      title: "Price in total in original",
      dataIndex: "price_in_total",
      render: (price_in_total, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",

            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: "black"

          }}
        >
          {price_in_total != null
            ? (more.currency == "USD"
              ? "$"
              : more.currency == "EUR"
                ? "€"
                : more.currency == "CZK"
                  ? "Kč"
                  : more.currency == "RUB"
                    ? "₽"
                    : "") +((price_in_total % 1 !== 0) ? price_in_total.toFixed(4):price_in_total)
            : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.price_in_total) - Number(b.price_in_total),
    },
    {
      perm_id: fieldIds.simPackage.price_in_total,
      title: "Price in total in USD",
      dataIndex: "price_in_total_in_USD",
      render: (price_in_total_in_USD, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"


          }}
        >
          {price_in_total_in_USD != null ? `$${(price_in_total_in_USD % 1 !== 0) ? price_in_total_in_USD.toFixed(4):price_in_total_in_USD}` : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.price_in_total_in_USD) - Number(b.price_in_total_in_USD),
    },
    {
      perm_id: fieldIds.simPackage.price_in_total,
      title: "Price in total in EUR",
      dataIndex: "price_in_total_in_EUR",

      render: (price_in_total_in_EUR, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_eur_delevery_date == '1' ? '#edf2ff' : more.source_to_eur_delevery_date == '3' ? "#fff0ed" : more.source_to_eur_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_eur_delevery_date == '1' ? '#4183c4' : more.source_to_eur_delevery_date == '3' ? "brown" : more.source_to_eur_delevery_date == '2' ? "#a600a4" : "black"


          }}
        >
          {price_in_total_in_EUR != null ? `€${(price_in_total_in_EUR % 1 !== 0) ? price_in_total_in_EUR.toFixed(4):price_in_total_in_EUR}` : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.price_in_total_in_EUR) - Number(b.price_in_total_in_EUR),
    },
    {
      perm_id: fieldIds.simPackage.paid_date,
      title: "Paid date",
      dataIndex: "paid_date",
      render: (paid_date) => (
        <div className="center-aligned-text">
          {paid_date ? dayjs(paid_date).format("D MMM YYYY") : "-"}
        </div>
      ),
      sorter: (a, b) => {
        const dateA = dayjs(a.paid_date);
        const dateB = dayjs(b.paid_date);
        return dateA - dateB;
      },
    },
    {
      perm_id: fieldIds.simPackage.paid_amount,
      title: "Paid in original",
      dataIndex: "paid_amount",
      render: (paid_amount, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {paid_amount != null
            ? (more.currency == "USD"
              ? "$"
              : more.currency == "EUR"
                ? "€"
                : more.currency == "CZK"
                  ? "Kč"
                  : more.currency == "RUB"
                    ? "₽"
                    : "") + ((paid_amount % 1 !== 0) ? paid_amount.toFixed(4):paid_amount)
            : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.paid_amount) - Number(b.paid_amount),
    },
    {
      perm_id: fieldIds.simPackage.paid_amount,
      title: "Paid in USD",
      dataIndex: "paid_amount_in_USD",
      render: (paid_amount_in_USD, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_usd_delevery_date == '1' ? '#edf2ff' : more.source_to_usd_delevery_date == '3' ? "#fff0ed" : more.source_to_usd_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_usd_delevery_date == '1' ? '#4183c4' : more.source_to_usd_delevery_date == '3' ? "brown" : more.source_to_usd_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {paid_amount_in_USD != null ? `$${(paid_amount_in_USD % 1 !== 0) ? paid_amount_in_USD.toFixed(4):paid_amount_in_USD}` : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.paid_amount_in_USD) - Number(b.paid_amount_in_USD),
    },
    {
      perm_id: fieldIds.simPackage.paid_amount,
      title: "Paid in EUR",
      dataIndex: "paid_amount_in_EUR",
      render: (paid_amount_in_EUR, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background: more.source_to_eur_delevery_date == '1' ? '#edf2ff' : more.source_to_eur_delevery_date == '3' ? "#fff0ed" : more.source_to_eur_delevery_date == '2' ? "#ffedff" : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color: more.source_to_eur_delevery_date == '1' ? '#4183c4' : more.source_to_eur_delevery_date == '3' ? "brown" : more.source_to_eur_delevery_date == '2' ? "#a600a4" : "black"

          }}
        >
          {paid_amount_in_EUR != null ? `€${(paid_amount_in_EUR % 1 !== 0) ? paid_amount_in_EUR.toFixed(4):paid_amount_in_EUR}` : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.paid_amount_in_EUR) - Number(b.paid_amount_in_EUR),
    },
    {
      perm_id: fieldIds.simPackage.total_sold_card,
      title: "Total sold card",
      dataIndex: "total_sold_card",
      render: (total_sold_card, more) => {
        const handleClick = (record) => {
          navigate("/soldcard-list", { state: { id: record.id } });
        };

        return (
        
            <div className="center-aligned-text" onClick={() => handleClick(more)} style={{ width: "100%" ,textDecoration:(total_sold_card != null)? "underline":"none", color: "blue",   }} role="button" title="Click to view Sold Card Detail">
       <span style={{ background: (total_sold_card != null) ? '#edf2ff' : ''}}>{total_sold_card || "-"}</span>
          </div>
        );
      },
      sorter: (a, b) => Number(a.sold_card) - Number(b.sold_card),
    },
    {
      perm_id: fieldIds.simPackage.total_sold_price_usd,
      title: "Total sold price in USD",
      dataIndex: "total_sold_price_usd",
      render: (total_sold_price_usd, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background:
              more.source_to_usd_delevery_date == "1"
                ? "#edf2ff"
                : more.source_to_usd_delevery_date == "3"
                ? "#fff0ed"
                : more.source_to_usd_delevery_date == "2"
                ? "#ffedff"
                : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color:
              more.source_to_usd_delevery_date == "1"
                ? "#4183c4"
                : more.source_to_usd_delevery_date == "3"
                ? "brown"
                : more.source_to_usd_delevery_date == "2"
                ? "#a600a4"
                : "black",
          }}
        >
          {total_sold_price_usd != null
            ? `$${
                total_sold_price_usd % 1 !== 0
                  ? total_sold_price_usd.toFixed(4)
                  : total_sold_price_usd
              }`
            : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.total_sold_price_usd) - Number(b.total_sold_price_usd),
    },
    {
      perm_id: fieldIds.simPackage.total_sold_price_eur,
      title: "Total sold price in EUR",
      dataIndex: "total_sold_price_eur",
      render: (total_sold_price_eur, more) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            textAlign: "center",
            padding: "5px 10px",
            background:
              more.source_to_eur_delevery_date == "1"
                ? "#edf2ff"
                : more.source_to_eur_delevery_date == "3"
                ? "#fff0ed"
                : more.source_to_eur_delevery_date == "2"
                ? "#ffedff"
                : "",
            display: "inline-block",
            borderRadius: "5px",
            width: "auto !important",
            color:
              more.source_to_eur_delevery_date == "1"
                ? "#4183c4"
                : more.source_to_eur_delevery_date == "3"
                ? "brown"
                : more.source_to_eur_delevery_date == "2"
                ? "#a600a4"
                : "black",
          }}
        >
          {total_sold_price_eur != null
            ? `€${
                total_sold_price_eur % 1 !== 0
                  ? total_sold_price_eur.toFixed(4)
                  : total_sold_price_eur
              }`
            : "-"}
        </div>
      ),
      sorter: (a, b) => Number(a.total_sold_price_eur) - Number(b.total_sold_price_eur),
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      width: 150,
      maxWidth: 150,
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];

  const childColumns = [
    {
      perm_id: fieldIds.simPackage.date_of_first_use,
      title: "Date of first usage",
      dataIndex: "date_of_first_use",
      width: "150px",
      render: (date_of_first_use) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {date_of_first_use
            ? dayjs(date_of_first_use).format("D MMM YYYY")
            : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.simPackage.date_of_last_use,
      title: "Date of last usage",
      dataIndex: "date_of_last_use",
      width: "150px",
      render: (date_of_last_use) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {date_of_last_use
            ? dayjs(date_of_last_use).format("D MMM YYYY")
            : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.simPackage.sub_name,
      title: "Sub Name",
      dataIndex: "name",
      width: "150px",
    },
    {
      perm_id: fieldIds.simPackage.used_service_partner,
      title: "Used Service Partner",
      dataIndex: "used_service_partner",
      width: "150px",
    },
    {
      perm_id: fieldIds.simPackage.usage_status,
      title: "Usage status",
      dataIndex: "usage_status",
      width: "150px",
    },
    {
      perm_id: fieldIds.simPackage.amount_of_sim,
      title: "Amount",
      dataIndex: "amount_of_sim",
      width: "150px",
    },
    // {
    //   perm_id: fieldIds.simPackage.last_used_date,
    //   title: "Last used date",
    //   dataIndex: "last_used_date",
    // },
    {
      perm_id: fieldIds.simPackage.storage_after_use_id,
      title: "Storage after use",
      dataIndex: "storage_after_use",
      width: "150px",
      render: (storage_after_use, record) => {
        const foundStorage = storageChoices?.storege_in_use.find(
          (val) => val.name == "test2"
        );

        return (
          <div
            className="center-aligned-text"
            style={{
              maxWidth: "300px",
              width: "300px",
              textOverflow: "ellipsis",
            }}
          >
            {/* <CustomDropdown
              label="Storage after use"
              selectedOption={foundStorage}
              options={storageChoices?.storege_not_in_use}
              handleSelect={(newValue) =>
                handleEditApiCall(newValue, "storage_after_use", record.id)
              }
              control={control}
              errors={errors}
              name={`storageAftUse_${record.id}`}
            /> */}
            {storage_after_use
              ? `${storage_after_use} ${more.description_after_use &&
                permissions?.permissions[simPackageModuleID].fields[
                  fieldIds.simPackage.description_after_use
                ].column_show
                ? `(${more.description_after_use})`
                : ""
              }`
              : "-"}
          </div>
        );
      },
    },
    {
      perm_id: fieldIds.simPackage.activation_status,
      title: "Activation status",
      dataIndex: "activation_status",
      width: "150px",
      render: (activation_status) =>
        activation_status ? activation_status : "-",
    },
    {
      perm_id: fieldIds.simPackage.expire_date_to_activate_sim,
      title: "Expiration date for activation",
      dataIndex: "expire_date_to_activate_sim",
      width: "150px",
      render: (expire_date_to_activate_sim) =>
        expire_date_to_activate_sim
          ? dayjs(expire_date_to_activate_sim).format("D MMM YYYY")
          : "-",
    },
    {
      perm_id: fieldIds.simPackage.expire_date_after_activation,
      title: "Expiration date after activation",
      dataIndex: "expire_date_after_activation",

      render: (expire_date_after_activation) =>
        expire_date_after_activation
          ? dayjs(expire_date_after_activation).format("D MMM YYYY")
          : "-",
    },

    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: simPackages?.count,
    onPageChange: handlePageChange,
  };

  const handleExportCSV = (headers, childHeaders, data) => {
    setCsvData(data);
    setCsvHeaders(headers);
    setCsvChildHeaders(childHeaders);
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        handleExportCSV={handleExportCSV}
        data={simPackages?.results}
        columns={columns}
        permissionSet={permissions.permissions[simPackageModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        choiceData={{
          country: allCountriesTransformed,
          return_type: returnTypeData,
          scan: scanTypeData,
          activation_status: activationChoicesData,
          currency: currencyChoicesData,
          selling_currency: currencySellChoicesData,
        }}
        expandableRow={true}
        childColumns={childColumns}
        showColumnState={showColumnState}
      />
    );
  }

  const handleExportCSV1 = async () => {
    setSimAllData(!allSimData);
    refetchAllData();
  };

  useEffect(() => {
    if (location.state?.simExpireFilter) {
      setFetchAllData(1);
    }
  }, [location.state]);
  useEffect(() => {
    // This block will run when the refetch is successful and the data has been updated
    if (allSimData) {
      const replaceIdsWithNames = (data, choiceData) => {
        if (!data) {
          return data;
        }

        if (Array.isArray(data)) {
          return data.map((item) => replaceIdsWithNames(item, choiceData));
        }

        if (typeof data === "object") {
          const updatedData = { ...data };

          Object.keys(updatedData).forEach((key) => {
            const id = updatedData[key];
            const choiceArray = choiceData[key];

            if (choiceArray) {
              const choiceItem = choiceArray.find((choice) => choice.id === id);

              if (choiceItem) {
                updatedData[key] = choiceItem.name;
              }
            }

            // Recursively check child objects
            if (typeof updatedData[key] === "object") {
              updatedData[key] = replaceIdsWithNames(
                updatedData[key],
                choiceData
              );
            } else if (typeof updatedData[key] === "boolean") {
              updatedData[key] = updatedData[key] ? "Yes" : "No";
            }
          });

          return updatedData;
        }

        return data;
      };

      const dataWithNames = () => {
        const choiceData = {
          country: allCountriesTransformed,
          return_type: returnTypeData,
          scan: scanTypeData,
          activation_status: activationChoicesData,
          currency: currencyChoicesData,
          selling_currency: currencySellChoicesData,
        };
        console.log("🚀 ~ dataWithNames ~ simPackagesAllData:", simPackagesAllData)
        const updatedData = replaceIdsWithNames(simPackagesAllData, choiceData);

        return updatedData?.results;
      };

      downloadCSV(
        csvHeaders,
        [
          { title: "Child table", dataIndex: "child_table" },
          ...csvChildHeaders,
        ],
        dataWithNames(),
        "exported_data.csv"
      );

      setSimAllData(!allSimData);
    }
  }, [simPackagesAllData, refetchAllData()]);

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> SIM Package
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2 sim-package-table">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper filter-column"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[simPackageModuleID].fields ||
                        []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={simPackageModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged}
                      setIsPermissionChanged={setPermissionChanged}
                    />

                    {permissions?.permissions[simPackageModuleID].fields[95]
                      ?.view_access && ReturnTypeChoices ? (
                      <DropdownFilter
                        title={"Return Type"}
                        selectedValue={selectedReturnType}
                        items={[allData, ...returnTypeData]}
                        onSelect={handleReturnTypeSelect}
                        filterName="Return Type"
                      />
                    ) : (
                      ""
                    )}

                    {permissions?.permissions[simPackageModuleID].fields[90]
                      ?.view_access && allCountries ? (
                      <DropdownFilter
                        title={"Country"}
                        selectedValue={selectedCountry}
                        items={[allData, ...allCountriesTransformed]}
                        onSelect={handleCountrySelect}
                        filterName="Country"
                        enableSearch={true}
                      />
                    ) : (
                      ""
                    )}

                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | Name | Provider Name | Supplier Name ..."
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center top-table-buttons"
                  >
                    {isAddAllowed(
                      permissions.permissions[simPackageModuleID]
                    ) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-sim-package")}
                      />
                    ) : (
                      ""
                    )}

                    <GenericButton
                      onClickHandler={handleExportCSV1}
                      name="Export"
                      icon={<ExportOutlined />}
                      className="export-btn"
                    />
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SimPackageTable;
