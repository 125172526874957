import React from "react";

import EmailForm from "components/ResetPassword/EmailForm";

const ForgotPasswordView = () => {
  return (
    <div>
      <EmailForm />
    </div>
  );
};

export default ForgotPasswordView;
