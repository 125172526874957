export const downloadCSV = (headers, childHeaders, data, filename) => {
  let blankLine = false;
  const csvContent =
    "data:text/csv;charset=utf-8," +
    headers.map((header) => header.title).join(",") +
    "\n" +
    data
      .map((row) => {
        const rowData = headers.map((header) => {
          const dataIndex = header.dataIndex;
          const cellData = row[dataIndex];

          // Check if data contains commas and the header dataIndex is not 'used_service_partner'
          if (
            cellData &&
            typeof cellData === "string" &&
            cellData.includes(",") &&
            dataIndex !== "used_service_partner"
          ) {
            return `"${cellData}"`; // Wrap in quotes to treat as a single cell
          }
          // Replace '-' or 0.0 with blank value
          const formattedData =
            cellData === "-" || cellData === 0.0 ? "" : cellData;

          return formattedData !== undefined ? formattedData : "";
        });

        if (
          row.child &&
          Object.keys(row.child).length > 0 &&
          typeof row.child === "object"
        ) {
          blankLine = true;
          const childHeadersRow = childHeaders.map((header) => header.title);
          const childDataRow = childHeaders.map((header) =>
            row.child[header.dataIndex] !== undefined
              ? `"${row.child[header.dataIndex]}"`
              : ""
          );

          const childRowData = rowData.concat(childDataRow);

          const childArray = Object.values(row.child);
          const childRows = childArray.map((childRow) =>
            childHeaders.map((header) =>
              childRow[header.dataIndex] !== undefined
                ? `"${childRow[header.dataIndex]}"`
                : ""
            )
          );

          return `${childRowData.join(",")}\n${childHeadersRow.join(
            ","
          )}\n${childRows.map((childRow) => childRow.join(",")).join("\n")}`;
        }

        return rowData.join(",");
      })
      .join(blankLine ? "\n\n" : "\n");


  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename);
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};
