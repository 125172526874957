import React from "react";

import ChangePasswordForm from "components/ChangePasswordForm/ChangePasswordForm";

const ChangePasswordView = () => {
  return (
    <div>
      <ChangePasswordForm />
    </div>
  );
};

export default ChangePasswordView;
