import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import LoadingOverlay from "components/Overlay";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  createSupplier,
  editSupplier,
  getUsersList,
  getPaymentType,
  getAllCountries,
} from "API/supplierApi";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "./AddSupplierForm.css";
import { Breadcrumbs } from "Breadcrumb";
import { cacheDuration } from "helpers/apiHelper";
import { fieldIds } from "constants/moduleFields";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
import { handleKeyPress } from "helpers/numberValidator";

const AddSupplierForm = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const location = useLocation();
  const [loadings, setLoading] = useState(false);

  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const supplierModuleID = parseInt(
    process.env.REACT_APP_SUPPLIER_MODULE_ID,
    10
  );
  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    error: errorUser,
    data: users,
  } = useQuery("users", getUsersList, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingPaymentTypes,
    isError: isErrorPaymentTypes,
    error: errorPaymentTypes,
    data: paymentTypes,
  } = useQuery("paymentTypes", getPaymentType, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const paymentTypesData = paymentTypes?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });
  const allCountriesData = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });
  const userData = users?.map((item) => ({
    id: item.id,
    name: `${item.first_name} ${item.last_name}`,
  }));

  useEffect(() => {
    if (location.state && paymentTypesData && allCountriesData) {
      const supplierData = location.state.data;

      const userVal = userData?.find((user) => user.id == supplierData.user_id);
      const paymentVal = paymentTypesData?.find((payment) => {
        return !isNaN(Number(supplierData.payment_type))
          ? payment.id == supplierData.payment_type
          : payment.name == supplierData.payment_type;
      });
      const countryVal = allCountriesData?.find((country) => {
        return !isNaN(Number(supplierData.country))
          ? country.id == supplierData.country
          : country.name == supplierData.country;
      });

      setValue("companyName", supplierData.company_name);
      setValue("contactPerson", supplierData.contact_person);
      setValue("street", supplierData.street);
      setValue("streetNo", supplierData.street_no);
      setValue("zipcode", supplierData.zipcode);
      setValue("city", supplierData.city);
      setSelectedCountry(countryVal);
      setValue("country", countryVal);
      setValue("phoneNumber", supplierData.phone_no);
      setValue("email", supplierData.email);
      setSelectedPayment(paymentVal);
      setValue("bankName", supplierData.bank_name);
      setValue("bankAccount", supplierData.bank_account);
      setValue("cryptowallet", supplierData.crypto_wallet_address);
      setValue("paypalWallet", supplierData.paypal_address);
      setValue("swiftCode", supplierData.swift_code);
      
      setSelectedUser(userVal);

      setIsEdit(true);
    }
  }, [isLoadingUsers, isLoadingPaymentTypes, isLoadingCountries]);

  const supplierCreator = useMutation(createSupplier, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/supplier");
      await showToast({
        icon: "success",
        title: "Supplier created successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const supplierEditor = useMutation(editSupplier, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/supplier");
      await showToast({
        icon: "success",
        title: "Supplier edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };
  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };
  const handlePaymentSelect = (payment) => {
    setSelectedPayment(payment);
  };
  const handleReset = () => {
    // Reset the form fields
    setSelectedCountry(null);
    setSelectedPayment(null);
    setSelectedUser(null);
    setApiError(false);
    reset();
  };

  const onSubmit = async (data) => {
    setApiError(false);
    const modulePermission = permissions?.permissions[supplierModuleID];
    const formData = new FormData();

    isFieldAddableOrEditable(
      fieldIds.supplier.company_name,
      modulePermission
    ) &&
      data.companyName &&
      formData.append("company_name", data.companyName);

    isFieldAddableOrEditable(
      fieldIds.supplier.contact_person,
      modulePermission
    ) &&
      data.contactPerson &&
      formData.append("contact_person", data.contactPerson);

    isFieldAddableOrEditable(fieldIds.supplier.street, modulePermission) &&
      data.street &&
      formData.append("street", data.street);

    isFieldAddableOrEditable(fieldIds.supplier.street_no, modulePermission) &&
      data.streetNo &&
      formData.append("street_no", data.streetNo);

    isFieldAddableOrEditable(fieldIds.supplier.zipcode, modulePermission) &&
      data.zipcode &&
      formData.append("zipcode", data.zipcode);

    isFieldAddableOrEditable(fieldIds.supplier.city, modulePermission) &&
      data.city &&
      formData.append("city", data.city);

    isFieldAddableOrEditable(fieldIds.supplier.country, modulePermission) &&
      selectedCountry &&
      formData.append("country", selectedCountry.id);

    isFieldAddableOrEditable(fieldIds.supplier.phone_no, modulePermission) &&
      formData.append("phone_no", data?.phoneNumber ? data.phoneNumber : "");

    isFieldAddableOrEditable(fieldIds.supplier.email, modulePermission) &&
      formData.append("email", data?.email ? data?.email : "");

    isFieldAddableOrEditable(
      fieldIds.supplier.payment_type,
      modulePermission
    ) &&
      formData.append(
        "payment_type",
        selectedPayment ? selectedPayment.id : ""
      );

    isFieldAddableOrEditable(fieldIds.supplier.bank_name, modulePermission) &&
      formData.append("bank_name", data?.bankName ? data.bankName : "");

    isFieldAddableOrEditable(
      fieldIds.supplier.bank_account,
      modulePermission
    ) &&
      formData.append(
        "bank_account",
        data?.bankAccount ? data.bankAccount : ""
      );

    isFieldAddableOrEditable(fieldIds.supplier.swift_code, modulePermission) &&
      formData.append("swift_code", data?.swiftCode ? data.swiftCode : "");

    isFieldAddableOrEditable(
      fieldIds.supplier.crypto_wallet_address,
      modulePermission
    ) &&
      formData.append(
        "crypto_wallet_address",
        data?.cryptowallet ? data.cryptowallet : ""
      );

    isFieldAddableOrEditable(
      fieldIds.supplier.paypal_address,
      modulePermission
    ) &&
      formData.append(
        "paypal_address",
        data?.paypalWallet ? data.paypalWallet : ""
      );

    isFieldAddableOrEditable(fieldIds.supplier.user_id, modulePermission) &&
      formData.append("user_id", selectedUser ? selectedUser.id : "");

    if (isEdit) {
    } else {
    }

    try {
      let response;
      if (isEdit) {
        response = await supplierEditor.mutateAsync({
          id: location.state.data.id,
          data: formData,
        });
      } else {
        response = await supplierCreator.mutateAsync(formData);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status == 400) {

        setApiError(error.response.data.message);
      } else {
        showServerMessage();
      }
    }
  };

  return (
    <div> <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">
                  {isEdit ? "Edit" : "Add"} supplier
                </Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      {/* <Col md="4"> */}

                      {/* </Col> */}
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          <fieldset>
                            <legend>Supplier details</legend>
                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.supplier.company_name,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Company name">
                                      <span className="label-text">
                                        Company name
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("companyName", {
                                        required: true,
                                        maxLength: 50,
                                        minLength: 3,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.companyName?.type ===
                                        "required" &&
                                        "Company name is required."}
                                      {errors.companyName?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.companyName?.type ===
                                        "minLength" &&
                                        "Enter minimum 3 characters."}
                                      {errors.companyName &&
                                        errors.companyName.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.supplier.contact_person,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Contact person">
                                      <span className="label-text">
                                        Contact person
                                      </span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("contactPerson", {
                                        required: false,
                                        maxLength: 50,
                                        minLength: 3,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.contactPerson?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.contactPerson?.type ===
                                        "minLength" &&
                                        "Enter minimum 3 characters."}
                                      {errors.contactPerson &&
                                        errors.contactPerson.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.phone_no,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Phone">
                                      <span className="label-text">Phone</span>
                                    </label>
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="number"
                                      onKeyPress={handleKeyPress}
                                      {...register("phoneNumber", {
                                        maxLength: 20,
                                        minLength: 10,
                                        pattern: {
                                          value: /^[0-9]+$/,
                                        },
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.phoneNumber?.type === "pattern" &&
                                        "Please enter a valid number."}
                                      {errors.phoneNumber?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 20 characters."}
                                      {errors.phoneNumber?.type ===
                                        "minLength" &&
                                        "Enter minimum 10 characters."}
                                      {errors.phoneNumber &&
                                        errors.phoneNumber.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.email,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Email">
                                      <span className="label-text">Email</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="email"
                                      {...register("email", {
                                        maxLength: 100,
                                        pattern: {
                                          value:
                                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                                        },
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.email?.type === "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.email?.type === "pattern" &&
                                        "Enter a valid email."}
                                      {errors.email && errors.email.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.street,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Street">
                                      <span className="label-text">Street</span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("street", {
                                        required: true,
                                        maxLength: 200,
                                        minLength: 3,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.street?.type === "required" &&
                                        "Street address is required."}
                                      {errors.street?.type === "maxLength" &&
                                        "It accepts maximum 200 characters."}
                                      {errors.street?.type === "minLength" &&
                                        "Enter minimum 3 characters."}
                                      {errors.street && errors.street.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.street_no,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Street number">
                                      <span className="label-text">
                                        Street number
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("streetNo", {
                                        required: true,
                                        maxLength: 10,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.streetNo?.type === "required" &&
                                        "Street number is required."}
                                      {errors.streetNo?.type === "maxLength" &&
                                        "It accepts maximum 10 characters."}

                                      {errors.streetNo &&
                                        errors.streetNo.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.zipcode,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Zip code">
                                      <span className="label-text">
                                        Zip code
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("zipcode", {
                                        required: true,
                                        maxLength: 20,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.zipcode?.type === "required" &&
                                        "Zip code is required."}
                                      {errors.zipcode?.type === "maxLength" &&
                                        "It accepts maximum 20 characters."}

                                      {errors.zipcode && errors.zipcode.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.city,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="City">
                                      <span className="label-text">City</span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("city", {
                                        required: true,
                                        maxLength: 20,
                                        minLength: 3,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.city?.type === "required" &&
                                        "City is required."}
                                      {errors.city?.type === "maxLength" &&
                                        "It accepts maximum 20 characters."}
                                      {errors.city?.type === "minLength" &&
                                        "Enter minimum 3 characters."}
                                      {errors.city && errors.city.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.country,
                                permissions?.permissions[supplierModuleID]
                              ) &&
                                allCountriesData && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      selectedOption={selectedCountry}
                                      options={allCountriesData}
                                      handleSelect={handleCountrySelect}
                                      label="Country"
                                      control={control}
                                      errors={errors}
                                      name="country"
                                      rules={{
                                        required: "Country is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.supplier.user_id,
                                permissions?.permissions[supplierModuleID]
                              ) &&
                                userData && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="User"
                                      selectedOption={selectedUser}
                                      options={userData}
                                      handleSelect={handleUserSelect}
                                      control={control}
                                      errors={errors}
                                      name="user"
                                    />
                                  </Col>
                                )}
                            </Row>
                          </fieldset>

                          <fieldset>
                            <legend>Payment details</legend>
                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.supplier.payment_type,
                                permissions?.permissions[supplierModuleID]
                              ) &&
                                paymentTypesData && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Payment type"
                                      selectedOption={selectedPayment}
                                      options={paymentTypesData}
                                      handleSelect={handlePaymentSelect}
                                      control={control}
                                      errors={errors}
                                      name="paymentType"
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.supplier.bank_name,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Bank name">
                                      <span className="label-text">
                                        Bank name
                                      </span>
                                      {selectedPayment &&
                                       
                                        ["2", "3"].includes(
                                          selectedPayment?.id
                                        ) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("bankName", {
                                        required:
                                          selectedPayment &&
                                       
                                          ["2", "3"].includes(
                                            selectedPayment.id
                                          ) &&
                                          true,
                                        maxLength: 200,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.bankName?.type === "maxLength" &&
                                        "It accepts maximum 200 characters."}
                                      {errors.bankName?.type === "required" &&
                                     
                                        ["2", "3"].includes(
                                          selectedPayment?.id
                                        ) &&
                                        "Bank name is required."}
                                      {errors.bankName &&
                                        errors.bankName.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.bank_account,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Bank account">
                                      <span className="label-text">
                                      Bank account (IBAN)
                                      </span>
                                      {selectedPayment &&
                                   
                                        ["2", "3"].includes(
                                          selectedPayment?.id
                                        ) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("bankAccount", {
                                        required:
                                          selectedPayment &&
                                      
                                          ["2", "3"].includes(
                                            selectedPayment.id
                                          ) &&
                                          true,
                                        maxLength: 50,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.bankAccount?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.bankAccount?.type ===
                                        "required" &&
                                     
                                        ["2", "3"].includes(
                                          selectedPayment?.id
                                        ) &&
                                        "Bank account is required."}
                                      {errors.bankAccount &&
                                        errors.bankAccount.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.swift_code,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Swift code">
                                      <span className="label-text">
                                        Swift code
                                      </span>
                                      {selectedPayment &&
                                 
                                        ["2", "3"].includes(
                                          selectedPayment?.id
                                        ) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      
                                      {...register("swiftCode", {
                                        required:
                                          selectedPayment &&
                                      
                                          ["2", "3"].includes(
                                            selectedPayment.id
                                          ) &&
                                          true,
                                        maxLength: 50,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.swiftCode?.type === "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.swiftCode?.type === "required" &&
                                    
                                        ["2", "3"].includes(
                                          selectedPayment?.id
                                        ) &&
                                        "Swift code is required."}
                                      {errors.swiftCode &&
                                        errors.swiftCode.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.crypto_wallet_address,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Crypto wallet">
                                      <span className="label-text">
                                        Crypto wallet
                                      </span>
                                      {selectedPayment &&
                                  
                                        ["4"].includes(selectedPayment?.id) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("cryptowallet", {
                                        required:
                                          selectedPayment &&
                                      
                                          ["4"].includes(selectedPayment.id) &&
                                          true,
                                        maxLength: 255,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.cryptowallet?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 255 characters."}
                                      {errors.cryptowallet?.type ===
                                        "required" &&
                                   
                                        ["4"].includes(selectedPayment?.id) &&
                                        "Crypto wallet is required."}
                                      {errors.cryptowallet &&
                                        errors.cryptowallet.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.supplier.paypal_address,
                                permissions?.permissions[supplierModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="PayPal address">
                                      <span className="label-text">
                                        PayPal address
                                      </span>
                                      {selectedPayment &&
                                     
                                        ["5"].includes(selectedPayment?.id) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("paypalWallet", {
                                        required:
                                          selectedPayment &&
                                      
                                          ["5"].includes(selectedPayment.id) &&
                                          true,
                                        maxLength: 255,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.paypalWallet?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 255 characters."}
                                      {errors.paypalWallet?.type ===
                                        "required" &&
                                    
                                        ["5"].includes(selectedPayment?.id) &&
                                        "PayPal wallet is required."}
                                      {errors.paypalWallet &&
                                        errors.paypalWallet.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}
                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right  mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddSupplierForm;
