import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import "../LoginForm/loginform.css";
import { otpChecker } from "API/authAPI";
import { storeData, clearLocalStorage } from "helpers/authHelper";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import { loginRedirector } from "helpers/loginRedirector";
import showToast from "components/Toast/Toast";
import { LoadingOutlined } from "@ant-design/icons";
import LoadingOverlay from "components/Overlay";

const TOTPForm = ({ userToken }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [apiError, setApiError] = useState(false);
  const [displayErrorMessage, showErrorMessage] = useErrorMessage();
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loadings, setLoading] = useState(false);

  const navigate = useNavigate();

  const totpChecker = useMutation(otpChecker, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      if (response.message.token["access"]) {
        setLoading(false);
        storeData(
          response.message.id,
          response.message.token.access,
          response.message.token.refresh,
          response.message.role
        );

        //  navigate("/dashboard");
        try {
          const route = await loginRedirector();
          navigate(route);
        } catch (err) {
        setLoading(false);
          
          showServerMessage();
          await clearLocalStorage();
        }
      }
      setConfirmLoading(false)
    },

  });

  const onSubmit = async (data) => {
    setConfirmLoading(true)
    setApiError(false);
    try {
      const response = await totpChecker.mutateAsync({
        otp: data.totp,
        token: userToken,
      });
    } catch (error) {
      setConfirmLoading(false)
      if (error.response.status == 408) {
        setLoading(false);
        showErrorMessage();
        navigate("/");
        await showToast({
          icon: "error",
          title: "Session expired! please try again.",
          position: "top-end",
          timer: 2000,
        });
        // window.location.href="/login"
      } else if (error.response.status == 400) {
        setLoading(false);

        setApiError(error.response.data.message);
      } else {
        setLoading(false);

        showServerMessage();
      }
    }
  };
  const handleKeyPress = (event) => {
    const keyCode = event.which || event.keyCode;
    if (keyCode == 13) {
      handleSubmit(onSubmit)();
    }
    if (keyCode < 48 || keyCode > 57) {
      event.preventDefault(); // Prevent non-numeric input
    }
  };
  return (
    <div>
       <LoadingOverlay isLoading={loadings} />

      <Form className="flex flex-col gap-2 " onSubmit={handleSubmit(onSubmit)}>
        <div className="text-blueGray-400 text-center mb-3 font-bold pt-1">
          <h4 className="mb-4 text-center  login-title">Login</h4>
        </div>
        <div className="relative w-full mb-2 user-fields-wrapper form-field">
          <Form.Group
            controlId="formBasicOTP"
            className="d-flex flex-column gap-2"
          >
            <Form.Label className="">
              Please enter code from TOTP device <span style={{color: 'red'}}>*</span>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder=""
              className=""
              onKeyPress={handleKeyPress}
              autocomplete="off"
              {...register("totp", {
                required: true,
                maxLength: 6,
                minLength: 6,
                pattern: {
                  value: /^[0-9]+$/,
                },
              })}
            />
            <span className="error-message">
              <div>
                {errors.totp?.type === "required" && "TOTP is required."}
                {errors.totp?.type === "pattern" &&
                  "Please enter a valid number."}
                {errors.totp?.type === "maxLength" &&
                  "It accepts maximum 6 characters."}
                {errors.totp?.type === "minLength" &&
                  "It accepts minimum 6 characters."}
                {errors.totp && errors.totp.message}
              </div>
            </span>
          </Form.Group>
        </div>
        {displayErrorMessage && (
          <FormAlertMessage
            message="Login Session Timeout"
            type="error"
            className="alert-message-without-spacing"
          />
        )}
        {displayServerMessage && (
          <FormAlertMessage
            type="error"
            message={serverErrorMessage}
            className="alert-message-without-spacing"
          />
        )}
        {apiError && (
          <FormAlertMessage
            type="error"
            message={apiError}
            className="alert-message-without-spacing"
          />
        )}

        <Button
          variant="primary"
          type="submit"
          block="true"
          className="mt-3 mb-4 submit-btn login-btn"
          disabled={confirmLoading}
        >
          {confirmLoading ? (
            <>
              <LoadingOutlined /> Login
            </>
          ) : (
            "Login"
          )}
        </Button>
      </Form>
    </div>
  );
};

export default TOTPForm;
