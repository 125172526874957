import { makeRequest } from "helpers/apiHelper";

export const getServicePartners = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/servicepartner/service-partner/?${queryParamsString}`;
  return makeRequest("get", url);
};

export const createServicePartners = async (data) => {
  return makeRequest("post", "/servicepartner/service-partner/", data);
};

export const editServicePartners = async ({ id, data }) => {
  return makeRequest("patch", `/servicepartner/service-partner/${id}/`, data);
};

export const deleteServicePartners = ({ id }) => {
  return makeRequest("delete", `/servicepartner/service-partner/${id}/`);
};

export const getScriptOptions = async () => {
  return makeRequest("get", "/servicepartner/service-partner/get-automation-cript");
};

export const getSingleServicePartData = async (params) => {
  const url = `/servicepartner/service-partner/${params.queryKey[1]}/`;
  return makeRequest("get", url);
};