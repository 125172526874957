import React from "react";
import AddExpirationListForm from "components/ExpirationList/AddExpirationListForm";
const AddExpirationListView = () => {
  return (
    <div>
      <AddExpirationListForm />
    </div>
  );
};

export default AddExpirationListView;
