import React from "react";
import AddSimPacKageView from "views/SimPackage/AddSimPacKageView";
const AddSimPackage = () => {
  return (
    <div>
      <AddSimPacKageView />
    </div>
  );
};

export default AddSimPackage;
