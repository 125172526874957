import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { FilterOutlined } from "@ant-design/icons";


const DropdownFilter = ({
  title,
  selectedValue,
  items,
  onSelect,
  filterName,
  enableSearch,
  defaultValue,
  packageTypeSelect,
}) => {
  const [selectedLabel, setSelectedLabel] = useState();

  const newArray = items.map((obj, index) => {
    return {
      key: index,
      label: obj.name,
      value: obj.id,
    };
  });

  useEffect(() => {
    if (defaultValue) {
      handleSelectChange(defaultValue.value);
    }
  }, []);

  const handleSelectChange = (value) => {
    onSelect(value);
    const selectedItem = items.find((item) => item.id == value);
    if (selectedItem.type) {
      packageTypeSelect(selectedItem.type);
    }
    setSelectedLabel(selectedItem ? selectedItem.name : title);
  };
  const filterOption = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  return (
    <Select
      placeholder={
        <div>
          <FilterOutlined /> {filterName}
        </div>
      }
      style={{ width: 120 }}
      onChange={handleSelectChange}
      options={newArray}
      showSearch={enableSearch}
      filterOption={filterOption}
      value={selectedValue}
    >
      {newArray.map((item) => (
        <Select.Option key={item.value} value={item.value} label={item.label}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DropdownFilter;
