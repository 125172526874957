import React from 'react';
import UpdateProfileView from 'views/UpdateProfile/UpdateProfileView';

const UpdateProfile = () => {

  return (
    <div><UpdateProfileView/></div>
  )
}

export default UpdateProfile;