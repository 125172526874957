export const permissionsDesign = {
  permissions: {
    // 1: {
    //   name: "User Role",
    //   is_addable: true,
    //   is_editable: true,
    //   is_deletable: false,
    //   view_access: true,
    //   add_access: true,
    //   edit_access: true,
    //   delete_access: false,
    //   fields: {
    //     11: {
    //       name: "name",
    //       is_viewable: true,
    //       is_add_editable: true,
    //       is_required: true,
    //       view_access: true,
    //       add_edit_access: true,
    //     },
    //     12: {
    //       name: "created_at",
    //       is_viewable: true,
    //       is_add_editable: false,
    //       is_required: false,
    //       view_access: true,
    //       add_edit_access: false,
    //     },
    //     13: {
    //       name: "permissions",
    //       is_viewable: false,
    //       is_add_editable: true,
    //       is_required: false,
    //       view_access: false,
    //       add_edit_access: true,
    //     },
    //   },
    // },
    2: {
      name: "Registration",
      is_addable: true,
      is_editable: true,
      is_deletable: false,
      view_access: true,
      add_access: true,
      edit_access: true,
      delete_access: false,
      fields: {
        1: {
          name: "first_name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        2: {
          name: "last_name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        3: {
          name: "email",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        4: {
          name: "role_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        5: {
          name: "user_image",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        6: {
          name: "phone_number",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        7: {
          name: "address",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        8: {
          name: "date_of_birth",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        9: {
          name: "password",
          is_viewable: false,
          is_add_editable: true,
          is_column_visible: false,
          is_required: true,
          view_access: false,
          add_edit_access: true,
          column_show: false,
        },
        10: {
          name: "status",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        147: {
          name: "reset_tfa",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
      },
    },
    // 3: {
    //   name: "User Profile",
    //   is_addable: false,
    //   is_editable: true,
    //   is_deletable: false,
    //   view_access: false,
    //   add_access: false,
    //   edit_access: true,
    //   delete_access: false,
    //   fields: {
    //     14: {
    //       name: "first_name",
    //       is_viewable: true,
    //       is_add_editable: true,
    //       view_access: true,
    //       add_edit_access: true,
    //     },
    //     15: {
    //       name: "last_name",
    //       is_viewable: true,
    //       is_add_editable: true,
    //       view_access: true,
    //       add_edit_access: true,
    //     },
    //     16: {
    //       name: "email",
    //       is_viewable: true,
    //       is_add_editable: true,
    //       view_access: true,
    //       add_edit_access: true,
    //     },
    //     17: {
    //       name: "user_image",
    //       is_viewable: true,
    //       is_add_editable: true,
    //       view_access: true,
    //       add_edit_access: true,
    //     },
    //     18: {
    //       name: "phone_number",
    //       is_viewable: true,
    //       is_add_editable: true,
    //       view_access: true,
    //       add_edit_access: true,
    //     },
    //     19: {
    //       name: "address",
    //       is_viewable: true,
    //       is_add_editable: true,
    //       view_access: true,
    //       add_edit_access: true,
    //     },
    //     20: {
    //       name: "date_of_birth",
    //       is_viewable: true,
    //       is_add_editable: true,
    //       view_access: true,
    //       add_edit_access: true,
    //     },
    //   },
    // },
    // 4: {
    //   name: "Change Password",
    //   is_addable: true,
    //   is_editable: true,
    //   is_deletable: false,
    //   view_access: false,
    //   add_access: false,
    //   edit_access: true,
    //   delete_access: false,
    //   fields: {
    //     21: {
    //       name: "password",
    //       is_viewable: true,
    //       is_add_editable: true,
    //       view_access: false,
    //       add_edit_access: true,
    //     },
    //   },
    // },
    5: {
      name: "Dashboard",
      is_addable: false,
      is_editable: false,
      is_deletable: false,
      view_access: false,
      add_access: false,
      edit_access: false,
      delete_access: false,
      fields: {
        199: {
          name: "sim_package_counter",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: false,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        200: {
          name: "modem_counter",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: false,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        201: {
          name: "storage_box_counter",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: false,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        202: {
          name: "available_modem_counter",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: false,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        203: {
          name: "sim_count_pie_chart",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: false,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        204: {
          name: "profit_chart",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: false,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        205: {
          name: "sim_expire",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: false,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        206: {
          name: "green_modem",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: false,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
      },
    },
    6: {
      name: "Supplier",
      is_addable: true,
      is_editable: true,
      is_deletable: true,
      view_access: true,
      add_access: true,
      edit_access: true,
      delete_access: true,
      fields: {
        22: {
          name: "company_name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        24: {
          name: "street",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        25: {
          name: "street_no",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        26: {
          name: "zipcode",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        27: {
          name: "city",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        28: {
          name: "country",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        29: {
          name: "phone_no",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        30: {
          name: "email",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        37: {
          name: "status",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        31: {
          name: "payment_type",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        32: {
          name: "bank_name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        33: {
          name: "bank_account",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        36: {
          name: "user_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        23: {
          name: "contact_person",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        34: {
          name: "crypto_wallet_address",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        35: {
          name: "paypal_address",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        188: {
          name: "swift_code",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
      },
    },
    7: {
      name: "Storage Box",
      is_addable: true,
      is_editable: true,
      is_deletable: true,
      view_access: true,
      add_access: true,
      edit_access: true,
      delete_access: true,
      fields: {
        39: {
          name: "status",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        38: {
          name: "name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        242: {
          name: "stored_packages",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: true,
        },
      },
    },
    8: {
      name: "Service Partner",
      is_addable: true,
      is_editable: true,
      is_deletable: true,
      view_access: true,
      add_access: true,
      edit_access: true,
      delete_access: true,
      fields: {
        42: {
          name: "registration_no",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        43: {
          name: "vat_number",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        44: {
          name: "website1",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        45: {
          name: "website2",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        47: {
          name: "gray_profit",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        48: {
          name: "green_profit",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        49: {
          name: "yellow_profit",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        50: {
          name: "short_name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        40: {
          name: "company_name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        41: {
          name: "address",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        69: {
          name: "country",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        72: {
          name: "username",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        73: {
          name: "password",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        46: {
          name: "interval_before_reuse",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        241: {
          name: "automation_script",
          is_viewable: false,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: false,
          add_edit_access: true,
          column_show: false,
        },
      },
    },
    9: {
      name: "Network Provider",
      is_addable: true,
      is_editable: true,
      is_deletable: true,
      view_access: true,
      add_access: true,
      edit_access: true,
      delete_access: true,
      fields: {
        57: {
          name: "pin",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        59: {
          name: "activation",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        54: {
          name: "ussd",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        55: {
          name: "number_on_card",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        56: {
          name: "at_cnum",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        63: {
          name: "notes",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        51: {
          name: "name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        52: {
          name: "country",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        53: {
          name: "deliverer",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        58: {
          name: "network_quality",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        136: {
          name: "is_activation_required_for_sms",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        137: {
          name: "avoid_deactivation_card",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        138: {
          name: "min_day_for_call",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        146: {
          name: "active_after_first_charge",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        139: {
          name: "min_day_for_topup",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        140: {
          name: "topup_amount",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        141: {
          name: "charge_after_days",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        142: {
          name: "charge_amount",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        143: {
          name: "one_call_cost",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        144: {
          name: "active_after_activation",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        145: {
          name: "active_after_first_call_or_topup",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
      },
    },
    10: {
      name: "Modem",
      is_addable: true,
      is_editable: true,
      is_deletable: true,
      view_access: true,
      add_access: true,
      edit_access: true,
      delete_access: true,
      fields: {
        64: {
          name: "name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        65: {
          name: "defective_port",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        66: {
          name: "term",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        67: {
          name: "status",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        70: {
          name: "name_from_sp",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        68: {
          name: "service_partner_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        264: {
          name: "type",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
      },
    },
    11: {
      name: "PC Modem",
      is_addable: true,
      is_editable: true,
      is_deletable: true,
      view_access: true,
      add_access: true,
      edit_access: true,
      delete_access: true,
      fields: {
        86: {
          name: "running_service_partner_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        75: {
          name: "modem_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        76: {
          name: "anydesk_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        77: {
          name: "password_full_access",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        78: {
          name: "password_for_limited_access",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        79: {
          name: "seller_shop_name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        81: {
          name: "config_status",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        82: {
          name: "is_software_prepared_for_sp1",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        83: {
          name: "is_software_prepared_for_sp2",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        84: {
          name: "is_software_prepared_for_sp3",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        85: {
          name: "notes",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        74: {
          name: "pc_name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        80: {
          name: "windows_license_key",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
      },
    },
    12: {
      name: "SIM Package",
      is_addable: true,
      is_editable: true,
      is_deletable: true,
      view_access: true,
      add_access: true,
      edit_access: true,
      delete_access: true,
      fields: {
        87: {
          name: "date_of_delevery",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        88: {
          name: "name",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        89: {
          name: "provider_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        90: {
          name: "country",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        91: {
          name: "supplier_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        92: {
          name: "amount_of_sim",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        94: {
          name: "missing_cards",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        103: {
          name: "priority",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        104: {
          name: "storage_before_use_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        93: {
          name: "more_card",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        105: {
          name: "description_before_use",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        106: {
          name: "storage_after_use_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        115: {
          name: "price_per_sim",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        116: {
          name: "paid_amount",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        117: {
          name: "paid_date",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        128: {
          name: "used_service_partner",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        129: {
          name: "cancellation",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        130: {
          name: "price_in_total",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        131: {
          name: "finished_to_send_back",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        132: {
          name: "is_returned",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        95: {
          name: "return_type",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        107: {
          name: "description_after_use",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        120: {
          name: "total_sold_card",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        121: {
          name: "note",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        122: {
          name: "defective",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        134: {
          name: "how_many_unused",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        135: {
          name: "total_sim_cards",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        108: {
          name: "storage_for_defective_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        109: {
          name: "description_for_defactive",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        110: {
          name: "amount_of_card_given_back",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        111: {
          name: "delivery_date_cz_de",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        112: {
          name: "delivery_date_to_supplier",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        113: {
          name: "thrown_away_card",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        114: {
          name: "currency",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        123: {
          name: "sub_name",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        124: {
          name: "date_of_first_use",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        125: {
          name: "date_of_last_use",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        // 126: {
        //   name: "last_used_date",
        //   is_viewable: true,
        //   is_add_editable: false,
        //   is_column_visible: true,
        //   is_required: false,
        //   view_access: true,
        //   add_edit_access: false,
        //   column_show: false,
        // },
        96: {
          name: "last_give_back_date",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: true,
        },
        97: {
          name: "is_unpacking_needed",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        98: {
          name: "scan",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        99: {
          name: "scan_phonenumber",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        100: {
          name: "activation_status",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        101: {
          name: "expire_date_to_activate_sim",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        102: {
          name: "expire_date_after_activation",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        127: {
          name: "usage_status",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
      },
    },
    13: {
      name: "Modem Dashboard",
      is_addable: true,
      is_editable: true,
      is_deletable: false,
      view_access: true,
      add_access: true,
      edit_access: true,
      delete_access: false,
      fields: {
        151: {
          name: "duration",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        154: {
          name: "traffic_light",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: true,
        },
        149: {
          name: "modem_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: true,
        },
        152: {
          name: "actual_profit",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        153: {
          name: "avg_profit_per_sim",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        155: {
          name: "start_btn",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: true,
        },
        156: {
          name: "stop_btn",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: true,
        },
        157: {
          name: "finish_btn",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: true,
        },
        158: {
          name: "log_btn",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: true,
        },
        159: {
          name: "add_sim",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        160: {
          name: "take_out_sim",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        161: {
          name: "log_no",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        162: {
          name: "service_partner_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        150: {
          name: "start_date",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        163: {
          name: "note",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        164: {
          name: "term",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        165: {
          name: "vip_number",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        166: {
          name: "packages",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        167: {
          name: "total_time",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        168: {
          name: "amount",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        169: {
          name: "port",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        170: {
          name: "defective_port",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        171: {
          name: "storage_after_use_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        172: {
          name: "note_for_storage_after_use",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        173: {
          name: "storage_before_use_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        174: {
          name: "storage_for_defactive_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        175: {
          name: "start_datetime",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        176: {
          name: "end_datetime",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        177: {
          name: "total_time_days",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        178: {
          name: "total_profit",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        179: {
          name: "log_avg_profit_per_sim",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        180: {
          name: "finished",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        181: {
          name: "log_avg_profit_per_min",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        182: {
          name: "log_avg_profit_per_day",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        183: {
          name: "how_many_defactive",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        184: {
          name: "provider_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        185: {
          name: "keep_or_give_back",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },

        186: {
          name: "note_for_storage_before_use",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        187: {
          name: "note_for_storage_for_defactive",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
      },
    },
    14: {
      name: "Software Log",
      is_addable: false,
      is_editable: false,
      is_deletable: false,
      view_access: true,
      add_access: false,
      edit_access: false,
      delete_access: false,
      fields: {
        189: {
          name: "modem_log_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        190: {
          name: "service_partner_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        191: {
          name: "event_status",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: true,
        },
        193: {
          name: "action",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        194: {
          name: "is_error",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        195: {
          name: "error_message",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        196: {
          name: "action_taken",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        197: {
          name: "action_taken_by_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        198: {
          name: "created_at",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
      },
    },
    15: {
      name: "Admin Setting",
      is_addable: false,
      is_editable: true,
      is_deletable: false,
      view_access: true,
      add_access: false,
      edit_access: true,
      delete_access: false,
      fields: {
        207: {
          name: "profit_fetch_interval",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
      },
    },
    16: {
      name: "Expiration List",
      is_addable: true,
      is_editable: true,
      is_deletable: false,
      view_access: true,
      add_access: true,
      edit_access: true,
      delete_access: false,
      fields: {
        224: {
          name: "expiration_date_for_activation",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        225: {
          name: "expiration_date_after_activation",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        226: {
          name: "days_from_last_balance",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        230: {
          name: "next_needed_topup_date",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        208: {
          name: "modem_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        209: {
          name: "package_id",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        211: {
          name: "port",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        212: {
          name: "number",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        213: {
          name: "iccid",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        214: {
          name: "actual_until",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        215: {
          name: "is_called",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        216: {
          name: "call_date",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        217: {
          name: "latest_balance_amount",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        218: {
          name: "balance_date",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        219: {
          name: "latest_topup_amount",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        220: {
          name: "topup_date",
          is_viewable: true,
          is_add_editable: true,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: true,
          column_show: false,
        },
        210: {
          name: "start_datetime",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: true,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        221: {
          name: "end_datetime",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        222: {
          name: "activation_status",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        223: {
          name: "activation_date",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        227: {
          name: "total_topup_amount",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        228: {
          name: "next_needed_call_date",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        229: {
          name: "rem_days_for_needed_call",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        231: {
          name: "rem_days_for_needed_topup",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        232: {
          name: "possible_call_without_further_topup",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        233: {
          name: "latest_call_without_further_topup",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        234: {
          name: "remider_date_for_call",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        235: {
          name: "remider_date_for_topup",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        236: {
          name: "created_by_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        237: {
          name: "add_balance",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: false,
        },
        238: {
          name: "service_partner_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: true,
        },
        239: {
          name: "provider_id",
          is_viewable: true,
          is_add_editable: false,
          is_column_visible: true,
          is_required: false,
          view_access: true,
          add_edit_access: false,
          column_show: true,
        },
      },
    },
  },
};

export const customNames = {
  1: {
    11: "Role Name",

    12: "Created at",

    13: "Permissions",
    148: "Status",
  },
  2: {
    1: "First name",
    2: "Last name",

    3: "Email",

    4: "Role",

    5: "User image",

    6: "Phone number",

    7: "Address",

    8: "Date of birth",

    9: "Password",

    10: "Status",
    147: "Reset 2FA",
  },
  3: {
    14: "First name",

    15: "Last name",

    16: "Email name",

    17: "User image",

    18: "Phone number",

    19: "Address",

    20: "Date of birth",
  },
  4: {
    21: "Password",
  },
  5: {
    199: "SIM Package counter",
    200: "Modem counter",
    201: "Storage Box counter",
    202: "Available modem counter",
    203: "SIM count pie chart",
    204: "Profit chart",
    205: "SIM expire",
    206: "Green modem",
  },
  6: {
    22: "Company name",
    23: "Contact person",
    24: "Street",
    25: "Street No.",
    26: "Zipcode",
    27: "City",
    28: "Country",
    29: "Phone number",
    30: "Email",
    31: "Payment type",
    32: "Bank name",
    33: "Bank account",
    34: "Crypto wallet address",
    35: "PayPal address",
    36: "User",
    37: "Status",
    188: "Swift code",
  },
  7: {
    38: "Name",
    39: "Status",
    242: "Sim Packages",
  },
  8: {
    40: "Company name",
    50: "Short name",
    41: "Address",
    42: "Registration No.",
    43: "VAT number",
    44: "Website 1",
    45: "Website 2",
    46: "Interval before reuse",
    47: "Gray profit",
    48: "Green profit",
    49: "Yellow profit",
    69: "Country",
    72: "Username",
    73: "Password",
    241: "Automation script",
  },
  9: {
    51: "Name",
    52: "Country",
    53: "Supplier",
    54: "USSD",
    55: "Number on cards",
    56: "AT + CNUM",
    57: "Pin",
    58: "Network quality",
    59: "Activation",
    63: "Notes",
    136: "Activate required for receive SMS",
    137: "Avoid deactivation of card",
    138: "Days for call",
    139: "Days for top-up",
    140: "Amount of top-up",
    141: "Days for charge",
    142: "Amount of charge",
    143: "Cost for one call",
    144: "Active after activation (no further action)",
    145: "Active after first call/top-up (no further action)",
    146: "Active after first charge (days)",
  },
  10: {
    70: "Name from Service Partner",
    64: "Name",
    65: "Defective port",
    66: "Term",
    67: "Status",
    68: "Service Partner",
    264: "Service Partner type"
  },
  11: {
    74: "PC name",
    75: "Modem",
    76: "Anydesk ID",
    77: "Password full access",
    78: "Password for limited access",
    81: "Config status",
    82: "Is software prepared for SP1",
    79: "Seller shop name",
    80: "Windows license key",
    83: "Is software prepared for SP2",
    84: "Is software prepared for SP3",
    85: "Notes",
    86: "Running Service Partner",
  },
  12: {
    89: "Provider",
    90: "Country",
    91: "Supplier",
    92: "Amount of SIM",
    93: "More cards",
    94: "Missing cards",
    87: "Package receive date",
    88: "Name",
    // 126: "Last used date",
    127: "Usage status",
    128: "Used Service Partner",
    129: "Cancellation",
    130: "Price in total",
    131: "Finished to send back",
    132: "Is returned",
    133: "Sold in total",
    134: "How many unused",
    95: "Return type",
    96: "Last give back date",
    97: "Is unpacking needed",
    98: "Scan",
    99: "Scan phone number",
    100: "Activation status",
    101: "Expire date to activation",
    102: "Expire date after activation",
    103: "Priority",
    104: "Storage before use",
    105: "Note for storage before use",
    106: "Storage after use",
    107: "Note for storage after use",
    108: "Storage for defective",
    109: "Note for storage for defective",
    110: "Amount of card given back",
    111: "Delivery date CZ-DE",
    112: "Delivery date to supplier",
    113: "Thrown away cards",
    114: "Currency",
    115: "Price per SIM",
    116: "Paid amount",
    117: "Paid date",
    120: "Sold price per SIM",
    121: "Note",
    122: "Defective",
    123: "Sub name",
    124: "Date of first use",
    125: "Date of last use",
    135: "Total amount (No. of SIM cards)",
    261: "Total sold price in USD",
    262: "Total sold price in EUR",
  },
  13: {
    150: "Start Date",
    153: "Avg. Profit Per Sim",
    149: "Modem",
    151: "Duration",
    152: "Actual Profit",
    154: "Traffic Light",
    155: "Start Button",
    156: "Stop Button",
    157: "Finish Button",
    158: "Log Button",
    159: "Add SIM",
    160: "Take out SIM",
    161: "Log ID",
    162: "Service Partner",
    150: "Start Date",
    163: "Note",
    164: "Term",
    165: "VIP Number",
    166: "Packages",
    167: "Total Time",
    168: "Amount",
    169: "Port",
    170: "Defective Port",
    171: "Stoage after use",
    172: "Note for storage after use",
    173: "Storage before use",
    174: "Storage for defective",
    175: "Start date time",
    176: "End date time",
    177: "Total time days",
    178: "Total profit",
    179: "Average profit per SIM",
    180: "Finished",
    181: "Average per minutes",
    182: "Average per day",
    183: "How many defective",
    184: "Provider",
    185: "Keep or give back",
    186: "Note for storage before use",
    187: "Note for storage for defective",
    256: "Last updated rate date",
  },
  14: {
    189: "Modem Log",
    190: "Service Partner",
    191: "Event status",
    193: "Event",
    194: "Is Error",
    195: "Error message",
    196: "Action taken",
    197: "Action taken by",
    198: "Created at",
  },
  15: {
    207: "Profit fetch interval",
  },
  16: {
    224: "Expiration date for activation",
    225: "Expiration date after activation",
    226: "Days from last balance",
    230: "Next needed topup date",
    208: "Modem",
    209: " Package",
    211: "Port",
    212: "Number",
    213: "ICCID",
    214: "Actual profit",
    215: "Is called",
    216: "Call date",
    217: "Latest balance date",
    218: "Balance date",
    219: "Latest topup amount",
    220: "Topup date",
    210: "Start date",
    221: "End date",
    222: "Activation status",
    223: "Activation date",
    227: "Total topup amount",
    228: "Next needed call date",
    229: "Remaining days for needed call",
    231: "Remaining days for needed topup",
    232: "Possible calls without further topup",
    233: "Latest call without further topup",
    234: "Reminder date for call",
    235: "Reminder date for topup",
    236: "Created by ID",
    237: "Add balance",
    238: "Service Partner",
    239: "Provider",
  },
  17:{
    243: "Currency Date",
    244: "Created By",
    245: "Updated Date",
    246: "Created Date",
    247: "Currency Rate EUR",
    248: "Id",
    258: "Currency Rate RUB",
    260: "Currency Rate CZK",
    263:"Task status"
  },
  18:{
    251:"Updated At",
    253:"Selling Price Per Sim",
    254:"Selling Currency",
    255:"id",
    259:"Selling Date",
    257:"Sold Card",
    250:"Sim Package Id",
    252:"Created At",

  }

};
