import React from "react";
import StorageBoxView from "views/StorageBox/StorageBoxView";

const StorageBox = () => {
  return (
    <div>
      <StorageBoxView />
    </div>
  );
};

export default StorageBox;
