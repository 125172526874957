import React from 'react'

import ResetPasswordForm from 'components/ResetPassword/ResetPasswordForm'

const ResetPasswordView = () => {
  return (
    <div>
        <ResetPasswordForm/>
    </div>
  )
}

export default ResetPasswordView