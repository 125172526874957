import React from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useLocation,useNavigate
  
 } from "react-router-dom";
import routes from "routes";
import usePermissions from "customHooks/usePermissions";
import { canAccessModule } from "helpers/permissionChecker";

export const Breadcrumbs = (_key = null) => {
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path, { state: {data :_key.data} });
  };

  const generateBreadcrumbs = (path, accumulator = []) => {
    const currentModule = routes.find((module) => module.path === path);
    if (currentModule) {
      const breadcrumb = {
        // href: currentModule.path,
   title: (
          <span style={{ cursor: location.pathname === path ? "default" : "pointer" }}
                      onClick={() => location.pathname !== path && handleNavigation(currentModule.path)}>
            {currentModule.name}
          </span>
        ),
      };

      accumulator.unshift(breadcrumb); // Add the breadcrumb to the beginning of the array

      if (currentModule.module && currentModule.module !== currentModule.name) {
        // Check for circular references
        const parentModule = routes.find(
          (module) => module.name === currentModule.module
        );

        if (parentModule && parentModule.path !== currentModule.path) {
          // Avoid infinite loop by checking that the parent path is different
          generateBreadcrumbs(parentModule.path, accumulator);
        }
      }
    }

    return accumulator;
  };

  const commonParentBreadcrumb = {
    href: "/dashboard", // Update the href as needed
    title: (
      <span>
        <HomeOutlined /> {/* Add your home icon component here */}
      </span>
    ),
  };

  const breadcrumbs =
    permissions &&
    canAccessModule(
      {
        moduleId: parseInt(process.env.REACT_APP_DASHBOARD_MODULE_ID, 10),
        accessType: "view",
      },
      permissions
    )
      ? [commonParentBreadcrumb, ...generateBreadcrumbs(location.pathname)]
      : generateBreadcrumbs(location.pathname);

  return <Breadcrumb items={breadcrumbs} />;
};
