import React from "react";

import DashboardView from "views/Dashboard/Dashboard";

const Dashboard = () => {
  return (
    <div>
      <DashboardView />
    </div>
  );
};

export default Dashboard;
