import React, { useState } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { UserAddOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import LoadingOverlay from "components/Overlay";

import {
  getStorageBoxes,
  editStorageBox,
  deleteStorageBox,
} from "API/storageBoxApi";
import ConfirmModal from "components/Modal/Modal";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";


const StorageBoxTableComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged,setPermissionChanged]=useState(true)
  const [loadings, setLoading] = useState(false);

  const storageBoxModuleID = parseInt(
    process.env.REACT_APP_STORAGE_BOX_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    status_filter: selectedStatus,
    search_filter: searchInput,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: storageBox,
    refetch,
  } = useQuery(["storageBox", queryParams], getStorageBoxes, {
    cacheTime: cacheDuration,
  });

  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };

  const handleStatusSelect = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
    refetch();
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  const storageBoxEditor = useMutation(editStorageBox, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      refetch();
      setLoading(false);
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      await refetch();
      setLoading(false);

      await permissionRefetch();
      setPermissionChanged(true)
    },
  });
  const removeStorageBox = useMutation(deleteStorageBox, {
    onMutate: () => {
      
      setLoading(true);
    },
    onSuccess: async () => {
      await refetch();
      setLoading(false);
    },
  });

  const handleEdit = (box) => {
    navigate("/edit-storage-box", { state: { data: box } });
  };

  const handleDelete = async (box) => {
    const deleteApi = async () => {
      try {
        const response = await removeStorageBox.mutateAsync({ id: box.id });
        return response;
      } catch (error) {
        setLoading(false);
        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: deleteApi,
      deleteErrorText: "Storage box is in use!",
    });
  };

  const handleToggle = async (id, status) => {
    const response = await storageBoxEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };

  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen && selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: storageBoxModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
    setLoading(false);

  };

  const statusOptions = [
    { id: "", name: "All" },
    { id: "1", name: "Enabled" },
    { id: "0", name: "Disabled" },
  ];

  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[storageBoxModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }
    if (permissions.permissions[storageBoxModuleID].delete_access) {
      buttons.push(
        <DeleteButton
          key={`delete-${record?.id}`}
          onChangeHandler={() => handleDelete(record)}
          record={record}
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    // {
    //   perm_id: 0,
    //   title: "Index",
    //   dataIndex: "index",
    //   sorter: (a, b) => a.index - b.index,
    //   width: 100,
    // },
    {
      perm_id: fieldIds.storageBox.name,
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      perm_id: fieldIds.storageBox.sim_pacakges,
      title: "SIM Packages",
      dataIndex: "stored_packages",
      render: (stored_packages) => (
        <div
          style={{
            width: "100%",
            whiteSpace: "pre-wrap",
            listStyleType: "disc"
          }}
        >
     {stored_packages.length>0 ? (
      stored_packages.map((item, index) => (
      
          <li key={index}>{item}</li>
   
        
      ))
    ) : (
     "-"
    )}
        </div>
      ),
    
    },
    {
      perm_id: fieldIds.storageBox.status,
      title: "Status",
      dataIndex: "status",
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      width: 150,
      maxWidth: 150,
      buttons: actionButtons(),
      render: (_, record) => (
        <div
          className="right-aligned-text"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {actionButtons(record)}
        </div>
      ),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: storageBox?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        data={storageBox?.results}
        columns={columns}
        permissionSet={permissions.permissions[storageBoxModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        showColumnState={showColumnState}
      />
    );
  }

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />

      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> Storage Box
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[storageBoxModuleID].fields ||
                        []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={storageBoxModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged} 
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    {permissions?.permissions[storageBoxModuleID].fields[39]
                      ?.view_access && (
                      <DropdownFilter
                        title="Status"
                        selectedValue={selectedStatus}
                        items={statusOptions}
                        onSelect={handleStatusSelect}
                        filterName="Status"
                      />
                    )}

                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | Name"
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center top-table-buttons"
                  >
                 
                    {isAddAllowed(
                      permissions.permissions[storageBoxModuleID]
                    ) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-storage-box")}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StorageBoxTableComponent;
