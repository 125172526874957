import React from 'react'

import AddRoleView from 'views/UserRole/AddRole'

const AddRole = () => {
  return (
    <div><AddRoleView/></div>
  )
}

export default AddRole