// src/LoadingOverlay.js
import React from 'react';
import { Spin } from 'antd';
import './LoadingOverlay.css';

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="overlay">
      <Spin size="large" />
    </div>
  );
};

export default LoadingOverlay;
