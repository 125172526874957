import React, { useState } from "react";

import { Container, Form, Button, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import LoadingOverlay from "components/Overlay";
import { changePassword } from "API/authAPI";
import { getUserID } from "helpers/authHelper";
import useSuccessMessage from "customHooks/useSuccessMessage";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";

const ChangePasswordForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [showMessage, showSuccessMessage] = useSuccessMessage();
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [loadings, setLoading] = useState(false);

  const passwordChanger = useMutation(changePassword, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      
      reset();
      setLoading(false);
      showSuccessMessage();
    },
  });

  const onSubmit = async (data) => {
    setApiError(false);
    setPasswordsMatch(true);
   
    if (data.newPassword !== data.confirmPassword) {
      setPasswordsMatch(false);
      return;
    }
    const credentials = {
      current_password: data.currentPassword,
      password: data.newPassword,
      retype_password: data.confirmPassword,
    };
    try {
      const response = await passwordChanger.mutateAsync({
        id: getUserID(),
        credentials: credentials,
      });
    } catch (error) {
      if (error.response.status == 400) {
      setLoading(false);

        setApiError(error.response.data.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container
        fluid
        className="d-flex justify-content-center align-items-center login-form-conatiner bg-white"
      >
        <Col
          xs={12}
          sm={10}
          md={8}
          lg={8}
          xl={6}
          className="justify-content-center"
        >
          <Form
            className="p-4 border rounded shadow-sm user-fields-wrapper form-field"
            onSubmit={handleSubmit(onSubmit)}
          >
            <>
              <h2 className="mb-4 text-center login-title">Change Password</h2>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  <span className="label-text">Current Password</span>
                  <span className="error-message"> *</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  className="mb-1"
                  autoComplete="off"
                  {...register("currentPassword", {
                    required: true,
                    maxLength: 50,
                    minLength: 8,
                  })}
                />
                <span className="error-message">
                  {errors.currentPassword?.type === "required" &&
                    "Current Password is required."}
                  {errors.currentPassword?.type === "maxLength" &&
                    "It accepts a maximum of 50 characters."}
                  {errors.currentPassword?.type === "minLength" &&
                    "Enter a minimum of 8 characters."}
                  {errors.currentPassword && errors.currentPassword.message}
                </span>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  <span className="label-text">New Password</span>
                  <span className="error-message"> *</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  className="mb-1"
                  {...register("newPassword", {
                    required: true,
                    maxLength: 50,
                    minLength: 8,
                    pattern: {
                      value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                    },
                  })}
                />
                <span className="error-message">
                  {errors.newPassword?.type === "required" &&
                    "New Password is required."}
                  {errors.newPassword?.type === "maxLength" &&
                    "It accepts a maximum of 50 characters."}
                  {errors.newPassword?.type === "minLength" &&
                    "Enter a minimum of 8 characters."}
                  {errors.newPassword?.type === "pattern" &&
                    "The password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character like (#?!@$%^&*-) and it must be at least 8 characters long."}
                  {errors.newPassword && errors.newPassword.message}
                </span>
              </Form.Group>
              <Form.Group controlId="formBasicPassword">
                <Form.Label>
                  <span className="label-text">Retype New Password</span>
                  <span className="error-message"> *</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  className="mb-1"
                  {...register("confirmPassword", {
                    required: true,
                  })}
                />
                <span className="error-message">
                  {errors.confirmPassword?.type === "required" &&
                    "Please re-enter your password to confirm."}
                  {errors.confirmPassword && errors.confirmPassword.message}
                  {!passwordsMatch && "Passwords do not match."}
                </span>
              </Form.Group>
              {showMessage && (
                <FormAlertMessage message="Password Updated." type="success" />
              )}
              {displayServerMessage && (
                <FormAlertMessage message={serverErrorMessage} type="error" />
              )}
              {apiError && <FormAlertMessage message={apiError} type="error" />}
              <Button
                variant="primary"
                type="submit"
                block="true"
                className="mt-3 mb-4 add-user-button w-full"
              >
                Update Password
              </Button>
            </>
          </Form>
        </Col>
      </Container>
    </div>
  );
};

export default ChangePasswordForm;
