import React from 'react'
import ServicePartner from 'pages/ServicePartner/ServicePartner'
import ServicePartnerTableComponent from 'components/Table/ServicePartnerTableComponent'

const ServicePartnerView = () => {
  return (
    <div><ServicePartnerTableComponent/></div>
  )
}

export default ServicePartnerView