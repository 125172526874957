import React from "react";
import ModemDashboardView from "views/ModemDashboard/ModemDashboardView";

const ModemDashboard = () => {
  return (
    <div>
      <ModemDashboardView />
    </div>
  );
};

export default ModemDashboard;
