import { makeRequest } from "helpers/apiHelper";

export const getSettingsList = async () => {
  const url = `/adminsetting/admin-setting/`;
  return makeRequest("get", url);
};

export const editAdminSettings = async ({ data }) => {
  return makeRequest("post", `/adminsetting/admin-setting/`, data);
};
