import React from "react";
import AddModemLog from "components/ModemDashboard/AddModemLog";

const AddModemLogView = () => {
  return (
    <div>
      <AddModemLog />
    </div>
  );
};

export default AddModemLogView;
