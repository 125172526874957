import React from 'react'

import SendAgainForm from 'components/ResetPassword/SendAgainForm'

const SendAgainView = () => {
  return (
    <div>
        <SendAgainForm/>
    </div>
  )
}

export default SendAgainView