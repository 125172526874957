import React from 'react'
import { Button } from "react-bootstrap";
import { Tooltip } from 'antd';
const FinishButton = ({ onClickHandler,name,icon,record,partner }) => {
  return (
    <div key={`reset-${record?.index}`}>
    <Tooltip title={name}>
      <Button
        key={`reset-${record?.id}`}
        variant="primary"
        block="true"
        className="mt-3 mb-4 btn btn-three edit reset"
        onClick={onClickHandler}
        disabled={record?.is_finished || partner?.status=="Pending for start"}
      >
        <i className={icon}></i>{record?.is_finished?"Finished":"Finish"||partner?.status=="Pending for stop"}
      </Button>
    </Tooltip>
  </div>
  )
}

export default FinishButton
