import React from "react";
import AddCCMListForm from "components/CustomCurrencyModule";
const AddCCMList = () => {
  return (
    <div>
      <AddCCMListForm />
    </div>
  );
};

export default AddCCMList;
