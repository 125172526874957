import React from "react";
import { Alert } from "antd";

const FormAlertMessage = ({ message,type, className = "alert-message" }) => {
  return (
    <div className={className}>
      <Alert message={message} type={type} showIcon />
    </div>
  );
};

export default FormAlertMessage;