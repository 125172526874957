import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const CancelButton = ({ onClickHandler }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Go back one step in the navigation stack
  };

  return (
    <Button
      className="btn pull-right  ms-2 mx-2 reset-user-button"
      type="button"
      variant="info"
      onClick={onClickHandler?onClickHandler:goBack}
    >
      Cancel
    </Button>
  );
};

export default CancelButton;
