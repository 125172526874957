import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import LoadingOverlay from "components/Overlay";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "./AddStorageBoxForm.css";
import { Breadcrumbs } from "Breadcrumb";
import { createStorageBox, editStorageBox } from "API/storageBoxApi";
import { fieldIds } from "constants/moduleFields";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
const AddStorageBoxForm = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [loadings, setLoading] = useState(false);

  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selectedStatus, setSelectedStatus] = useState("");
  const storageBoxModuleID = parseInt(
    process.env.REACT_APP_STORAGE_BOX_MODULE_ID,
    10
  );
  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const statusOptions = [
    { id: "1", name: "Enable" },
    { id: "0", name: "Disable" },
  ];
  const handleStatusSelect = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
  };

  useEffect(() => {
    if (location.state) {
      const storageBoxData = location.state.data;

      const defaultStatusIndex = storageBoxData.status ? "0" : "1";

      setValue("name", storageBoxData.name);
      setSelectedStatus(statusOptions[defaultStatusIndex]);
      setValue("status", statusOptions[defaultStatusIndex]);

      setIsEdit(true);
    } else {
      setSelectedStatus(statusOptions[0]);
      setValue("status", statusOptions[0]);
    }
  }, [location.state]);

  const storageBoxCreator = useMutation(createStorageBox, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/storage-box");
      await showToast({
        icon: "success",
        title: "Storage Box Added Successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const storageBoxEditor = useMutation(editStorageBox, {
    onMutate: () => {
      setLoading(true);
    },

    onSuccess: async (response) => {
      setLoading(false);

      navigate("/storage-box");
      await showToast({
        icon: "success",
        title: "Storage Box Edited Successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleReset = () => {
    // Reset the form fields
    reset();
  };

  const onSubmit = async (data) => {
    setApiError(false);
    const modulePermission = permissions?.permissions[storageBoxModuleID];
    const formData = new FormData();

    isFieldAddableOrEditable(fieldIds.storageBox.name, modulePermission) &&
      data.name &&
      formData.append("name", data.name);

    isFieldAddableOrEditable(fieldIds.storageBox.status, modulePermission) &&
      selectedStatus &&
      formData.append("status", selectedStatus ? selectedStatus.id : "");

    if (isEdit) {
    } else {
    }

    try {
      let response;
      if (isEdit) {
        response = await storageBoxEditor.mutateAsync({
          id: location.state.data.id,
          data: formData,
        });
      } else {
        response = await storageBoxCreator.mutateAsync(formData);
      }
    } catch (error) {
      if (error.response.status == 400) {
      setLoading(false);

        setApiError(error.response.data.message);
      } else {
      setLoading(false);

        showServerMessage();
      }
    }
  };

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">
                  {isEdit ? "Edit" : "Add"} storage box
                </Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          {isFieldAddableOrEditable(
                            fieldIds.storageBox.name,
                            permissions?.permissions[storageBoxModuleID]
                          ) && (
                            <Col className="" md="6">
                              <Form.Group>
                                <label title="Name">
                                  <span className="label-text">Name</span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("name", {
                                    required: true,
                                    maxLength: 50,
                                    minLength: 3,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.name?.type === "required" &&
                                    "Name is required."}
                                  {errors.name?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.name?.type === "minLength" &&
                                    "Enter minimum 3 characters."}
                                  {errors.name && errors.name.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            fieldIds.storageBox.status,
                            permissions?.permissions[storageBoxModuleID]
                          ) && (
                            <Col className="" md="6">
                              <CustomDrodown
                                label="Status"
                                selectedOption={selectedStatus}
                                options={statusOptions}
                                handleSelect={handleStatusSelect}
                                control={control}
                                errors={errors}
                                name="status"
                                rules={{ required: "Select status." }}
                              />
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}

                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right  add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right  mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddStorageBoxForm;
