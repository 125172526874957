import React from "react";
import SettingForm from "components/AdminSetting/SettingForm";

const AdminSettingsView = () => {
  return (
    <div>
      <SettingForm />
    </div>
  );
};

export default AdminSettingsView;
