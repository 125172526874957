import React, { useState, useEffect } from "react";

//react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Dropdown,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import LoadingOverlay from "components/Overlay";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import RolePermissionTable from "components/Table/RolePermissionTable";
import { permissionsDesign } from "./data";
import { retrieveRolePermissions } from "API/userRoleAPI";
import { editRole } from "API/userRoleAPI";
import { customNames } from "./data";
import CancelButton from "components/CancelButton/CancelButton";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { Breadcrumbs } from "Breadcrumb";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import { cacheDuration } from "helpers/apiHelper";
import showToast from "components/Toast/Toast";

const EditRoleForm = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [modulePermissions, setModulePermissions] = useState({});
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [loadings, setLoading] = useState(false);

  const [apiError, setApiError] = useState(false);
  const userRoleModuleID = parseInt(
    process.env.REACT_APP_USERROLE_MODULE_ID,
    10
  );

  const {
    isLoading,
    isError,
    error,
    data: rolePermission,
    refetch,
  } = location.state
    ? useQuery(["users", location.state?.data?.id], retrieveRolePermissions, {
        cacheTime: cacheDuration,
      })
    : {
        isLoading: false,
        isError: false,
        error: null,
        data: null,
        refetch: () => {},
      };

  useEffect(() => {
    if (location.state) {
      const roleData = location.state.data;
      setValue("Name", roleData.name);
    }
    if (!isLoading && !isError) {
      setModulePermissions({ ...rolePermission.permissions });
    }
  }, [location.state, rolePermission]);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const roleEditor = useMutation(editRole, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/user-role");
      await showToast({
        icon: "success",
        title: "User role edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const onSubmit = async (data) => {
    setApiError(false);
    const dataObject = {};
    if (
      isFieldAddableOrEditable("11", permissions?.permissions[userRoleModuleID])
    ) {
      dataObject.name = data.Name;
    }
    if (
      isFieldAddableOrEditable("13", permissions?.permissions[userRoleModuleID])
    ) {
      dataObject.permissions = modulePermissions;
    }

    try {
      const response = await roleEditor.mutateAsync({
        id: location.state.data.id,
        permissions: dataObject,
      });
    } catch (error) {
      const bottomElement = document.getElementById("bottom-element-id");
      if (error.response.status == 400) {
      setLoading(false);

        setApiError(error.response.data.message);
        bottomElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      } else {
        setLoading(false);
        showServerMessage();
        bottomElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  };

  const updateModulePermissions = (moduleId, updatedModulePermissions) => {
    // Create a copy of the current modulePermissions to update
    const updatedPermissions = { ...modulePermissions };

    // Update the module permissions with the provided data
    updatedPermissions[moduleId] = updatedModulePermissions;

    // Return the updated permissions
    return updatedPermissions;
  };

  const handlePermissionsChange = (newPermissions) => {
    // Find the module ID based on the updated permissions' name
    const moduleId = Object.keys(modulePermissions).find(
      (moduleId) => modulePermissions[moduleId].name === newPermissions.name
    );

    if (moduleId) {
      // Call the updateModulePermissions function to update the specific module permissions
      const updatedPermissions = updateModulePermissions(
        moduleId,
        newPermissions
      );

      // Update the state with the updated permissions

      setModulePermissions(updatedPermissions);
    }
  };

  let serialNumber = 1;

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">User role</Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>

            <Card>
              <Card.Header>
                <Card.Title as="h4" className="section-title-userform">
                  Edit role
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {!permissionsLoading && !isLoading ? (
                  <Form id="userRoleForm" onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      {isFieldAddableOrEditable(
                        "11",
                        permissions?.permissions[userRoleModuleID]
                      ) && (
                        <Col className="pr-1" md="4">
                          <Form.Group>
                            <label title="Role name">
                              <span className="label-text">Role name</span>
                              <span className="error-message"> *</span>
                            </label>

                            <Form.Control
                              defaultValue=""
                              placeholder="(eg., Admin, Employee)"
                              type="text"
                              {...register("Name", {
                                required: true,
                                maxLength: 50,
                                minLength: 2,
                              })}
                            ></Form.Control>
                            <span className="error-message">
                              {errors.Name?.type === "required" &&
                                "Role name is required."}
                              {errors.Name?.type === "maxLength" &&
                                "It accepts maximum 50 characters."}
                              {errors.Name?.type === "minLength" &&
                                "Enter minimum 2 characters."}
                              {errors.Name && errors.Name.message}
                            </span>
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      {isFieldAddableOrEditable(
                        "13",
                        permissions?.permissions[userRoleModuleID]
                      ) &&
                        rolePermission.permissions && (
                          <Col className="pr-1" md="12">
                            <h3>Permission management</h3>

                            {Object.entries(rolePermission.permissions).map(
                              ([key, modulePermissions], index) => (
                                <RolePermissionTable
                                  key={index}
                                  modulePermissions={modulePermissions}
                                  customFieldNames={customNames[key]}
                                  onPermissionsChange={handlePermissionsChange}
                                  serialNumber={serialNumber++}
                                />
                              )
                            )}
                          </Col>
                        )}
                    </Row>
                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                    </Col>)}
                    <div className="mt-3">
                      <Button
                        className="btn-fill pull-right add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      <CancelButton />
                    </div>

                    <div className="clearfix" id="bottom-element-id"></div>
                  </Form>
                ) : (
                  <div className="centered-container">Loading....</div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EditRoleForm;
