import React from "react";
import ExpirationListView from "views/ExpirationList/ExpirationListView";
const ExpirationList = () => {
  return (
    <div>
      <ExpirationListView />
    </div>
  );
};

export default ExpirationList;
