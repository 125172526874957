import React, {useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { cacheDuration } from "helpers/apiHelper";
import {

  getStorageBoxes,

} from "API/modemDashApi";

import RecommendedDropdown from "components/CustomDropdown/RecommendedDropdown";


const StorageAftUseDropdown = ({
  packageIdForStorage,
  selectedAftUse,
  packageItem,
  isEdit,
  storageOptions,
  setStorageOptions,
  setSelectedAftUse,
  currentPackage
}) => {
  const {
    control,
    formState: { errors },

  } = useForm();



  const {
    isLoading: isLoadingStorageBoxes,
    isError: isErrorStorageBoxes,
    error: errorStorageBoxes,
    data: allStorageBoxes,
    refetch: storageBoxRefetch,
  } = useQuery(["storageboxes", packageIdForStorage?.[`package${packageItem?.id}`]], getStorageBoxes, {
    cacheTime: cacheDuration,
    enabled: packageIdForStorage?.[`package${packageItem?.id}`] ? true : false,
  });


  useEffect(() => {
    const storegeNotInUse = allStorageBoxes?.storege_not_in_use || [];
    const recomandedStoregeNotInUse =
      allStorageBoxes?.recomanded_storege_not_in_use || [];

    // Merge the two arrays into a single array
    const mergedArray = [...storegeNotInUse, ...recomandedStoregeNotInUse];

    const sortedArray = mergedArray.sort((a, b) => {
      const aRecomanded = a.recomanded || false;
      const bRecomanded = b.recomanded || false;

      return bRecomanded - aRecomanded;
    });

    // Create a set to keep track of unique IDs
    const uniqueIds = new Set();

    // Filter out duplicates based on ID
    const filteredArray = sortedArray.filter((item) => {
      if (uniqueIds.has(item.id)) {
        return false; // Skip duplicate
      }
      uniqueIds.add(item.id);
      return true;
    });

    setStorageOptions((prevFields) => ({
      ...prevFields,
      [`storageOption${packageItem.id}`]: [...filteredArray],
    }));
  }, [allStorageBoxes]);


  useEffect(() => {
    // console.log(storageOptions?.[`storageOption${packageItem?.id}`],"found",packageItem?.id);
  }, [storageOptions]);
  const handleStorageAftUseChange = (fieldName, value) => {

    setSelectedAftUse((prevFields) => ({
      ...prevFields,
      [fieldName]: value,
    }));
  };

  return (
    <div>
      {(

        <RecommendedDropdown
          label={`Storage after use`}
          selectedOption={
            selectedAftUse || []
          }
          options={
            isEdit && packageItem.logPackageId
              ? selectedAftUse?.length != 0 && storageOptions?.[`storageOption${packageItem.id}`] && !storageOptions?.[`storageOption${packageItem.id}`]?.includes(selectedAftUse)
                ? [
                  ...storageOptions?.[`storageOption${packageItem.id}`],
                  selectedAftUse?.[`storageAftUse${packageItem?.id}`],
                ]
                : storageOptions?.[`storageOption${packageItem.id}`] || []
              : storageOptions?.[`storageOption${packageItem.id}`] || []
          }
          handleSelect={(e) =>
            handleStorageAftUseChange(`storageAftUse${packageItem?.id}`, e)
          }
          control={control}
          errors={errors}
          name={`storageAftUse${packageItem?.id}`}
          rules={{
            required: "Storage after use is required.",
          }}
        />
      )}
    </div>
  );
};

export default StorageAftUseDropdown;
