export const isFieldAddableOrEditable = (fieldId, userPermissions) => {
  const fieldPermission = userPermissions.fields[fieldId];
  return fieldPermission?.is_add_editable && fieldPermission?.add_edit_access;
};

export const allColumnShowTrue = (module, userPermissions) => {
  return Object.values(
    userPermissions.permissions[module].fields
  ).every(
    (value) => value.column_show === true || value.is_column_visible === false
  );
};

export const canAccessModule = (route, userPermissions) => {
  if (route.moduleId === 0) {
    return true; // No permission check needed, allow access
  }
  const modulePermission = userPermissions.permissions[route.moduleId];
  if (modulePermission) {
    switch (route.accessType) {
      case "view":
        return modulePermission.view_access;
      case "add":
        return modulePermission.add_access;
      case "edit":
        return modulePermission.edit_access;
      // Add more cases for other access types if needed
      default:
        return false; // Default to false if accessType is not recognized
    }
  }

  return false;
  // return modulePermission && modulePermission.view_access;
};

export const isAddAllowed = (permissionSet) => {
  const { is_addable, add_access } = permissionSet;
  return is_addable && add_access;
};
