import React from "react";
import { Navbar, Container} from "react-bootstrap";


function OuterNavbar() {
  return (
    <Navbar className="outer-navbar" expand="lg">
      <Container fluid className="justify-content-center">
        <div className="logo-img mr-2">
          <img
            src={require("assets/img/Color logo with background.png")}
            className="outer-logo"
            alt="..."
          />
        </div>
        <div></div>
      </Container>
    </Navbar>
  );
}

export default OuterNavbar;
