import { getPermissions } from "API/userRoleAPI";
import { getAccessToken } from "./tokenManager";

const userRoleModuleID = parseInt(process.env.REACT_APP_USERROLE_MODULE_ID, 10);
const dashboardModuleID = parseInt(
  process.env.REACT_APP_DASHBOARD_MODULE_ID,
  10
);
const registrationModuleID = parseInt(
  process.env.REACT_APP_REGISTRATION_MODULE_ID,
  10
);
const supplierModuleID = parseInt(
  process.env.REACT_APP_SUPPLIER_MODULE_ID,
  10
);
const storageBoxModuleID = parseInt(
  process.env.REACT_APP_STORAGE_BOX_MODULE_ID,
  10
);
const servicePartnerModuleID = parseInt(
  process.env.REACT_APP_SERVICE_PARTNER_MODULE_ID,
  10
);
const networkProviderModuleID = parseInt(
  process.env.REACT_APP_NETWORK_PROVIDER_MODULE_ID,
  10
);
const modemListModuleID = parseInt(
  process.env.REACT_APP_MODEM_LIST_MODULE_ID,
  10
);
const pcModemModuleID = parseInt(
  process.env.REACT_APP_PC_MODEM_MODULE_ID,
  10
);
const simPackageModuleID = parseInt(
  process.env.REACT_APP_SIM_PACKAGE_MODULE_ID,
  10
);


export const loginRedirector = async () => {
  if(!getAccessToken()){
    return "/login"
  }
  const response = await getPermissions();
  const hasDashboardPermission = response.permissions[dashboardModuleID]?.view_access;

  if (!hasDashboardPermission) {
    // Define the order of pages based on your application logic
    const pageOrder = [
      { pageId: registrationModuleID, route: "/registration" },
      { pageId: userRoleModuleID, route: "/user-role" },
      { pageId: supplierModuleID, route: "/supplier" },
      { pageId: storageBoxModuleID, route: "/storage-box" },
      { pageId: servicePartnerModuleID, route: "/service-partner" },
      { pageId: networkProviderModuleID, route: "/network-provider" },
      { pageId: modemListModuleID, route: "/modem-list" },
      { pageId: pcModemModuleID, route: "/pc-modem" },
      { pageId: simPackageModuleID, route: "/sim-package" },
    ];

    // Find the first page in the order that the user has permission for
    const nextPermissionPage = pageOrder.find(
      (page) => response.permissions[page.pageId]?.view_access
    );

    if (nextPermissionPage) {
      const route = nextPermissionPage.route;
      // Redirect to the next available permission-based page

      return route;
    } else {
      // If no permission-based pages are available, redirect to a default page
      return "/404";
    }
  }
  return "/dashboard"
};
