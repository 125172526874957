import { createSlice } from "@reduxjs/toolkit";

const userSlice=createSlice({
    name:"userEdit",
    initialState:[],
    reducers:{
        editUserDetails(state,action){
            state.push(action.payload)
        },
    }
})

export {userSlice}
export const {editUserDetails}=userSlice.actions
