import React from "react";

import { Navigate } from "react-router-dom";

import { isAuthenticated } from "helpers/authHelper";


const PublicRoutes = ({ children }) => {
  let auth = isAuthenticated();
  return !auth ? children : <Navigate to="/" />;
};

export default PublicRoutes;
