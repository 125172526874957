import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import LoadingOverlay from "components/Overlay";
import ImageInput from "components/ImageInput/ImageInput";
import { createUsers } from "API/registerApi";
import { editUsers, getSingleUserData } from "API/registerApi";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import { convertDate } from "helpers/dateValidator";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import { getRoles } from "API/registerApi";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { DatePicker } from "antd";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "./AddUserForm.css";
import { Breadcrumbs } from "Breadcrumb";
import { cacheDuration } from "helpers/apiHelper";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
import { handleKeyPress } from "helpers/numberValidator";

const AddUserForm = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const [selectedRole, setSelectedRole] = useState(null);
  const [dob, setDob] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [newImageSelected, setNewImageSelected] = useState(null);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [imageRemoval, setImageRemoval] = useState();
  const [loadings, setLoading] = useState(false);

  const registrationModuleID = parseInt(
    process.env.REACT_APP_REGISTRATION_MODULE_ID,
    10
  );
  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingRoles,
    isError: isErrorRoles,
    error: rolesError,
    data: roles,
  } = useQuery("roles", getRoles, {
    cacheTime: cacheDuration,
  });



  useEffect(() => {
    if (location.state && roles) {
      const userData = location.state.data;

      const val =
        roles["Active"].find((role) => {
          return role.name == userData.role_name;
        }) ||
        roles["Deactivated"].find((role) => {
          return role.name == userData.role_name;
        });

      setValue("email", userData.email);
      setValue("address", userData.address);
      setValue("firstName", userData.first_name);
      setValue("lastName", userData.last_name);
      setValue("dob", dayjs(userData.date_of_birth));
      setValue("phoneNumber", userData.phone_number);
      setSelectedImage(userData.user_image);
      setSelectedRole(val);
      setValue("role", val.id);
      setDob(userData.date_of_birth);
      setIsEdit(true);
      setImageRemoval(0);
    }
  }, [isLoadingRoles]);

  const {
    isLoading: isLoadingEditUserData,
    isError: isErrorEditUserData,
    error: errorEditUserData,
    data: editditUserData,
    refetch: refetchEditUserData,
  } = useQuery(
    ["editUserData", location?.state?.data?.id],
    getSingleUserData,
    {
      cacheTime: cacheDuration,
      enabled: location?.state?.data?.id ? true : false,
    }
  );

  // set user image 
  useEffect(() => {
    if (location.state){
      console.log("🚀 ~ useEffect ~ location.state:", location.state)
      console.log("🚀 ~ setTimeout ~ editditUserData:", editditUserData)
      setTimeout(()=>{
        console.log("🚀 ~ setTimeout ~ editditUserData in:", editditUserData)
        if (editditUserData) {
          console.log("🚀 ~ setTimeout ~ editditUserData.user_image:", editditUserData.user_image)
          setSelectedImage(editditUserData.user_image);
        }
      },500)
    }
  }, [editditUserData]);
      

  const userCreator = useMutation(createUsers, {
    onMutate: () => {
      setLoading(true);
    },

    onSuccess: async (response) => {
      setLoading(false);
      navigate("/registration");

      await showToast({
        icon: "success",
        title: "User registered successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const userEditor = useMutation(editUsers, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/registration");
      await showToast({
        icon: "success",
        title: "User edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleImageSelect = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile) {
      setSelectedImage(URL.createObjectURL(imageFile));
      setNewImageSelected(imageFile);
    }
  };
  const handleImageRemove = (event) => {
    setSelectedImage();
    setImageRemoval(1);
  };

  const roleOptions = [
    { value: "2", label: "Admin" },
    { value: "3", label: "Employee" },
    { value: "4", label: "Supplier" },
  ];
  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };
  const handleReset = () => {
    // Reset the form fields
    setSelectedImage();
    setSelectedRole(null);
    reset();
  };
  // Function to disable future dates
  const disabledDate = (current) => {
    return current && current.isAfter(dayjs().endOf("day"));
  };

  const onSubmit = async (data) => {
    setApiError(false);
    const modulePermission = permissions?.permissions[registrationModuleID];
    const formData = new FormData();

    isFieldAddableOrEditable("1", modulePermission) &&
      data.firstName &&
      formData.append("first_name", data.firstName);
    isFieldAddableOrEditable("2", modulePermission) &&
      data.lastName &&
      formData.append("last_name", data.lastName);
    isFieldAddableOrEditable("7", modulePermission) &&
      formData.append("address", data.address ? data.address : "");
    isFieldAddableOrEditable("6", modulePermission) &&
      formData.append(
        "phone_number",
        data?.phoneNumber ? data.phoneNumber : ""
      );
    isFieldAddableOrEditable("4", modulePermission) &&
      selectedRole &&
      formData.append("role_id", selectedRole.id);

    isFieldAddableOrEditable("8", modulePermission) &&
      data.dob &&
      formData.append("date_of_birth", convertDate(data.dob));
    if (isEdit) {
      formData.append("image_remove", imageRemoval == 1 ? 1 : 0);
      isFieldAddableOrEditable("5", modulePermission) &&
        newImageSelected &&
        formData.append("user_image", newImageSelected ? newImageSelected : "");
    } else {
      isFieldAddableOrEditable("3", modulePermission) &&
        data.email &&
        formData.append("email", data.email);
      isFieldAddableOrEditable("5", modulePermission) &&
        formData.append("user_image", newImageSelected ? newImageSelected : "");
      setPasswordsMatch(true);
      if (data.password !== data.confirmPassword) {
        setPasswordsMatch(false);
        return;
      }
      isFieldAddableOrEditable("9", modulePermission) &&
        data.password &&
        formData.append("password", data.password);
      isFieldAddableOrEditable("9", modulePermission) &&
        data.confirmPassword &&
        formData.append("retype_password", data.confirmPassword);
    }

    try {
      let response;
      if (isEdit) {
        response = await userEditor.mutateAsync({
          id: location.state.data.id,
          data: formData,
        });
      } else {
        response = await userCreator.mutateAsync(formData);
      }
    } catch (error) {
      setLoading(false);

      if (error?.response?.status == 400) {
        setApiError(error.response.data.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">{isEdit ? "Edit" : "Add"} user</Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
   
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          {isFieldAddableOrEditable(
                            "5",
                            permissions?.permissions[registrationModuleID]
                          ) && (
                            <Col className="" md="4">
                              <ImageInput
                                selectedImage={selectedImage}
                                handleImageSelect={handleImageSelect}
                                handleImageRemove={handleImageRemove}
                              />
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            "1",
                            permissions?.permissions[registrationModuleID]
                          ) && (
                            <Col className="form-field" md="4">
                              <Form.Group>
                                <label title="First name">
                                  <span className="label-text">First name</span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("firstName", {
                                    required: true,
                                    maxLength: 50,
                                    minLength: 3,
                                    pattern: {
                                      value: /^[A-Za-z0-9\s]+$/,
                                    },
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.firstName?.type === "required" &&
                                    "First name is required."}
                                  {errors.firstName?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.firstName?.type === "minLength" &&
                                    "Enter minimum 3 characters."}
                                  {errors.firstName?.type === "pattern" &&
                                    "Special characters are not allowed."}
                                  {errors.firstName && errors.firstName.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            "2",
                            permissions?.permissions[registrationModuleID]
                          ) && (
                            <Col className="form-field" md="4">
                              <Form.Group>
                                <label title="Last name">
                                  <span className="label-text">Last name</span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="text"
                                  {...register("lastName", {
                                    required: true,
                                    maxLength: 50,
                                    minLength: 3,
                                    pattern: {
                                      value: /^[A-Za-z0-9\s\s]+$/,
                                    },
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.lastName?.type === "required" &&
                                    "Last name is required."}
                                  {errors.lastName?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.lastName?.type === "minLength" &&
                                    "Enter minimum 3 characters."}
                                  {errors.lastName?.type === "pattern" &&
                                    "Special characters are not allowed."}
                                  {errors.lastName && errors.lastName.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            "3",
                            permissions?.permissions[registrationModuleID]
                          ) && (
                            <Col className="form-field" md="4">
                              <Form.Group>
                                <label title="Email">
                                  <span className="label-text">Email</span>
                                  <span className="error-message"> *</span>
                                </label>

                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="email"
                                  disabled={isEdit}
                                  autoComplete="new-email"
                                  {...register("email", {
                                    required: true,
                                    maxLength: 100,
                                    pattern: {
                                      value:
                                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                                    },
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.email?.type === "required" &&
                                    "Email is required."}
                                  {errors.email?.type === "maxLength" &&
                                    "It accepts maximum 50 characters."}
                                  {errors.email?.type === "pattern" &&
                                    "Enter a valid email."}
                                  {errors.email && errors.email.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            "9",
                            permissions?.permissions[registrationModuleID]
                          ) &&
                            !isEdit && (
                              <Col className="form-field" md="4">
                                <Form.Group>
                                  <label title="Password">
                                    <span className="label-text">Password</span>
                                    <span className="error-message"> *</span>
                                  </label>

                                  <Form.Control
                                    defaultValue=""
                                    placeholder=""
                                    type="password"
                                    autoComplete="new-password"
                                    {...register("password", {
                                      required: true,
                                      maxLength: 50,
                                      minLength: 8,
                                      pattern: {
                                        value:
                                          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                                      },
                                    })}
                                  />
                                  <span className="error-message">
                                    {errors.password?.type === "required" &&
                                      "Password is required."}
                                    {errors.password?.type === "maxLength" &&
                                      "It accepts a maximum of 50 characters."}
                                    {errors.password?.type === "minLength" &&
                                      "Enter a minimum of 8 characters."}
                                    {errors.password?.type === "pattern" &&
                                      "The password must contain at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character like (#?!@$%^&*-) and it must be at least 8 characters long."}
                                  </span>
                                </Form.Group>
                              </Col>
                            )}

                          {isFieldAddableOrEditable(
                            "9",
                            permissions?.permissions[registrationModuleID]
                          ) &&
                            !isEdit && (
                              <Col className="form-field" md="4">
                                <Form.Group>
                                  <label title="Confirm password">
                                    <span className="label-text">
                                      Confirm password
                                    </span>
                                    <span className="error-message"> *</span>
                                  </label>

                                  <Form.Control
                                    defaultValue=""
                                    placeholder=""
                                    type="password"
                                    {...register("confirmPassword", {
                                      required: true,
                                    })}
                                  ></Form.Control>
                                  <span className="error-message">
                                    {errors.confirmPassword?.type ===
                                      "required" &&
                                      "Please re-enter your password to confirm."}
                                    {!passwordsMatch &&
                                      "Passwords do not match."}
                                  </span>
                                </Form.Group>
                              </Col>
                            )}

                          {isFieldAddableOrEditable(
                            "6",
                            permissions?.permissions[registrationModuleID]
                          ) && (
                            <Col className="form-field" md="4">
                              <Form.Group>
                                <label title="Phone number">Phone number</label>
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  type="number"
                                  onKeyPress={handleKeyPress}
                                  {...register("phoneNumber", {
                                    maxLength: 20,
                                    minLength: 10,
                                    pattern: {
                                      value: /^[0-9]+$/,
                                    },
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.phoneNumber?.type === "pattern" &&
                                    "Please enter a valid number."}
                                  {errors.phoneNumber?.type === "maxLength" &&
                                    "It accepts maximum 20 characters."}
                                  {errors.phoneNumber?.type === "minLength" &&
                                    "Enter minimum 10 characters."}
                                  {errors.phoneNumber &&
                                    errors.phoneNumber.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}

                          {isFieldAddableOrEditable(
                            "4",
                            permissions?.permissions[registrationModuleID]
                          ) &&
                            roles && (
                              <Col className="form-field" md="4">
                                <CustomDrodown
                                  label="Role"
                                  selectedOption={selectedRole}
                                  options={
                                    isEdit
                                      ? roles.Active.filter(
                                          (role) =>
                                            role.name ==
                                            location.state.data.role_name
                                        ).length > 0
                                        ? roles.Active
                                        : [
                                            roles.Deactivated.filter((role) => {
                                              return (
                                                role.name ==
                                                location.state.data.role_name
                                              );
                                            })[0],
                                            ...roles.Active,
                                          ]
                                      : roles.Active
                                  }
                                  handleSelect={handleRoleSelect}
                                  control={control}
                                  errors={errors}
                                  name="role"
                                  rules={{ required: "Role is required." }}
                                />
                              </Col>
                            )}

                          {isFieldAddableOrEditable(
                            "8",
                            permissions?.permissions[registrationModuleID]
                          ) && (
                            <Col className="" md="4">
                              <Form.Group>
                                <label title="Date of birth">
                                  <span className="label-text">
                                    Date of birth
                                  </span>
                                </label>

                                <br />
                                <Controller
                                  name="dob" // Field name in the form data
                                  control={control}
                                  defaultValue={dob}
                                  render={({ field }) => (
                                    <DatePicker
                                      value={field.value}
                                      onChange={(date) => field.onChange(date)}
                                      disabledDate={disabledDate}
                                      format="DD/MM/YYYY"
                                      allowClear={true}
                                    />
                                  )}
                                  rules={{
                                    required: "Date of birth is required.",
                                  }} // Validation rules
                                />
                                <br />
                                {errors.dob && (
                                  <span className="error-message">
                                    {errors.dob.message}
                                  </span>
                                )}
                              </Form.Group>
                            </Col>
                          )}
                          {isFieldAddableOrEditable(
                            "7",
                            permissions?.permissions[registrationModuleID]
                          ) && (
                            <Col className="" md="12">
                              <Form.Group>
                                <label title="Address">Address</label>{" "}
                                <Form.Control
                                  defaultValue=""
                                  placeholder=""
                                  as="textarea"
                                  rows={5}
                                  {...register("address", {
                                    maxLength: 200,
                                    minLength: 3,
                                  })}
                                ></Form.Control>
                                <span className="error-message">
                                  {errors.address?.type === "maxLength" &&
                                    "It accepts maximum 200 characters."}
                                  {errors.address?.type === "minLength" &&
                                    "Enter minimum 3 characters."}
                                  {errors.address && errors.address.message}
                                </span>
                              </Form.Group>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}

                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddUserForm;
