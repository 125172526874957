import { makeRequest } from "helpers/apiHelper";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const headers = {
  "Content-Type": "multipart/form-data",
};

export const getUsers = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/registration/user/?${queryParamsString}`;
  return makeRequest("get", url);
};

export const createUsers = async (data) => {
  return makeRequest("post", "/registration/user/", data, headers);
};

export const editUsers = async ({ id, data }) => {
  return makeRequest("patch", `/registration/user/${id}/`, data, headers);
};

export const resetTfa = async ({ id }) => {
  return makeRequest("patch", `/registration/reset-tfa/${id}/`);
};

export const getRoles = async () => {
  const url = `/registration/user-role-list`;
  return makeRequest("get", url);
};

export const getSingleUserData = async (params) => {
  const url = `/registration/user/${params.queryKey[1]}/`;
  return makeRequest("get", url);
};