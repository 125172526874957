import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import "styles/tailwind.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
<script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>;

import InnerLayout from "layouts/InnerLayout";
import Login from "pages/Login/Login";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import ForgotPassword from "pages/ResetPassword/ForgotPassword";
import SendAgain from "pages/ResetPassword/SendAgain";
import OuterLayout from "layouts/OuterLayout";
import Page404 from "pages/Page404/Page404";
import store from "./store";
import PublicRoutes from "PublicRoutes";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();
root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<InnerLayout />} />
          <Route
            path="/login/*"
            element={
              <OuterLayout>
                <PublicRoutes>
                  <Login />
                </PublicRoutes>
              </OuterLayout>
            }
          />
          <Route
            path="/forgot-password/"
            element={
              <OuterLayout>
                <PublicRoutes>
                  <ForgotPassword />
                </PublicRoutes>
              </OuterLayout>
            }
          />
          <Route
            path="/reset-password/"
            element={
              <OuterLayout>
                <PublicRoutes>
                  <ResetPassword />
                </PublicRoutes>
              </OuterLayout>
            }
          />
          <Route
            path="/send-again/"
            element={
              <OuterLayout>
                <PublicRoutes>
                  <SendAgain />
                </PublicRoutes>
              </OuterLayout>
            }
          />
          <Route
            path="/404"
            element={
              <OuterLayout>
                <Page404 />
              </OuterLayout>
            }
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);
