import React from "react";
import AddStorageBoxView from "views/StorageBox/AddStorageBoxView";
const AddStorageBox = () => {
  return (
    <div>
      <AddStorageBoxView />
    </div>
  );
};

export default AddStorageBox;
