import React from "react";
import AddPCModemForm from "components/PCModem/AddPCModemForm";
const AddPCModemView = () => {
  return (
    <div>
      <AddPCModemForm />
    </div>
  );
};

export default AddPCModemView;
