import React from "react";
import AddSimPackageForm from "components/SimPackage/AddSimPackageForm";
const AddSimPacKageView = () => {
  return (
    <div>
      <AddSimPackageForm />
    </div>
  );
};

export default AddSimPacKageView;
