import React from "react";
import { Button } from "react-bootstrap";

const GenericIconButton = ({ index, onChangeHandler, icon, name }) => {
  return (
    <div key={`button-${index}`}>
      <Button
        key={`button-${index}`}
        variant="primary"
        block="true"
        className="mt-3 mb-4 btn btn-three edit reset"
        onClick={onChangeHandler}
      >
        <i className={icon}></i> {name?name:""}
      </Button>
    </div>
  );
};

export default GenericIconButton;
