import * as XLSX from "xlsx";

// const transformKeys = (data) => {
//   return data?.map((item) => {
//     const transformedItem = {};
//     for (const key in item) {
//       const transformedKey = key
//         .replace(/_/g, " ")
//         .replace(
//           /\w\S*/g,
//           (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
//         );
//       transformedItem[transformedKey] = item[key];
//     }
//     return transformedItem;
//   });
// };
export const exportToExcel = (jsonArray, fileName) => {
  const ws = XLSX.utils.json_to_sheet(jsonArray);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};
