import React from "react";

import {
 
  Card,
  Container,
  Row,
  Col,

} from "react-bootstrap";
import Counter from "components/Dashboard/Counter";
import SimCardsPieChart from "components/Dashboard/SimCardsPieChart";
import ProfitBarChart from "components/Dashboard/ProfitBarChart";
import SimPackageExpire from "components/Dashboard/SimPackageExpire";
import GreenProfitModem from "components/Dashboard/GreenProfitModem";
import usePermissions from "customHooks/usePermissions";
import { fieldIds } from "constants/moduleFields";

const DashboardView = () => {
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const dashboardModuleID = parseInt(
    process.env.REACT_APP_DASHBOARD_MODULE_ID,
    10
  );
  return (
    <div>
      <Container fluid>
        <Card className="strpied-tabled-with-hover pt-3 pb-3">
          <Card.Header className="register-header">
            <Card.Title as="h4">Dashboard</Card.Title>
            {/* <Breadcrumbs /> */}
          </Card.Header>
        </Card>
        <Row>
          <Col lg="6" md="6" sm="12">
            <Counter />
          </Col>
          {permissions?.permissions[dashboardModuleID].fields[
            fieldIds.dashboard.sim_count_pie_chart
          ]?.view_access ? (
            <Col lg="6" md="6" sm="12">
              <SimCardsPieChart />
            </Col>
          ) : (
            ""
          )}
        </Row>
        {permissions?.permissions[dashboardModuleID].fields[
          fieldIds.dashboard.profit_chart
        ]?.view_access ? (
          <Row>
            <Col md="12">
              <ProfitBarChart />
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row>
          {permissions?.permissions[dashboardModuleID].fields[
            fieldIds.dashboard.sim_expire
          ]?.view_access ? (
            <Col lg="6" md="6" sm="12">
              <SimPackageExpire />
            </Col>
          ) : (
            ""
          )}
          {permissions?.permissions[dashboardModuleID].fields[
            fieldIds.dashboard.green_modem
          ]?.view_access ? (
            <Col lg="6" md="6" sm="12">
              <GreenProfitModem />
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Container>
    </div>
  );
};

export default DashboardView;
