import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getAllCountries } from "API/supplierApi";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "./AddNetworkProvider.css";
import { Breadcrumbs } from "Breadcrumb";
import LoadingOverlay from "components/Overlay";

import { cacheDuration } from "helpers/apiHelper";
import {
  createNetworkProvider,
  editNetworkProvider,
  getActivationType,
  getNetworkQualChoice,
  getAvoidDeactivationType,
} from "API/networkProviderApi";
import { fieldIds } from "constants/moduleFields";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
import { handleKeyPress } from "helpers/numberValidator";


const AddNetworkProviderForm = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm();

  const location = useLocation();
  const [loadings, setLoading] = useState(false);

  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [displayServerMessage, showServerMessage] = useErrorMessage();
  const [selectedNOC, setSelectedNOC] = useState(null);
  const [selectedCNUM, setSelectedCNUM] = useState(null);
  const [selectedPIN, setSelectedPIN] = useState(null);
  const [selectedActivation, setSelectedActivation] = useState(null);
  const [selectedNetworkQual, setSelectedNetworkQual] = useState(null);
  const [selectedExpiredIf, setSelectedExpiredIf] = useState();
  const [selectedActReqReceieveSMS, setSelectedActReqReceieveSMS] = useState();
  const [selectedAvoidDeacCards, setSelectedAvoidDeacCards] = useState();

  const networkProviderModuleID = parseInt(
    process.env.REACT_APP_NETWORK_PROVIDER_MODULE_ID,
    10
  );
  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesData = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingActivationType,
    isError: isErrorActivation,
    error: errorActivation,
    data: activationType,
  } = useQuery("activationType", getActivationType, {
    cacheTime: cacheDuration,
  });

  const activationTypeChoices = activationType?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingNetworkQuality,
    isError: isErrorNetworkQuality,
    error: errorNetworkQuality,
    data: networkQualityChoices,
  } = useQuery("networkQuality", getNetworkQualChoice, {
    cacheTime: cacheDuration,
  });

  const networkQualityTypes = networkQualityChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingAvoidDeacChoice,
    isError: isErrorAvoidDeacChoice,
    error: errorAvoidDeacChoice,
    data: avoidDeacChoice,
  } = useQuery("avoidDeactivationChoices", getAvoidDeactivationType, {
    cacheTime: cacheDuration,
  });

  const avoidDeactivationTypes = avoidDeacChoice?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const booleanOptions = [
    { id: "1", name: "Yes", boolVal: true },
    { id: "0", name: "No", boolVal: false },
  ];
  const handleNOCSelect = (value) => {
    setSelectedNOC(value);
  };
  const handleCNUMSelect = (value) => {
    setSelectedCNUM(value);
  };
  const handlePINSelect = (value) => {
    setSelectedPIN(value);
  };
  const handleActivationSelect = (value) => {
    setSelectedActivation(value);
  };
  const handlNetworkQualSelect = (value) => {
    setSelectedNetworkQual(value);
  };

  useEffect(() => {
    if (
      location.state &&
      activationTypeChoices &&
      allCountriesData &&
      networkQualityTypes &&
      avoidDeactivationTypes
    ) {
      const networkProvrData = location.state.data;

      const noc = booleanOptions.find((value) => {
        return !isNaN(Number(networkProvrData?.number_on_card))
          ? value.id == networkProvrData?.number_on_card
          : value.name == networkProvrData?.number_on_card;
      });
      const atCnumVal = booleanOptions?.find((value) => {
        return !isNaN(Number(networkProvrData?.at_cnum))
          ? value.id == networkProvrData?.at_cnum
          : value.name == networkProvrData?.at_cnum;
      });
      const countryVal = allCountriesData?.find((value) => {
        return !isNaN(Number(networkProvrData?.country))
          ? value.id == networkProvrData?.country
          : value.name == networkProvrData?.country;
      });
      const netQualVal = networkQualityTypes?.find((value) => {
        return !isNaN(Number(networkProvrData?.network_quality))
          ? value.id == networkProvrData?.network_quality
          : value.name == networkProvrData?.network_quality;
      });
      const pinVal = booleanOptions?.find((value) => {
        return !isNaN(Number(networkProvrData?.pin))
          ? value.id == networkProvrData?.pin
          : value.name == networkProvrData?.pin;
      });
      const activationVal = activationTypeChoices?.find((value) => {
        return !isNaN(Number(networkProvrData?.activation))
          ? value.id == networkProvrData?.activation
          : value.name == networkProvrData?.activation;
      });

      const smsActReqVal = booleanOptions?.find((value) => {
        return !isNaN(Number(networkProvrData?.is_activation_required_for_sms))
          ? value.id == networkProvrData?.is_activation_required_for_sms
          : value.name == networkProvrData?.is_activation_required_for_sms;
      });
      const deactAvoidVal = avoidDeactivationTypes?.find((value) => {
        return !isNaN(Number(networkProvrData?.avoid_deactivation_card))
          ? value.id == networkProvrData?.avoid_deactivation_card
          : value.name == networkProvrData?.avoid_deactivation_card;
      });

      setValue("providerName", networkProvrData?.name);
      setSelectedCountry(countryVal);
      setValue("country", countryVal);
      setValue("deliverer", networkProvrData?.deliverer);
      setValue("ussd", networkProvrData?.ussd);
      setSelectedNOC(noc);
      setValue("numberOnCards", noc);
      setSelectedCNUM(atCnumVal);
      setValue("atCnum", atCnumVal);
      setSelectedNetworkQual(netQualVal);
      setValue("networkQuality", netQualVal);
      setSelectedPIN(pinVal);
      setValue("pin", pinVal);
      setSelectedActivation(activationVal);
      setValue("activation", activationVal);
      setValue("notes", networkProvrData.notes);
      setSelectedActReqReceieveSMS(smsActReqVal);
      setValue("is_activation_required_for_sms", smsActReqVal);
      setSelectedAvoidDeacCards(deactAvoidVal);
      setValue("avoid_deactivation_card", deactAvoidVal);
      setValue("daysForCall", networkProvrData.min_day_for_call);
      setValue("daysForTopup", networkProvrData.min_day_for_topup);
      setValue("topUpAmount", networkProvrData.topup_amount);
      setValue("daysForCharge", networkProvrData.charge_after_days);
      setValue("chargeAmount", networkProvrData.charge_amount);
      setValue("oneCostCall", networkProvrData.one_call_cost);
      setValue("activeAfterAct", networkProvrData.active_after_activation);
      setValue(
        "actAftFirstCallTopup",
        networkProvrData.active_after_first_call_or_topup
      );
      setValue("actAftFirstCharge", networkProvrData.active_after_first_charge);

      setIsEdit(true);
    }
  }, [
    location.state,
    allCountries,
    activationType,
    networkQualityChoices,
    avoidDeacChoice,
  ]);

  const networkProviderCreator = useMutation(createNetworkProvider, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);
      navigate("/network-provider");
      await showToast({
        icon: "success",
        title: "Network provider added successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const networkProviderEditor = useMutation(editNetworkProvider, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);
      navigate("/network-provider");
      await showToast({
        icon: "success",
        title: "Network provider edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  const handleReset = () => {
    // Reset the form fields
    setSelectedCNUM(null);
    setSelectedCountry(null);
    setSelectedNOC(null);
    setSelectedPIN(null);
    setSelectedActivation(null);
    setSelectedExpiredIf(null);
    setSelectedNetworkQual(null);
    reset();
  };

  const onSubmit = async (data) => {
    setApiError(false);
    const modulePermission = permissions?.permissions[networkProviderModuleID];
    const formData = new FormData();

    isFieldAddableOrEditable(fieldIds.networkProvider.name, modulePermission) &&
      data.providerName &&
      formData.append("name", data.providerName);

    isFieldAddableOrEditable(
      fieldIds.networkProvider.country,
      modulePermission
    ) &&
      selectedCountry &&
      formData.append("country", selectedCountry.id);

    isFieldAddableOrEditable(
      fieldIds.networkProvider.deliverer,
      modulePermission
    ) && formData.append("deliverer", data.deliverer ? data.deliverer : "");

    isFieldAddableOrEditable(fieldIds.networkProvider.ussd, modulePermission) &&
      formData.append("ussd", data.ussd ? data.ussd : "");

    isFieldAddableOrEditable(
      fieldIds.networkProvider.number_on_card,
      modulePermission
    ) && formData.append("number_on_card", selectedNOC ? selectedNOC.id : "");

    isFieldAddableOrEditable(
      fieldIds.networkProvider.at_cnum,
      modulePermission
    ) && formData.append("at_cnum", selectedCNUM ? selectedCNUM.id : "");

    isFieldAddableOrEditable(fieldIds.networkProvider.pin, modulePermission) &&
      formData.append("pin", selectedPIN ? selectedPIN.id : "");

    isFieldAddableOrEditable(
      fieldIds.networkProvider.activation,
      modulePermission
    ) &&
      selectedActivation &&
      formData.append(
        "activation",
        selectedActivation ? selectedActivation.id : ""
      );

    isFieldAddableOrEditable(
      fieldIds.networkProvider.network_quality,
      modulePermission
    ) &&
      formData.append(
        "network_quality",
        selectedNetworkQual ? selectedNetworkQual.id : ""
      );

    isFieldAddableOrEditable(
      fieldIds.networkProvider.is_activation_required_for_sms,
      modulePermission
    ) &&
      formData.append(
        "is_activation_required_for_sms",
        selectedActReqReceieveSMS ? selectedActReqReceieveSMS.id : ""
      );

    isFieldAddableOrEditable(
      fieldIds.networkProvider.avoid_deactivation_card,
      modulePermission
    ) &&
      formData.append(
        "avoid_deactivation_card	",
        selectedAvoidDeacCards ? selectedAvoidDeacCards.id : ""
      );

    isFieldAddableOrEditable(
      fieldIds.networkProvider.min_day_for_call,
      modulePermission
    ) &&
      formData.append(
        "min_day_for_call	",
        data.daysForCall ? data.daysForCall : ""
      );
    isFieldAddableOrEditable(
      fieldIds.networkProvider.min_day_for_topup,
      modulePermission
    ) &&
      formData.append(
        "min_day_for_topup",
        data.daysForTopup ? data.daysForTopup : ""
      );

    isFieldAddableOrEditable(
      fieldIds.networkProvider.topup_amount,
      modulePermission
    ) &&
      formData.append("topup_amount", data.topUpAmount ? data.topUpAmount : "");

    isFieldAddableOrEditable(
      fieldIds.networkProvider.charge_after_days,
      modulePermission
    ) &&
      formData.append(
        "charge_after_days",
        data.daysForCharge ? data.daysForCharge : ""
      );

    isFieldAddableOrEditable(
      fieldIds.networkProvider.charge_amount,
      modulePermission
    ) &&
      formData.append(
        "charge_amount",
        data.chargeAmount ? data.chargeAmount : ""
      );

    isFieldAddableOrEditable(
      fieldIds.networkProvider.one_call_cost,
      modulePermission
    ) &&
      formData.append(
        "one_call_cost",
        data.oneCostCall ? data.oneCostCall : ""
      );

    isFieldAddableOrEditable(
      fieldIds.networkProvider.active_after_activation,
      modulePermission
    ) &&
      formData.append(
        "active_after_activation",
        data.activeAfterAct ? data.activeAfterAct : ""
      );

    isFieldAddableOrEditable(
      fieldIds.networkProvider.active_after_first_call_or_topup,
      modulePermission
    ) &&
      formData.append(
        "active_after_first_call_or_topup",
        data.actAftFirstCallTopup ? data.actAftFirstCallTopup : ""
      );
    isFieldAddableOrEditable(
      fieldIds.networkProvider.active_after_first_charge,
      modulePermission
    ) &&
      formData.append(
        "active_after_first_charge",
        data.actAftFirstCharge ? data.actAftFirstCharge : ""
      );

    isFieldAddableOrEditable(
      fieldIds.networkProvider.notes,
      modulePermission
    ) && formData.append("notes", data.notes ? data.notes : "");
    selectedActivation;

    try {
      let response;
      if (isEdit) {
        response = await networkProviderEditor.mutateAsync({
          id: location.state.data.id,
          data: formData,
        });
      } else {
        response = await networkProviderCreator.mutateAsync(formData);
      }
    } catch (error) {
      if (error.response.status == 400) {
        setLoading(false);
        setApiError(error.response.data.message);
      } else {
        setLoading(false);
        showServerMessage();
      }
    }
  };



  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">
                  {isEdit ? "Edit" : "Add"} network provider
                </Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          <fieldset>
                            <legend>Network provider details</legend>
                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.name,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Provider name">
                                      <span className="label-text">
                                        Provider name
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("providerName", {
                                        required: true,
                                        maxLength: 50,
                                        minLength: 2,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.providerName?.type ===
                                        "required" &&
                                        "Provider name is required."}
                                      {errors.providerName?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.providerName?.type ===
                                        "minLength" &&
                                        "Enter minimum 2 characters."}
                                      {errors.providerName &&
                                        errors.providerName.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.country,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) &&
                                allCountries && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Country"
                                      selectedOption={selectedCountry}
                                      options={allCountriesData}
                                      handleSelect={handleCountrySelect}
                                      control={control}
                                      errors={errors}
                                      name="country"
                                      rules={{
                                        required: "Country is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.deliverer,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Supplier">Supplier</label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("deliverer", {
                                        maxLength: 50,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.deliverer?.type === "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.deliverer &&
                                        errors.deliverer.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.ussd,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="USSD">USSD code for own number</label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("ussd", {
                                        maxLength: 50,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.ussd?.type === "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.ussd && errors.ussd.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.number_on_card,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <CustomDrodown
                                    label="Number on card"
                                    selectedOption={selectedNOC}
                                    options={booleanOptions}
                                    handleSelect={handleNOCSelect}
                                    control={control}
                                    errors={errors}
                                    name="numberOnCards"
                                  />
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.at_cnum,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <CustomDrodown
                                    label="AT + CNUM"
                                    selectedOption={selectedCNUM}
                                    options={booleanOptions}
                                    handleSelect={handleCNUMSelect}
                                    control={control}
                                    errors={errors}
                                    name="atCnum"
                                  />
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.network_quality,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) &&
                                networkQualityChoices && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Network quality"
                                      selectedOption={selectedNetworkQual}
                                      options={networkQualityTypes}
                                      handleSelect={handlNetworkQualSelect}
                                      control={control}
                                      errors={errors}
                                      name="networkQuality"
                                    />
                                  </Col>
                                )}

                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.pin,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <CustomDrodown
                                    label="PIN?"
                                    selectedOption={selectedPIN}
                                    options={booleanOptions}
                                    handleSelect={handlePINSelect}
                                    control={control}
                                    errors={errors}
                                    name="pin"
                                  />
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.notes,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col md="4">
                                  <Form.Group>
                                    <label title="Notes">Notes</label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      as="textarea"
                                      rows={2}
                                      {...register("notes", {})}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.notes && errors.notes.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                          <fieldset>
                            <legend>Activation network conditions</legend>
                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.activation,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) &&
                                activationType && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Activation"
                                      selectedOption={selectedActivation}
                                      options={activationTypeChoices}
                                      handleSelect={handleActivationSelect}
                                      control={control}
                                      errors={errors}
                                      name="activation"
                                      rules={{
                                        required: "Activation is required.",
                                      }}
                                    />
                                  </Col>
                                )}

                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider
                                  .is_activation_required_for_sms,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <CustomDrodown
                                    label="Activate required for receive SMS"
                                    selectedOption={selectedActReqReceieveSMS}
                                    options={booleanOptions}
                                    handleSelect={(e) =>
                                      setSelectedActReqReceieveSMS(e)
                                    }
                                    control={control}
                                    errors={errors}
                                    name="is_activation_required_for_sms"
                                    rules={{
                                      required:
                                        "Activate required for receive SMS is required.",
                                    }}
                                  />
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider
                                  .avoid_deactivation_card,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) &&
                                avoidDeactivationTypes && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Avoid deactivation of card"
                                      selectedOption={selectedAvoidDeacCards}
                                      options={avoidDeactivationTypes}
                                      handleSelect={(e) =>
                                        setSelectedAvoidDeacCards(e)
                                      }
                                      control={control}
                                      errors={errors}
                                      name="avoid_deactivation_card"
                                      rules={{
                                        required:
                                          "Avoid deactivation of card is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.min_day_for_call,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Days for call">
                                      <span className="label-text">
                                        Days for call
                                      </span>
                                      {selectedAvoidDeacCards &&
                                        watch("avoid_deactivation_card") &&
                                        ["1", "4", "5", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("daysForCall", {
                                        maxLength: 50,
                                        required:
                                          selectedAvoidDeacCards &&
                                          watch("avoid_deactivation_card") &&
                                          ["1", "4", "5", "7", "8"].includes(
                                            selectedAvoidDeacCards.id
                                          ) &&
                                          true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.daysForCall?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.daysForCall?.type ===
                                        "required" &&
                                        watch("avoid_deactivation_card") &&
                                        ["1", "4", "5", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) &&
                                        "Days for call is required."}
                                      {errors.daysForCall &&
                                        errors.daysForCall.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.min_day_for_topup,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Days for top-up">
                                      <span className="label-text">
                                        Days for top-up
                                      </span>
                                      {selectedAvoidDeacCards &&
                                        watch("avoid_deactivation_card") &&
                                        ["2", "4", "6", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("daysForTopup", {
                                        maxLength: 50,
                                        required:
                                          selectedAvoidDeacCards &&
                                          watch("avoid_deactivation_card") &&
                                          ["2", "4", "6", "7", "8"].includes(
                                            selectedAvoidDeacCards.id
                                          ) &&
                                          true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.daysForTopup?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.daysForTopup?.type ===
                                        "required" &&
                                        watch("avoid_deactivation_card") &&
                                        ["2", "4", "6", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) &&
                                        "Days for top-up is required."}
                                      {errors.daysForTopup &&
                                        errors.daysForTopup.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.topup_amount,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Amount for top-up">
                                      <span className="label-text">
                                        Amount for top-up
                                      </span>
                                      {selectedAvoidDeacCards &&
                                        watch("avoid_deactivation_card") &&
                                        ["2", "4", "6", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={(e)=>handleKeyPress(e, true)}
                                      {...register("topUpAmount", {
                                        maxLength: 50,
                                        required:
                                          selectedAvoidDeacCards &&
                                          watch("avoid_deactivation_card") &&
                                          ["2", "4", "6", "7", "8"].includes(
                                            selectedAvoidDeacCards.id
                                          ) &&
                                          true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.topUpAmount?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.topUpAmount?.type ===
                                        "required" &&
                                        watch("avoid_deactivation_card") &&
                                        ["2", "4", "6", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) &&
                                        "Amount of top-up is required."}
                                      {errors.topUpAmount &&
                                        errors.topUpAmount.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.charge_after_days,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Days for charge">
                                      <span className="label-text">
                                        Days for charge
                                      </span>
                                      {selectedAvoidDeacCards &&
                                        watch("avoid_deactivation_card") &&
                                        ["3", "5", "6", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("daysForCharge", {
                                        maxLength: 50,
                                        required:
                                          selectedAvoidDeacCards &&
                                          watch("avoid_deactivation_card") &&
                                          ["3", "5", "6", "7", "8"].includes(
                                            selectedAvoidDeacCards.id
                                          ) &&
                                          true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.daysForCharge?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.daysForCharge?.type ===
                                        "required" &&
                                        watch("avoid_deactivation_card") &&
                                        ["3", "5", "6", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) &&
                                        "Days for charge is required."}
                                      {errors.daysForCharge &&
                                        errors.daysForCharge.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.charge_amount,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Amount of charge">
                                      <span className="label-text">
                                        Amount of charge
                                      </span>
                                      {selectedAvoidDeacCards &&
                                        watch("avoid_deactivation_card") &&
                                        ["3", "5", "6", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={(e)=>handleKeyPress(e, true)}
                                      {...register("chargeAmount", {
                                        maxLength: 50,
                                        required:
                                          selectedAvoidDeacCards &&
                                          watch("avoid_deactivation_card") &&
                                          ["3", "5", "6", "7", "8"].includes(
                                            selectedAvoidDeacCards.id
                                          ) &&
                                          true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.chargeAmount?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.chargeAmount?.type ===
                                        "required" &&
                                        watch("avoid_deactivation_card") &&
                                        ["3", "5", "6", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) &&
                                        "Amount of charge is required."}
                                      {errors.chargeAmount &&
                                        errors.chargeAmount.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider.one_call_cost,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Cost for one call">
                                      <span className="label-text">
                                        Cost for one call
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue="0"
                                      placeholder=""
                                      type="text"
                                      onKeyPress={(e)=>handleKeyPress(e, true)}
                                      {...register("oneCostCall", {
                                        maxLength: 50,
                                        required: true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.oneCostCall?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.oneCostCall?.type ===
                                        "required" &&
                                        "Cost for one call is required."}
                                      {errors.oneCostCall &&
                                        errors.oneCostCall.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider
                                  .active_after_activation,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label
                                      title="Active after activation (no further
                                      action)"
                                    >
                                      <span className="label-text">
                                        Active after activation (no further
                                        action)
                                      </span>
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      title="active after activation (no further
                                        action)"
                                      onKeyPress={handleKeyPress}
                                      {...register("activeAfterAct", {
                                        maxLength: 50,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.activeAfterAct?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.activeAfterAct &&
                                        errors.activeAfterAct.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider
                                  .active_after_first_call_or_topup,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label
                                      title="Active after first call/top-up (no further
                                      action)"
                                    >
                                      <span className="label-text">
                                        Active after first call/top-up (no
                                        further action)
                                      </span>

                                      {selectedAvoidDeacCards &&
                                        watch("avoid_deactivation_card") &&
                                        [
                                          "1",
                                          "2",
                                          "4",
                                          "5",
                                          "6",
                                          "7",
                                          "8",
                                        ].includes(
                                          selectedAvoidDeacCards.id
                                        ) && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("actAftFirstCallTopup", {
                                        maxLength: 50,
                                        required:
                                          selectedAvoidDeacCards &&
                                          watch("avoid_deactivation_card") &&
                                          [
                                            "1",
                                            "2",
                                            "4",
                                            "5",
                                            "6",
                                            "7",
                                            "8",
                                          ].includes(
                                            selectedAvoidDeacCards.id
                                          ) &&
                                          true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.actAftFirstCallTopup?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.actAftFirstCallTopup?.type ===
                                        "required" &&
                                        watch("avoid_deactivation_card") &&
                                        [
                                          "1",
                                          "2",
                                          "4",
                                          "5",
                                          "6",
                                          "7",
                                          "8",
                                        ].includes(selectedAvoidDeacCards.id) &&
                                        "Active after first call/top-up is required."}
                                      {errors.actAftFirstCallTopup &&
                                        errors.actAftFirstCallTopup.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.networkProvider
                                  .active_after_first_charge,
                                permissions?.permissions[
                                  networkProviderModuleID
                                ]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Active after first charge (Days)">
                                      <span className="label-text">
                                        Active after first charge (Days)
                                      </span>

                                      {selectedAvoidDeacCards &&
                                        watch("avoid_deactivation_card") &&
                                        ["3", "5", "6", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) && (
                                          <span className="error-message">
                                            *
                                          </span>
                                        )}
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("actAftFirstCharge", {
                                        maxLength: 50,
                                        required:
                                          selectedAvoidDeacCards &&
                                          watch("avoid_deactivation_card") &&
                                          ["3", "5", "6", "7", "8"].includes(
                                            selectedAvoidDeacCards.id
                                          ) &&
                                          true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.actAftFirstCharge?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.actAftFirstCharge?.type ===
                                        "required" &&
                                        watch("avoid_deactivation_card") &&
                                        ["3", "5", "6", "7", "8"].includes(
                                          selectedAvoidDeacCards.id
                                        ) &&
                                        "Active after first charge is required."}
                                      {errors.actAftFirstCharge &&
                                        errors.actAftFirstCharge.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}

                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right  add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right  mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddNetworkProviderForm;
