import Dashboard from "pages/Dashboard/Dashboard";
import Registration from "pages/Registration/Registration";
import AddUser from "pages/Registration/AddUser";
import ChangePassword from "pages/ChangePassword/ChangePassword";
import UpdateProfile from "pages/UpdateProfile/UpdateProfile";
import UserRole from "pages/UserRole/UserRole";
import AddRole from "pages/UserRole/AddRole";
import EditRole from "pages/UserRole/EditRole";
import Supplier from "pages/Supplier/Supplier";
import AddSupplier from "pages/Supplier/AddSupplier";
import StorageBox from "pages/StorageBox/StorageBox";
import AddStorageBox from "pages/StorageBox/AddStorageBox";
import ServicePartner from "pages/ServicePartner/ServicePartner";
import AddServicePartner from "pages/ServicePartner/AddServicePartner";
import NetworkProvider from "pages/NetworkProvider/NetworkProvider";
import AddNetworkProvider from "pages/NetworkProvider/AddNetworkProvider";
import ModemList from "pages/ModemList/ModemList";
import AddModemList from "pages/ModemList/AddModemList";
import CCMList from "pages/CcmList/AddCcmlist";
import PCModem from "pages/PCModem/PCModem";
import AddPCModem from "pages/PCModem/AddPCModem";
import SimPackage from "pages/SimPackage/SimPackage";
import AddSimPackage from "pages/SimPackage/AddSimPackage";
import ExpirationList from "pages/ExpirationList/ExpirationList";
import AddExpirationList from "pages/ExpirationList/AddExpirationList";
import supplierIcon from "./assets/img/supplier-icon.png"
import ModemDashboard from "pages/ModemDashboard/ModemDashboard";
import ModemLogView from "views/ModemDashboard/ModemLogView";
import AddModemLog from "pages/ModemDashboard/AddModemLog";
import SoftwareLog from "pages/SoftwareLog/SoftwareLog";
import AdminSetting from "pages/AdminSettings/AdminSetting";
import CCM from "pages/CcmList/Ccmlist"
import SoldCard from "components/Table/SoldCardTable"
import EditSoldCard from "components/SoldCard"

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    element: Dashboard,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_DASHBOARD_MODULE_ID, 10),
    accessType: "view",
    module: "",
  },
  {
    path: "/registration",
    name: "Registration",
    icon: "nc-icon nc-circle-09",
    element: Registration,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_REGISTRATION_MODULE_ID, 10),
    accessType: "view",
    module: "Registration",
  },
  {
    path: "/add-user",
    name: "Add user",
    icon: "nc-icon nc-circle-09",
    element: AddUser,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_REGISTRATION_MODULE_ID, 10),
    accessType: "add",
    module: "Registration",
  },
  {
    path: "/edit-user",
    name: "Edit user",
    icon: "nc-icon nc-circle-09",
    element: AddUser,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_REGISTRATION_MODULE_ID, 10),
    accessType: "edit",
    module: "Registration",
  },
  {
    path: "/change-password",
    name: "Change password",
    icon: "nc-icon nc-circle-09",
    element: ChangePassword,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_ALLOWED_MODULE_ID, 10),
    accessType: false,
    module: "",
  },
  {
    path: "/user-role",
    name: "User Role",
    icon: "nc-icon nc-badge",
    element: UserRole,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_USERROLE_MODULE_ID, 10),
    accessType: "view",
    module: "User Role",
  },
  {
    path: "/update-profile",
    name: "Update profile",
    icon: "nc-icon nc-circle-09",
    element: UpdateProfile,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_ALLOWED_MODULE_ID, 10),
    accessType: false,
    module: "",
  },
  {
    path: "/add-role",
    name: "Add role",
    icon: "nc-icon nc-circle-09",
    element: AddRole,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_USERROLE_MODULE_ID, 10),
    accessType: "add",
    module: "User Role",
  },
  {
    path: "/edit-role",
    name: "Edit role",
    icon: "nc-icon nc-circle-09",
    element: EditRole,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_USERROLE_MODULE_ID, 10),
    accessType: "edit",
    module: "User Role",
  },
  {
    path: "/supplier",
    name: "Supplier",
    icon: "nc-icon nc-delivery-fast",
    element: Supplier,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_SUPPLIER_MODULE_ID, 10),
    accessType: "view",
    module: "Supplier",
  },
  {
    path: "/add-supplier",
    name: "Add supplier",
    icon: "nc-icon nc-delivery-fast",
    element: AddSupplier,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_SUPPLIER_MODULE_ID, 10),
    accessType: "add",
    module: "Supplier",
  },
  {
    path: "/edit-supplier",
    name: "Edit supplier",
    icon: "nc-icon nc-delivery-fast",
    element: AddSupplier,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_SUPPLIER_MODULE_ID, 10),
    accessType: "edit",
    module: "Supplier",
  },
  {
    path: "/storage-box",
    name: "Storage Box",
    icon: "nc-icon nc-app",
    element: StorageBox,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_STORAGE_BOX_MODULE_ID, 10),
    accessType: "view",
    module: "Storage Box",
  },
  {
    path: "/add-storage-box",
    name: "Add storage box",
    icon: "nc-icon nc-app",
    element: AddStorageBox,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_STORAGE_BOX_MODULE_ID, 10),
    accessType: "add",
    module: "Storage Box",
  },
  {
    path: "/edit-storage-box",
    name: "Edit storage box",
    icon: "nc-icon nc-app",
    element: AddStorageBox,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_STORAGE_BOX_MODULE_ID, 10),
    accessType: "edit",
    module: "Storage Box",
  },
  {
    path: "/service-partner",
    name: "Service Partner",
    icon: "nc-icon nc-settings-90",
    element: ServicePartner,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_SERVICE_PARTNER_MODULE_ID, 10),
    accessType: "view",
    module: "Service Partner",
  },
  {
    path: "/add-service-partner",
    name: "Add service partner",
    icon: "nc-icon nc-settings-90",
    element: AddServicePartner,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_SERVICE_PARTNER_MODULE_ID, 10),
    accessType: "add",
    module: "Service Partner",
  },
  {
    path: "/edit-service-partner",
    name: "Edit service partner",
    icon: "nc-icon nc-settings-90",
    element: AddServicePartner,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_SERVICE_PARTNER_MODULE_ID, 10),
    accessType: "edit",
    module: "Service Partner",
  },
  {
    path: "/network-provider",
    name: "Network Provider",
    icon: "nc-icon nc-planet",
    element: NetworkProvider,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_NETWORK_PROVIDER_MODULE_ID, 10),
    accessType: "view",
    module: "Network Provider",
  },
  {
    path: "/add-network-provider",
    name: "Add network provider",
    icon: "nc-icon nc-planet",
    element: AddNetworkProvider,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_NETWORK_PROVIDER_MODULE_ID, 10),
    accessType: "add",
    module: "Network Provider",
  },
  {
    path: "/edit-network-provider",
    name: "Edit network provider",
    icon: "nc-icon nc-planet",
    element: AddNetworkProvider,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_NETWORK_PROVIDER_MODULE_ID, 10),
    accessType: "edit",
    module: "Network Provider",
  },
  {
    path: "/modem-list",
    name: "Modem List",
    icon: "nc-icon nc-bullet-list-67",
    element: ModemList,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_MODEM_LIST_MODULE_ID, 10),
    accessType: "view",
    module: "Modem List",
  },
  {
    path: "/add-modem-list",
    name: "Add modem list",
    icon: "nc-icon nc-bullet-list-67",
    element: AddModemList,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_MODEM_LIST_MODULE_ID, 10),
    accessType: "add",
    module: "Modem List",
  },
  {
    path: "/add-ccm-list",
    name: "Add CCM list",
    icon: "nc-icon nc-bullet-list-67",
    element: CCMList,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_CCM_LIST_MODULE_ID, 10),
    accessType: "add",
    module: "CCM",
  },
  {
    path: "/soldcard-list",
    name: "Sold card list",
    icon: "nc-icon nc-bullet-list-67",
    element: SoldCard,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_CCM_LIST_MODULE_ID, 10),
    accessType: "view",
    module: "Sold Card List",
  },

  {
    path: "/edit-ccm-list",
    name: "Edit ccm list",
    icon: "nc-icon nc-bullet-list-67",
    element: CCMList,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_CCM_LIST_MODULE_ID, 10),
    accessType: "edit",
    module: "CCM",
  },
  {
    path: "/edit-modem-list",
    name: "Edit modem list",
    icon: "nc-icon nc-bullet-list-67",
    element: AddModemList,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_MODEM_LIST_MODULE_ID, 10),
    accessType: "edit",
    module: "Modem List",
  },
  {
    path: "/pc-modem",
    name: "PC Modem",
    icon: "nc-icon nc-tv-2",
    element: PCModem,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_PC_MODEM_MODULE_ID, 10),
    accessType: "view",
    module: "PC Modem",
  },
  {
    path: "/add-pc-modem",
    name: "Add PC modem",
    icon: "nc-icon nc-tv-2",
    element: AddPCModem,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_PC_MODEM_MODULE_ID, 10),
    accessType: "add",
    module: "PC Modem",
  },
  {
    path: "/edit-pc-modem",
    name: "Edit PC modem",
    icon: "nc-icon nc-circle-09",
    element: AddPCModem,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_PC_MODEM_MODULE_ID, 10),
    accessType: "edit",
    module: "PC Modem",
  },
  {
    path: "/sim-package",
    name: "SIM Package",
    icon: "nc-icon nc-credit-card",
    element: SimPackage,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_SIM_PACKAGE_MODULE_ID, 10),
    accessType: "view",
    module: "SIM Package",
  },
  {
    path: "/add-sim-package",
    name: "Add SIM package",
    icon: "nc-icon nc-app",
    element: AddSimPackage,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_SIM_PACKAGE_MODULE_ID, 10),
    accessType: "add",
    module: "SIM Package",
  },
  {
    path: "/edit-sim-package",
    name: "Edit SIM package",
    icon: "nc-icon nc-app",
    element: AddSimPackage,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_SIM_PACKAGE_MODULE_ID, 10),
    accessType: "edit",
    module: "SIM Package",
  },
  {
    path: "/modem-dashboard",
    name: "Modem Dashboard",
    icon: "nc-icon nc-chart-bar-32",
    element: ModemDashboard,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID, 10),
    accessType: "view",
    module: "Modem Dashboard",
  },
  {
    path: "/modem-log",
    name: "Modem Log",
    icon: "nc-icon nc-circle-09",
    element: ModemLogView,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID, 10),
    accessType: "view",
    module: "Modem Dashboard",
  },
  {
    path: "/ccm-list",
    name: "CCM",
    icon: "nc-icon nc-money-coins",
    element: CCM,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID, 10),
    accessType: "view",

  },
  {
    path: "/add-modem-log",
    name: "Add modem log",
    icon: "nc-icon nc-single-copy-04",
    element: AddModemLog,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID, 10),
    accessType: "add",
    module: "Modem Log",
  },
  {
    path: "/edit-modem-log",
    name: "Edit modem log",
    icon: "nc-icon nc-single-copy-04",
    element: AddModemLog,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_MODEM_DASHBOARD_MODULE_ID, 10),
    accessType: "edit",
    module: "Modem Log",
  },
  {
    path: "/edit-sold-log",
    name: "Edit Sold log",
    icon: "nc-icon nc-single-copy-04",
    element: EditSoldCard,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_SOLD_CARD_MODULE_ID, 10),
    accessType: "edit",
    module: "Sold Log",
  },
  {
    path: "/add-sold-log",
    name: "Add Sold log",
    icon: "nc-icon nc-single-copy-04",
    element: EditSoldCard,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_SOLD_CARD_MODULE_ID, 10),
    accessType: "add",
    module: "Sold Log",
  },
  {
    path: "/software-log",
    name: "Software Log",
    icon: "nc-icon nc-single-copy-04",
    element: SoftwareLog,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_SOFTWARE_LOG_MODULE_ID, 10),
    accessType: "view",
    module: "Software Log",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "nc-icon nc-circle-09",
    element: AdminSetting,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_ADMIN_SETTING_MODULE_ID, 10),
    accessType: "view",
    module: "Setting",
  },
  {
    path: "/expiration-list",
    name: "Expiration List",
    icon: "nc-icon nc-notes",
    element: ExpirationList,
    sidebar: true,
    moduleId: parseInt(process.env.REACT_APP_EXPIRATION_LIST_MODULE_ID, 10),
    accessType: "view",
    module: "Expiration List",
  },
  {
    path: "/add-expiration-list",
    name: "Add Expiration List",
    icon: "nc-icon nc-notes",
    element: AddExpirationList,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_EXPIRATION_LIST_MODULE_ID, 10),
    accessType: "add",
    module: "Expiration List",
  },
  {
    path: "/edit-expiration-list",
    name: "Edit Expiration List",
    icon: "nc-icon nc-notes",
    element: AddExpirationList,
    sidebar: false,
    moduleId: parseInt(process.env.REACT_APP_EXPIRATION_LIST_MODULE_ID, 10),
    accessType: "edit",
    module: "Expiration List",
  },
];

export default routes;
