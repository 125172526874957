

import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { Controller } from "react-hook-form";


const CustomMultipleDropdown = ({
  label,
  selectedOption,
  options,
  handleSelect,
  control,
  errors,
  name,
  rules,
  defaultValue,
}) => {
  return (
    <div>
      <Form.Item required={!!rules}>
        <label className="custom-label" title={label}>
          <span className="label-text">{label}</span>
          {rules && <span className="error-message"> *</span>}
        </label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              id={`${name}-dropdown`}
              className="multiple-dropdown-selector"
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder={`Select ${label}`}
              value={selectedOption ? selectedOption : []}
              onChange={(value) => {
                const selectedOptions = options.filter((option) =>
                  value.includes(option.value)
                );
                handleSelect(selectedOptions);
                field.onChange(value);
              }}
              options={options}
              auto
            />
          )}
          rules={rules}
        />
        {errors[name] && (
          <span className="error-message">{errors[name].message}</span>
        )}
      </Form.Item>
    </div>
  );
};

export default CustomMultipleDropdown;
