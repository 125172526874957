import React, { useState } from "react";

import {
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import LoadingOverlay from "components/Overlay";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { UserAddOutlined, SearchOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import DropdownFilter from "components/DropdownFilter/DropdownFilter";
import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import { fieldIds } from "constants/moduleFields";
import { getAllCountries } from "API/supplierApi";
import { getPCModems, editPCModem, deletePcModem } from "API/pcModemApi";
import ConfirmModal from "components/Modal/Modal";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import PasswordHideShow from "components/PasswordHideShow/PasswordHideShow";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";

const PCModemTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged,setPermissionChanged]=useState(true)
  const [loadings, setLoading] = useState(false);

  const pcModemModuleID = parseInt(
    process.env.REACT_APP_PC_MODEM_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    config_status_filter: selectedPaymentType,
    country_filter: selectedCountry,
    search_filter: searchInput,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: pcModems,
    refetch,
  } = useQuery(["pcModems", queryParams], getPCModems, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesTransformed = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });
  const allConfigData = { id: "", name: "All" };

  const configOption = [
    {
      id: "1",
      name: "Ready",
    },
    {
      id: "2",
      name: "Not Ready",
    },
  ];

  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };
  const handlePaymentTypeSelect = (selectedPaymentType) => {
    setSelectedPaymentType(selectedPaymentType);
    refetch();
  };
  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    refetch();
  };
  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  const pcModemEditor = useMutation(editPCModem, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      
      refetch();
      setLoading(false);
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      
      await refetch();
      await permissionRefetch();
      setLoading(false);
      setPermissionChanged(true)
    },
  });
  const removePCModem = useMutation(deletePcModem, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      
      refetch();
      setLoading(false);
    },
  });

  const handleEdit = (user) => {
    navigate("/edit-pc-modem", { state: { data: user } });
  };
  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen&& selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: pcModemModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
    setLoading(false);

  };
  const handleDelete = async (pcModem) => {
    const deleteApi = async () => {
      try {
        const response = await removePCModem.mutateAsync({ id: pcModem.id });
        return response;
      } catch (error) {
      setLoading(false);

        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: deleteApi,
      deleteErrorText: "Can't delete PC modem!",
      deleteSuccessMessage: "PC modem deleted successfully",
    });
  };

  const handleToggle = async (id, status) => {
    const response = await pcModemEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };

  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[pcModemModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }
    if (permissions.permissions[pcModemModuleID].delete_access) {
      buttons.push(
        <DeleteButton
          key={`delete-${record?.id}`}
          onChangeHandler={() => handleDelete(record)}
          record={record}
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    // {
    //   perm_id: 0,
    //   title: "Index",
    //   dataIndex: "index",
    //   sorter: (a, b) => a.index - b.index,
    //   width: 10,
    // },
    {
      perm_id: 0,
      title: "Index",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      width: 10,
    },
    {
      perm_id: fieldIds.pcModem.pc_name,
      title: "PC name",
      dataIndex: "pc_name",
      sorter: (a, b) => {
        return a.pc_name.localeCompare(b.pc_name);
      },
    },
    {
      perm_id: fieldIds.pcModem.modem_id,
      title: "Modem name",
      dataIndex: "modem",
      sorter: (a, b) => {
        return a.modem.localeCompare(b.modem);
      },
    },
    {
      perm_id: fieldIds.pcModem.anydesk_id,
      title: "Anydesk ID",
      dataIndex: "anydesk_id",
      sorter: (a, b) =>
        String(a.anydesk_id ?? "").localeCompare(String(b.anydesk_id ?? "")),
    },
    {
      perm_id: fieldIds.pcModem.password_full_access,
      title: "Anydesk password for full access",
      dataIndex: "password_full_access",
      render: (password_full_access) => (
        <div
          className="password-box"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            border: "1px solid #ddd",
            padding: "5px 10px",
            minWidth: "150px",
            borderRadius: "5px",
          }}
        >
          {password_full_access ? (
            <PasswordHideShow password={password_full_access} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      perm_id: fieldIds.pcModem.password_for_limited_access,
      title: "Anydesk password for limited access",
      dataIndex: "password_for_limited_access",

      render: (password_for_limited_access) => (
        <div
          className="password-box"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            border: "1px solid #ddd",
            padding: "5px 10px",
            minWidth: "150px",
            borderRadius: "5px",
          }}
        >
          {password_for_limited_access ? (
            <PasswordHideShow password={password_for_limited_access} />
          ) : (
            "-"
          )}
        </div>
      ),
    },
    {
      perm_id: fieldIds.pcModem.seller_shop_name,
      title: "Seller shop name",
      dataIndex: "seller_shop_name",
      width: "300px",
      render: (seller_shop_name) => (
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
          }}
        >
          {seller_shop_name ? seller_shop_name : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.seller_shop_name ?? "").localeCompare(
          String(b.seller_shop_name ?? "")
        ),
    },
    {
      perm_id: fieldIds.pcModem.windows_license_key,
      title: "Windows license key",
      dataIndex: "windows_license_key",
      width: "300px",

      render: (windows_license_key) => (
        <div
          style={{
            textOverflow: "eclipsis",
            whiteSpace: "pre-wrap",
          }}
        >
          {windows_license_key ? windows_license_key : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.windows_license_key ?? "").localeCompare(
          String(b.windows_license_key ?? "")
        ),
    },
    {
      perm_id: fieldIds.pcModem.config_status,
      title: "Config status",
      dataIndex: "config_status",
      sorter: (a, b) =>
        String(a.config_status ?? "").localeCompare(
          String(b.config_status ?? "")
        ),
    },

    {
      perm_id: fieldIds.pcModem.running_service_partner_id,
      title: "Running service partner",
      dataIndex: "running_service_partner",

      render: (running_service_partner) => (
        <div
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
          }}
        >
          {running_service_partner ? running_service_partner : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.running_service_partner ?? "").localeCompare(
          String(b.running_service_partner ?? "")
        ),
    },
    {
      perm_id: fieldIds.pcModem.country,
      title: "Country",

      dataIndex: "country",
      render: (country) => (country ? country : "-"),
      sorter: (a, b) =>
        String(a.country ?? "").localeCompare(String(b.country ?? "")),
    },
    {
      perm_id: fieldIds.pcModem.is_software_prepared_for_sp1,
      title: "Software1 prepared?",
      dataIndex: "is_software_prepared_for_sp1",
      render: (is_software_prepared_for_sp1) =>
        is_software_prepared_for_sp1 != null
          ? is_software_prepared_for_sp1
            ? "Yes"
            : "No"
          : "-",
      sorter: (a, b) =>
        String(a.is_software_prepared_for_sp1 ?? "").localeCompare(
          String(b.is_software_prepared_for_sp1 ?? "")
        ),
    },
    {
      perm_id: fieldIds.pcModem.is_software_prepared_for_sp2,
      title: "Software2 prepared?",
      dataIndex: "is_software_prepared_for_sp2",
      render: (is_software_prepared_for_sp2) =>
        is_software_prepared_for_sp2 != null
          ? is_software_prepared_for_sp2
            ? "Yes"
            : "No"
          : "-",
      sorter: (a, b) =>
        String(a.is_software_prepared_for_sp2 ?? "").localeCompare(
          String(b.is_software_prepared_for_sp2 ?? "")
        ),
    },
    {
      perm_id: fieldIds.pcModem.is_software_prepared_for_sp3,
      title: "Software3 prepared?",
      dataIndex: "is_software_prepared_for_sp3",
      render: (is_software_prepared_for_sp3) =>
        is_software_prepared_for_sp3 != null
          ? is_software_prepared_for_sp3
            ? "Yes"
            : "No"
          : "-",
      sorter: (a, b) =>
        String(a.is_software_prepared_for_sp3 ?? "").localeCompare(
          String(b.is_software_prepared_for_sp3 ?? "")
        ),
    },
    {
      perm_id: fieldIds.pcModem.notes,
      title: "Notes",
      dataIndex: "notes",
      render: (notes) => (
        <div
          style={{

            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {notes ? notes : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.notes ?? "").localeCompare(String(b.notes ?? "")),
    },
    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      width: 150,
      maxWidth: 150,
      buttons: actionButtons(),
      render: (_, record) => actionButtons(record),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: pcModems?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        data={pcModems?.results}
        columns={columns}
        permissionSet={permissions.permissions[pcModemModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        choiceData={{
          country: allCountriesTransformed,
          config_status: configOption,
        }}
        showColumnState={showColumnState}
      />
    );
  }

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> PC Modem
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[pcModemModuleID].fields || []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={pcModemModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged} 
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    
                    {permissions?.permissions[pcModemModuleID].fields[81]
                      ?.view_access && (
                      <DropdownFilter
                        title={"Config Status"}
                        selectedValue={selectedPaymentType}
                        items={[allConfigData, ...configOption]}
                        onSelect={handlePaymentTypeSelect}
                        filterName="Config Status"
                      />
                    )}

                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | PC Name | Modem Name ..."
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center top-table-buttons"
                  >
                 
                    {isAddAllowed(permissions.permissions[pcModemModuleID]) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-pc-modem")}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PCModemTable;
