import React from "react";
import AddNetworkProviderForm from "components/NetworkProvider/AddNetworkProviderForm";
const AddNetworkProviderView = () => {
  return (
    <div>
      <AddNetworkProviderForm />
    </div>
  );
};

export default AddNetworkProviderView;
