import React from "react";
import AddSupplierView from "views/Supplier/AddSupplierView";

const AddSupplier = () => {
  return (
    <div>
      <AddSupplierView />
    </div>
  );
};

export default AddSupplier;
