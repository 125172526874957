import React, { useState, useEffect } from "react";
import { Select, Checkbox } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { customNames } from "components/AddRoleForm/data";

// ... (previous imports)
// ... (previous imports)

const CheckboxDropdown = ({
  title,
  selectedValues,
  items,
  onSelect,
  moduleId,
  filterName,
  handleDropdownClose,
  isPermissionChanged, 
  setIsPermissionChanged
}) => {
  const [checkedValues, setCheckedValues] = useState(selectedValues || []);

  // Convert object to array
  const options = Object.keys(items)
    .map((key) => ({
      key,
      label: items[key].name,
      value: key, // Store key instead of name
      defaultChecked: items[key].column_show,
      coloum_visible: items[key].view_access,
    }))
    .filter((option) => option.coloum_visible);

  useEffect(() => {
    const defaultCheckedValues = options
      .filter((item) => item.defaultChecked)
      .map((item) => item.value);
    setCheckedValues(defaultCheckedValues);

    setIsPermissionChanged(false)
  }, [isPermissionChanged,items]); // Run this effect only once, on component mount


  const handleCheckboxChange = (value, option) => {
    if (value === 'selectAll') {
      const allValues = options.map((item) => item.value);
      const updatedCheckedValues = checkedValues.length === allValues.length
        ? []
        : allValues;

      setCheckedValues(updatedCheckedValues);
      onSelect(updatedCheckedValues);
    } else {
      const updatedCheckedValues = checkedValues.includes(value)
        ? checkedValues.filter((v) => v !== value)
        : [...checkedValues, value];

      setCheckedValues(updatedCheckedValues);
      onSelect(updatedCheckedValues); // Send all selected values to onSelect
    }
  };

  const filterOption = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const selectAllLabel =
    checkedValues.length === options.length ? 'Unselect all' : 'Select all';

  return (
    <Select
      className="checkboxDropdownSelect"
      mode="multiple"
      placeholder={
        <div>
          <FilterOutlined />{' '}
          {checkedValues.length > 0 
            ? `${checkedValues?.length} items selected`
            : filterName}
        </div>
      }
      style={{ width: 120 }}
      value={`${checkedValues.length} columns selected`}
      onDropdownVisibleChange={handleDropdownClose}
      showSearch={false}
      dropdownStyle={{ maxHeight: '300px', overflowY: 'auto' }}
    >
      <Select.Option
        className="checkboxDropdownOptions"
        key="selectAll"
        value="selectAll"
        onMouseDown={(e) => {
          handleCheckboxChange('selectAll', null);
        }}
      >
        <Checkbox checked={checkedValues.length === options.length}>
          {selectAllLabel}
        </Checkbox>
      </Select.Option>
      {options?.map((item) => (
        <Select.Option
          className="checkboxDropdownOptions"
          key={item.value}
          value={item.value}
          label={item.label}
          defaultChecked={item.defaultChecked}
          onMouseDown={(e) => {
            handleCheckboxChange(item.value, item);
          }}
        >
          <Checkbox checked={checkedValues?.includes(item.value)}>
            {customNames[moduleId][item.key] || 'Unknown Name'}
          </Checkbox>
        </Select.Option>
      ))}
    </Select>
  );
};

export default CheckboxDropdown;
