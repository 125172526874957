import React, { useState, useEffect } from "react";

import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "components/Overlay";

import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { getAllCountries } from "API/supplierApi";
import CustomDrodown from "components/CustomDropdown/CustomDrodown";
import { isFieldAddableOrEditable } from "helpers/permissionChecker";
import usePermissions from "customHooks/usePermissions";
import useErrorMessage from "customHooks/useErrorMessage";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import FormAlertMessage from "components/FormAlertMessage/FormAlertMessage";
import "./AddSimpackageForm.css";
import { Breadcrumbs } from "Breadcrumb";
import { cacheDuration } from "helpers/apiHelper";
import { fieldIds } from "constants/moduleFields";
import { DatePicker } from "antd";
import {
  getProviderChoices,
  getSupplierChoices,
  createSimPackage,
  editSimPackage,
  getActivationChoices,
  getScanChoices,
  getStorageChoices,
  getCurrencyChoices,
  getSellCurrencyChoices,
} from "API/simPackageAPI";
import { getReturnChoices } from "API/simPackageAPI";
import { convertDate } from "helpers/dateValidator";
import showToast from "components/Toast/Toast";
import CancelButton from "components/CancelButton/CancelButton";
import { handleKeyPress } from "helpers/numberValidator";

const AddSimPackageForm = () => {
  const {
    watch,
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const location = useLocation();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [displayServerMessage, showServerMessage] = useErrorMessage();

  const [dateOfDel, setDateOfDel] = useState(null);
  const [selectedProv, setSelectedProv] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedReturnType, setSelectedReturnType] = useState(null);
  const [selectedUnpackNeed, setSelectedUnpackNeed] = useState(null);
  const [selectedScan, setSelectedScan] = useState(null);
  const [selectedScanPhone, setSelectedScanPhone] = useState(null);
  const [expForActivation, setSelectedExpForActivation] = useState(null);
  const [expAfterActivation, setSelectedExpafterActivation] = useState(null);
  const [delDateCZZE, setDateCZZE] = useState(null);
  const [delDateSup, setDateSup] = useState(null);
  const [selecetedCurrency, setSelecetedCurrency] = useState(null);
  const [paidDate, setPaidDate] = useState(null);
  const [selectedSellCurrency, setSelectedSellCurrency] = useState(null);
  const [selectedBefUse, setSelectedBefUse] = useState(null);
  const [loadings, setLoading] = useState(false);

  const [selectedAftUse, setSelectedAftUse] = useState(null);
  const [selectedDefUse, setSelectedDefUse] = useState(null);
  const [selectedActivationStatus, setSelectedActivationStatus] =
    useState(null);
  const simPackageModuleID = parseInt(
    process.env.REACT_APP_SIM_PACKAGE_MODULE_ID,
    10
  );

  dayjs.extend(customParseFormat);

  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });
  const allCountriesData = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingActivationType,
    isError: isErrorActivation,
    error: errorActivation,
    data: activationChoices,
  } = useQuery("activationChoices", getActivationChoices, {
    cacheTime: cacheDuration,
  });

  const activationChoicesData = activationChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingProvider,
    isError: isErrorProvider,
    error: errorProvider,
    data: providerChoices,
  } = useQuery("providerChoices", getProviderChoices, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingSupplier,
    isError: isErrorSupplier,
    error: errorSupplier,
    data: supplierChoices,
  } = useQuery("supplierChoices", getSupplierChoices, {
    cacheTime: cacheDuration,
  });

  const supplierChoicesData = supplierChoices?.map((innerArray) => {
    return {
      id: innerArray.id,
      name: innerArray.company_name,
    };
  });

  const {
    isLoading: isLoadingReturnType,
    isError: isErrorReturnType,
    error: errorReturnType,
    data: ReturnTypeChoices,
  } = useQuery("returnTypeChoices", getReturnChoices, {
    cacheTime: cacheDuration,
  });

  const returnTypeData = ReturnTypeChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingScanType,
    isError: isErrorScanType,
    error: errorScanType,
    data: scanTypeChoices,
  } = useQuery("scanTypeChoices", getScanChoices, {
    cacheTime: cacheDuration,
  });

  const scanTypeData = scanTypeChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingStorageChoice,
    isError: isErrorStorageChoice,
    error: errorStorageChoice,
    data: storageChoices,
  } = useQuery("storageChoices", getStorageChoices, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingCurrency,
    isError: isErrorCurrency,
    error: errorCurrency,
    data: currencyChoices,
  } = useQuery("currencyChoices", getCurrencyChoices, {
    cacheTime: cacheDuration,
  });

  const currencyChoicesData = currencyChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const {
    isLoading: isLoadingSellCurrency,
    isError: isErrorSellCurrency,
    error: errorSellCurrency,
    data: currencySellChoices,
  } = useQuery("currencySellChoices", getSellCurrencyChoices, {
    cacheTime: cacheDuration,
  });

  const currencySellChoicesData = currencySellChoices?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const booleanOptions = [
    { id: "1", name: "Yes" },
    { id: "0", name: "No" },
  ];

  useEffect(() => {
    if (
      location.state &&
      storageChoices &&
      activationChoices &&
      currencyChoices &&
      currencySellChoices &&
      allCountries &&
      providerChoices &&
      supplierChoices &&
      returnTypeData &&
      scanTypeChoices
    ) {
      const simPackageData = location.state.data;

      const provVal = providerChoices.find(
        (prov) => prov.id == simPackageData.provider_id
      );
      const countryVal = allCountriesData.find((value) => {
        return !isNaN(Number(simPackageData.country))
          ? value.id == simPackageData.country
          : value.name == simPackageData.country;
      });
      const supplierVal = supplierChoicesData.find(
        (val) => val.id == simPackageData.supplier_id
      );
      const returnVal = returnTypeData.find((value) => {
        return !isNaN(Number(simPackageData.return_type))
          ? value.id == simPackageData.return_type
          : value.name == simPackageData.return_type;
      });
      const scanVal = scanTypeData.find((value) => {
        return !isNaN(Number(simPackageData.scan))
          ? value.id == simPackageData.scan
          : value.name == simPackageData.scan;
      });
      const activationVal = activationChoicesData.find((value) => {
        return !isNaN(Number(simPackageData.activation_status))
          ? value.id == simPackageData.activation_status
          : value.name == simPackageData.activation_status;
      });
      const currencyVal = currencyChoicesData.find((value) => {
        return !isNaN(Number(simPackageData.currency))
          ? value.id == simPackageData.currency
          : value.name == simPackageData.currency;
      });
      const sellCurrencyVal = currencySellChoicesData.find((value) => {
        return !isNaN(Number(simPackageData.selling_currency))
          ? value.id == simPackageData.selling_currency
          : value.name == simPackageData.selling_currency;
      });
      const befUse =
        storageChoices?.storege_in_use.find(
          (val) => val.id == simPackageData.storage_before_use_id
        ) || [];
      const aftUse =
        storageChoices?.storege_in_use.find(
          (val) => val.id == simPackageData.storage_after_use_id
        ) || [];
      const defUse =
        storageChoices?.storege_in_use.find(
          (val) => val.id == simPackageData.storage_for_defective_id
        ) || [];

      setValue("name", simPackageData.name);
      setValue(
        "dateOfDel",
        simPackageData.date_of_delevery != "-" &&
          simPackageData.date_of_delevery
          ? dayjs(simPackageData.date_of_delevery)
          : ""
      );
      setValue("packageName", simPackageData.name);
      setSelectedProv(provVal);
      setValue("provider", provVal.id);
      setSelectedCountry(countryVal);
      setValue("country", countryVal.id);
      setSelectedSupplier(supplierVal);
      setValue("supplier", supplierVal.id);
      setSelectedReturnType(returnVal);
      setValue("returnType", returnVal.id);
      setValue(
        "lastGiveBackDate",
        simPackageData.last_give_back_date != "-" &&
          simPackageData.last_give_back_date
          ? dayjs(simPackageData.last_give_back_date)
          : ""
      );
      setSelectedUnpackNeed(
        booleanOptions[simPackageData.is_unpacking_needed ? 0 : 1]
      );

      setValue(
        "unPackingNeed",
        booleanOptions[simPackageData.is_unpacking_needed ? 0 : 1].id
      );
      setSelectedScan(scanVal);
      setValue("scan", scanVal.id);
      setSelectedScanPhone(
        booleanOptions[simPackageData.scan_phonenumber ? 0 : 1]
      );
      setValue(
        "scanPhone",
        booleanOptions[simPackageData.scan_phonenumber ? 0 : 1].id
      );
      setValue("priority", simPackageData.priority);
      setValue(
        "delieverydateCZZE",
        simPackageData.delivery_date_cz_de != "-" &&
          simPackageData.delivery_date_cz_de
          ? dayjs(simPackageData.delivery_date_cz_de)
          : ""
      );
      setValue(
        "delDateSup",
        simPackageData.delivery_date_to_supplier != "-" &&
          simPackageData.delivery_date_to_supplier
          ? dayjs(simPackageData.delivery_date_to_supplier)
          : ""
      );
      setSelectedActivationStatus(activationVal);
      setValue("actvationStatus", activationVal.id);
      setValue("notes", simPackageData.note);
      setValue("amountSim", simPackageData.amount_of_sim);
      setValue("moreCards", simPackageData.more_card);
      setValue("missingCards", simPackageData.missing_cards);
      setValue("amountGivenBack", simPackageData.amount_of_card_given_back);
      setValue("thrownAwayCards", simPackageData.thrown_away_card);
      setValue(
        "expirationForActivation",
        simPackageData.expire_date_to_activate_sim != "-" &&
          simPackageData.expire_date_to_activate_sim
          ? dayjs(simPackageData.expire_date_to_activate_sim)
          : ""
      );
      setValue(
        "expirationAfterActivation",
        simPackageData.expire_date_after_activation != "-" &&
          simPackageData.expire_date_after_activation
          ? dayjs(simPackageData.expire_date_after_activation)
          : ""
      );
      setSelecetedCurrency(currencyVal);
      setValue("currency", currencyVal.id);
      setValue("pricePerSim", simPackageData.price_per_sim);
      setValue("paid", simPackageData.paid_amount);
      setValue(
        "paidDate",
        simPackageData.paid_date != "-" && simPackageData.paid_date
          ? dayjs(simPackageData.paid_date)
          : ""
      );
      setValue("soldCards", simPackageData.sold_card);
      setValue("soldPricePerSim", simPackageData.sold_price_per_sim);
      setSelectedSellCurrency(
        simPackageData.selling_currency ? sellCurrencyVal : ""
      );
      setValue(
        "sellingCurrency",
        simPackageData.selling_currency ? sellCurrencyVal.id : ""
      );

      setSelectedBefUse(simPackageData.storage_before_use_id ? befUse : "");

      setValue(
        "storageBefUse",
        simPackageData.storage_before_use_id ? befUse.id : ""
      );

      setSelectedAftUse(
        simPackageData.storage_after_use_id && aftUse?.id ? aftUse : ""
      );
      setValue(
        "storageAftUse",
        simPackageData.storage_after_use_id && aftUse?.id ? aftUse.id : ""
      );
      setSelectedDefUse(
        simPackageData.storage_for_defective_id && defUse?.id ? defUse : ""
      );
      setValue(
        "storageAftUseDef",
        simPackageData.storage_for_defective_id && defUse?.id ? defUse.id : ""
      );
      setValue("noteStorageBefUse", simPackageData.description_before_use);
      setValue("noteStorageAftUse", simPackageData.description_after_use);
      setValue(
        "noteStorageAftUseDef",
        simPackageData.description_for_defactive
      );

      setIsEdit(true);
    } else {
      setDateOfDel(dayjs());
      setValue("dateOfDel", dayjs());
    }
  }, [
    location.state,
    isLoadingStorageChoice,
    isLoadingActivationType,
    isLoadingCurrency,
    isLoadingSellCurrency,
    isLoadingCountries,
    isLoadingProvider,
    isLoadingSupplier,
    isLoadingReturnType,
    isLoadingScanType,
  ]);

  // Function to disable future dates
  const disabledDate = (current) => {
    return current && current.isAfter(dayjs().endOf("day"));
  };
  const simPackageCreator = useMutation(createSimPackage, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      navigate("/sim-package");
      await showToast({
        icon: "success",
        title: "SIM Package added successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });
  const simPackageEditor = useMutation(editSimPackage, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      setLoading(false);

      navigate("/sim-package");
      await showToast({
        icon: "success",
        title: "SIM Package edited successfully!",
        position: "top-end",
        timer: 1500,
      });
    },
  });

  const handleReset = () => {
    // Reset the form fields
    setSelecetedCurrency(null);
    setSelectedCountry(null);
    setSelectedActivationStatus(null);
    setSelectedAftUse();
    setSelectedBefUse();
    setSelectedDefUse();
    setSelectedProv();
    setSelectedReturnType();
    setSelectedScan();
    setSelectedScanPhone();
    setSelectedSupplier();
    setSelectedUnpackNeed();
    setSelecetedCurrency();
    setSelectedSellCurrency();
    reset();
  };

  const onSubmit = async (data) => {
    setApiError(false);
    // var defective_port_string = data.defective_port.join(',');

    const modulePermission = permissions?.permissions[simPackageModuleID];
    const formData = new FormData();

    isFieldAddableOrEditable(
      fieldIds.simPackage.date_of_delevery,
      modulePermission
    ) &&
      data.dateOfDel &&
      formData.append("date_of_delevery", convertDate(data.dateOfDel));

    isFieldAddableOrEditable(fieldIds.simPackage.name, modulePermission) &&
      data.packageName &&
      formData.append("name", data.packageName ? data.packageName : "");

    isFieldAddableOrEditable(
      fieldIds.simPackage.provider_id,
      modulePermission
    ) &&
      selectedProv &&
      formData.append("provider_id", selectedProv ? selectedProv.id : "");

    isFieldAddableOrEditable(fieldIds.simPackage.country, modulePermission) &&
      selectedCountry &&
      formData.append("country", selectedCountry ? selectedCountry.id : "");

    isFieldAddableOrEditable(
      fieldIds.simPackage.supplier_id,
      modulePermission
    ) &&
      selectedSupplier &&
      formData.append(
        "supplier_id",
        selectedSupplier ? selectedSupplier.id : ""
      );
    isFieldAddableOrEditable(
      fieldIds.simPackage.return_type,
      modulePermission
    ) &&
      selectedReturnType &&
      formData.append(
        "return_type",
        selectedReturnType ? selectedReturnType.id : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.last_give_back_date,
      modulePermission
    ) &&
      formData.append(
        "last_give_back_date",
        data.lastGiveBackDate ? convertDate(data.lastGiveBackDate) : ""
      );
    isFieldAddableOrEditable(
      fieldIds.simPackage.is_unpacking_needed,
      modulePermission
    ) &&
      selectedUnpackNeed &&
      formData.append(
        "is_unpacking_needed",
        selectedUnpackNeed ? selectedUnpackNeed.id : ""
      );
    isFieldAddableOrEditable(fieldIds.simPackage.scan, modulePermission) &&
      selectedScan &&
      formData.append("scan", selectedScan ? selectedScan.id : "");

    isFieldAddableOrEditable(
      fieldIds.simPackage.scan_phonenumber,
      modulePermission
    ) &&
      selectedScanPhone &&
      formData.append(
        "scan_phonenumber",
        selectedScanPhone ? selectedScanPhone.id : ""
      );

    isFieldAddableOrEditable(fieldIds.simPackage.priority, modulePermission) &&
      formData.append("priority", data.priority ? data.priority : "");

    isFieldAddableOrEditable(
      fieldIds.simPackage.delivery_date_cz_de,
      modulePermission
    ) &&
      formData.append(
        "delivery_date_cz_de",
        data.delieverydateCZZE ? convertDate(data.delieverydateCZZE) : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.delivery_date_to_supplier,
      modulePermission
    ) &&
      formData.append(
        "delivery_date_to_supplier",
        data.delDateSup ? convertDate(data.delDateSup) : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.activation_status,
      modulePermission
    ) &&
      selectedActivationStatus &&
      formData.append(
        "activation_status",
        selectedActivationStatus ? selectedActivationStatus.id : ""
      );

    isFieldAddableOrEditable(fieldIds.simPackage.note, modulePermission) &&
      formData.append("note", data.notes ? data.notes : "");

    isFieldAddableOrEditable(
      fieldIds.simPackage.amount_of_sim,
      modulePermission
    ) &&
      data.amountSim &&
      formData.append("amount_of_sim", data.amountSim ? data.amountSim : "");

    isFieldAddableOrEditable(fieldIds.simPackage.more_card, modulePermission) &&
      formData.append("more_card", data.moreCards ? data.moreCards : "");

    isFieldAddableOrEditable(
      fieldIds.simPackage.missing_cards,
      modulePermission
    ) &&
      formData.append(
        "missing_cards",
        data.missingCards ? data.missingCards : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.amount_of_card_given_back,
      modulePermission
    ) &&
      formData.append(
        "amount_of_card_given_back",
        data.amountGivenBack ? data.amountGivenBack : 0
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.thrown_away_card,
      modulePermission
    ) &&
      formData.append(
        "thrown_away_card",
        data.thrownAwayCards ? data.thrownAwayCards : 0
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.expire_date_to_activate_sim,
      modulePermission
    ) &&
      formData.append(
        "expire_date_to_activate_sim",
        data.expirationForActivation
          ? convertDate(data.expirationForActivation)
          : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.expire_date_after_activation,
      modulePermission
    ) &&
      formData.append(
        "expire_date_after_activation",
        data.expirationAfterActivation
          ? convertDate(data.expirationAfterActivation)
          : ""
      );

    isFieldAddableOrEditable(fieldIds.simPackage.currency, modulePermission) &&
      selecetedCurrency &&
      formData.append(
        "currency",
        selecetedCurrency ? selecetedCurrency.id : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.price_per_sim,
      modulePermission
    ) &&
      data.pricePerSim &&
      formData.append(
        "price_per_sim",
        data.pricePerSim ? data.pricePerSim : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.paid_amount,
      modulePermission
    ) && formData.append("paid_amount", data.paid ? data.paid : "");

    isFieldAddableOrEditable(fieldIds.simPackage.paid_date, modulePermission) &&
      formData.append(
        "paid_date",
        data.paidDate ? convertDate(data.paidDate) : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.sold_price_per_sim,
      modulePermission
    ) &&
      formData.append(
        "sold_price_per_sim",
        data.soldPricePerSim ? data.soldPricePerSim : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.storage_before_use_id,
      modulePermission
    ) &&
      selectedBefUse &&
      formData.append(
        "storage_before_use_id",
        selectedBefUse ? selectedBefUse.id : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.storage_after_use_id,
      modulePermission
    ) &&
      formData.append(
        "storage_after_use_id",
        selectedAftUse ? selectedAftUse.id : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.storage_for_defective_id,
      modulePermission
    ) &&
      formData.append(
        "storage_for_defective_id",
        selectedDefUse ? selectedDefUse.id : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.description_before_use,
      modulePermission
    ) &&
      formData.append(
        "description_before_use",
        data.noteStorageBefUse ? data.noteStorageBefUse : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.description_after_use,
      modulePermission
    ) &&
      formData.append(
        "description_after_use",
        data.noteStorageAftUse ? data.noteStorageAftUse : ""
      );

    isFieldAddableOrEditable(
      fieldIds.simPackage.description_for_defactive,
      modulePermission
    ) &&
      formData.append(
        "description_for_defactive",
        data.noteStorageAftUseDef ? data.noteStorageAftUseDef : ""
      );

    try {
      let response;
      if (isEdit) {
        response = await simPackageEditor.mutateAsync({
          id: location.state.data.id,
          data: formData,
        });
      } else {
        response = await simPackageCreator.mutateAsync(formData);
      }
    } catch (error) {
      if (error.response.status == 400) {
        setLoading(false)
        setApiError(error.response.data.message);
      } else {
      setLoading(false);

        showServerMessage();
      }
    }
  };

  return (
    <div>
       <LoadingOverlay isLoading={loadings} />
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover pt-3 pb-3">
              <Card.Header className="register-header">
                <Card.Title as="h4">
                  {isEdit ? "Edit" : "Add"} SIM package
                </Card.Title>
                <Breadcrumbs />
              </Card.Header>
            </Card>
            <Card>
              <Card.Body>
                {!permissionsLoading ? (
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col className="user-fields-wrapper">
                        <Row className="">
                          <fieldset>
                            <legend>SIM Package details</legend>
                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.date_of_delevery,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Package receive date">
                                      <span className="label-text">
                                        Package receive date
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <br />
                                    <Controller
                                      name="dateOfDel" // Field name in the form data
                                      control={control}
                                      defaultValue={dateOfDel}
                                      render={({ field }) => (
                                        <DatePicker
                                          value={field.value}
                                          onChange={(date) =>
                                            field.onChange(date)
                                          }
                                          disabledDate={disabledDate}
                                          format="DD/MM/YYYY"
                                          allowClear={false}
                                        />
                                      )}
                                      rules={{
                                        required:
                                          "Card receive date is required.",
                                      }} // Validation rules
                                    />
                                    <br />
                                    {errors.dateOfDel && (
                                      <span className="error-message">
                                        {errors.dateOfDel.message}
                                      </span>
                                    )}
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.name,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Package name">
                                      <span className="label-text">
                                        Package name
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      {...register("packageName", {
                                        required: true,
                                        maxLength: 50,
                                        minLength: 2,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.packageName?.type ===
                                        "required" &&
                                        "Package name is required."}
                                      {errors.packageName?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.packageName?.type ===
                                        "minLength" &&
                                        "Enter minimum 2 characters."}
                                      {errors.packageName &&
                                        errors.packageName.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.provider_id,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                providerChoices && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Provider"
                                      selectedOption={selectedProv}
                                      options={providerChoices}
                                      handleSelect={(e) => setSelectedProv(e)}
                                      control={control}
                                      errors={errors}
                                      name="provider"
                                      rules={{
                                        required: "Provider is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.country,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                allCountries && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Country"
                                      selectedOption={selectedCountry}
                                      options={allCountriesData}
                                      handleSelect={(e) =>
                                        setSelectedCountry(e)
                                      }
                                      control={control}
                                      errors={errors}
                                      name="country"
                                      rules={{
                                        required: "Country is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.supplier_id,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                supplierChoices && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Supplier"
                                      selectedOption={selectedSupplier}
                                      options={supplierChoicesData}
                                      handleSelect={(e) =>
                                        setSelectedSupplier(e)
                                      }
                                      control={control}
                                      errors={errors}
                                      name="supplier"
                                      rules={{
                                        required: "Supplier is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                            </Row>
                          </fieldset>
                          <fieldset>
                            <legend>SIM Package extra details</legend>

                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.return_type,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                ReturnTypeChoices && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Return type"
                                      selectedOption={selectedReturnType}
                                      options={returnTypeData}
                                      handleSelect={(e) =>
                                        setSelectedReturnType(e)
                                      }
                                      control={control}
                                      errors={errors}
                                      name="returnType"
                                      rules={{
                                        required: "Return type is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.last_give_back_date,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Last give back date">
                                      <span className="label-text">
                                        Last give back date
                                      </span>
                                      {selectedReturnType &&
                                        watch("returnType") &&
                                        selectedReturnType.id == 2 && (
                                          <span className="error-message">
                                            {" "}
                                            *
                                          </span>
                                        )}
                                    </label>
                                    <br />
                                    <Controller
                                      name="lastGiveBackDate" // Field name in the form data
                                      control={control}
                                      // defaultValue={lastGiveBackDate}
                                      render={({ field }) => (
                                        <DatePicker
                                          value={field.value}
                                          onChange={(date) =>
                                            field.onChange(date)
                                          }
                                          format="DD/MM/YYYY"
                                          allowClear={true}
                                        />
                                      )}
                                      rules={{
                                        required:
                                          selectedReturnType &&
                                          watch("returnType") &&
                                          selectedReturnType.id == 2
                                            ? "Last give back date is required."
                                            : undefined,
                                      }}
                                    />
                                    <br />
                                    {errors.lastGiveBackDate && (
                                        <span className="error-message">
                                          {errors.lastGiveBackDate.message}
                                        </span>
                                      )}
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.is_unpacking_needed,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                allCountries && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Unpacking needed?"
                                      selectedOption={selectedUnpackNeed}
                                      options={booleanOptions}
                                      handleSelect={(e) =>
                                        setSelectedUnpackNeed(e)
                                      }
                                      control={control}
                                      errors={errors}
                                      name="unPackingNeed"
                                      rules={{
                                        required:
                                          "Unpacking needed is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.scan,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                scanTypeChoices && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Scan"
                                      selectedOption={selectedScan}
                                      options={scanTypeData}
                                      handleSelect={(e) => setSelectedScan(e)}
                                      control={control}
                                      errors={errors}
                                      name="scan"
                                      rules={{
                                        required: "Scan is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.scan_phonenumber,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                booleanOptions && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Scan phone number"
                                      selectedOption={selectedScanPhone}
                                      options={booleanOptions}
                                      handleSelect={(e) =>
                                        setSelectedScanPhone(e)
                                      }
                                      control={control}
                                      errors={errors}
                                      name="scanPhone"
                                      rules={{
                                        required:
                                          "Scan phone number is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.priority,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Priority">
                                      <span className="label-text">
                                        Priority
                                      </span>
                                    </label>
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("priority", {
                                        pattern: {
                                          value: /^[0-9]+$/,
                                        },
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.priority?.type === "pattern" &&
                                        "Please enter a valid number."}
                                      {errors.priority &&
                                        errors.priority.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.delivery_date_cz_de,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Delievery date CZ-DE">
                                      <span className="label-text">
                                        Delievery date CZ-DE
                                      </span>
                                    </label>{" "}
                                    <br />
                                    <Controller
                                      name="delieverydateCZZE" // Field name in the form data
                                      control={control}
                                      defaultValue={delDateCZZE}
                                      render={({ field }) => (
                                        <DatePicker
                                          value={field.value}
                                          onChange={(date) =>
                                            field.onChange(date)
                                          }
                                          disabledDate={disabledDate}
                                          format="DD/MM/YYYY"
                                          allowClear={false}
                                        />
                                      )}
                                    />
                                    <br />
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.delivery_date_to_supplier,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Delievery date to supplier">
                                      <span className="label-text">
                                        Delievery date to supplier
                                      </span>
                                    </label>{" "}
                                    <br />
                                    <Controller
                                      name="delDateSup" // Field name in the form data
                                      control={control}
                                      defaultValue={delDateSup}
                                      render={({ field }) => (
                                        <DatePicker
                                          value={field.value}
                                          onChange={(date) =>
                                            field.onChange(date)
                                          }
                                          disabledDate={disabledDate}
                                          format="DD/MM/YYYY"
                                          allowClear={false}
                                        />
                                      )}
                                    />
                                    <br />
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.activation_status,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                activationChoices && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Activation status"
                                      selectedOption={selectedActivationStatus}
                                      options={activationChoicesData}
                                      handleSelect={(e) =>
                                        setSelectedActivationStatus(e)
                                      }
                                      control={control}
                                      errors={errors}
                                      name="actvationStatus"
                                      rules={{
                                        required:
                                          "Activation status is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.note,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="6">
                                  <Form.Group>
                                    <label title="Notes">
                                      <span className="label-text">Notes</span>
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      as="textarea"
                                      row={5}
                                      {...register("notes", {})}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.notes && errors.notes.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                          <fieldset>
                            <legend>SIM card counts</legend>

                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.amount_of_sim,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Amount (No. of SIM cards)">
                                      <span className="label-text">
                                        Amount (No. of SIM cards) (by Supplier)
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("amountSim", {
                                        maxLength: 10,
                                        required: true,
                                        pattern: {
                                          value: /^[0-9]+$/,
                                        },
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.amountSim?.type === "pattern" &&
                                        "Please enter a valid number."}
                                      {errors.amountSim?.type === "required" &&
                                        "Amount of SIM cards is required."}
                                      {errors.amountSim?.type === "maxLength" &&
                                        "It accepts maximum 10 characters."}
                                      {errors.amountSim &&
                                        errors.amountSim.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.more_card,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="More cards">
                                      <span className="label-text">
                                        More cards
                                      </span>
                                    </label>
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("moreCards", {
                                        maxLength: 10,
                                        pattern: {
                                          value: /^[0-9]+$/,
                                        },
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.moreCards?.type === "pattern" &&
                                        "Please enter a valid number."}
                                      {errors.moreCards?.type === "maxLength" &&
                                        "It accepts maximum 10 characters."}
                                      {errors.moreCards &&
                                        errors.moreCards.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.missing_cards,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Missing cards">
                                      <span className="label-text">
                                        Missing cards
                                      </span>
                                    </label>
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("missingCards", {
                                        maxLength: 10,
                                        pattern: {
                                          value: /^[0-9]+$/,
                                        },
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.missingCards?.type ===
                                        "pattern" &&
                                        "Please enter a valid number."}
                                      {errors.missingCards?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 10 characters."}
                                      {errors.missingCards &&
                                        errors.missingCards.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.amount_of_card_given_back,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Amount of card given back">
                                      <span className="label-text">
                                        Amount of card given back
                                      </span>
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue="0"
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("amountGivenBack", {
                                        maxLength: 10,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.amountGivenBack?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 10 characters."}
                                      {errors.amountGivenBack &&
                                        errors.amountGivenBack.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}

                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.thrown_away_card,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Thrown away cards">
                                      <span className="label-text">
                                        Thrown away cards
                                      </span>
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue="0"
                                      placeholder=""
                                      type="text"
                                      onKeyPress={handleKeyPress}
                                      {...register("thrownAwayCards", {
                                        maxLength: 10,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.thrownAwayCards?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 10 characters."}
                                      {errors.thrownAwayCards &&
                                        errors.thrownAwayCards.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                          <fieldset>
                            <legend>SIM card expiring details</legend>

                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.expire_date_to_activate_sim,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Expiration date for activation">
                                      <span className="label-text">
                                        Expiration date for activation
                                      </span>
                                      {selectedActivationStatus?.id != 1 && (
                                        <span className="error-message">
                                          {" "}
                                          *
                                        </span>
                                      )}
                                    </label>

                                    <br />
                                    <Controller
                                      name="expirationForActivation" // Field name in the form data
                                      control={control}
                                      defaultValue={expForActivation}
                                      {...register("expirationForActivation", {
                                        required:
                                          selectedActivationStatus?.id != 1
                                            ? true
                                            : false,
                                      })}
                                      render={({ field }) => (
                                        <DatePicker
                                          value={field.value}
                                          onChange={(date) =>
                                            field.onChange(date)
                                          }
                                          // disabledDate={disabledDate}
                                          format="DD/MM/YYYY"
                                          allowClear={false}
                                        />
                                      )}
                                    />
                                    <br></br>
                                    <span className="error-message">
                                      {selectedActivationStatus?.id != 1 &&
                                        errors.expirationForActivation?.type ===
                                          "required" &&
                                        "Expiration date for activation is required."}
                                      {errors.expirationForActivation &&
                                        errors.expirationForActivation.message}
                                    </span>
                                    <br />
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage
                                  .expire_date_after_activation,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Expiration date after activation">
                                      <span className="label-text">
                                        Expiration date after activation
                                      </span>
                                      {selectedActivationStatus?.id == 1 && (
                                        <span className="error-message">
                                          {" "}
                                          *
                                        </span>
                                      )}
                                    </label>

                                    <br />
                                    <Controller
                                      name="expirationAfterActivation" // Field name in the form data
                                      control={control}
                                      defaultValue={expAfterActivation}
                                      {...register(
                                        "expirationAfterActivation",
                                        {
                                          required:
                                            selectedActivationStatus?.id == 1
                                              ? true
                                              : false,
                                        }
                                      )}
                                      render={({ field }) => (
                                        <DatePicker
                                          value={field.value}
                                          onChange={(date) =>
                                            field.onChange(date)
                                          }
                                          // disabledDate={disabledDate}
                                          format="DD/MM/YYYY"
                                          allowClear={true}
                                        />
                                      )}
                                    />
                                    <br></br>
                                    <span className="error-message">
                                      {selectedActivationStatus?.id == 1 &&
                                        errors.expirationAfterActivation
                                          ?.type === "required" &&
                                        "Expiration date after activation is required."}
                                      {errors.expirationAfterActivation &&
                                        errors.expirationAfterActivation
                                          .message}
                                    </span>
                                    <br />
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                          <fieldset>
                            <legend>Payment</legend>

                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.currency,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                currencyChoices && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Currency"
                                      selectedOption={selecetedCurrency}
                                      options={currencyChoicesData}
                                      handleSelect={(e) =>
                                        setSelecetedCurrency(e)
                                      }
                                      control={control}
                                      errors={errors}
                                      name="currency"
                                      rules={{
                                        required: "Currency is required.",
                                      }}
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.price_per_sim,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Price per SIM">
                                      <span className="label-text">
                                        Price per SIM
                                      </span>
                                      <span className="error-message"> *</span>
                                    </label>

                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={(e) =>
                                        handleKeyPress(e, true)
                                      }
                                      {...register("pricePerSim", {
                                        maxLength: 10,
                                        required: true,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.pricePerSim?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 10 characters."}
                                      {errors.pricePerSim?.type ===
                                        "required" &&
                                        "Price per SIM is required."}
                                      {errors.pricePerSim &&
                                        errors.pricePerSim.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.paid_amount,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Paid">
                                      <span className="label-text">Paid</span>
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      type="text"
                                      onKeyPress={(e) =>
                                        handleKeyPress(e, true)
                                      }
                                      {...register("paid", {
                                        maxLength: 10,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.paid?.type === "maxLength" &&
                                        "It accepts maximum 10 characters."}
                                      {errors.paid && errors.paid.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.paid_date,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Paid Date">
                                      <span className="label-text">
                                        Paid date
                                      </span>
                                      {watch("paid") && (
                                        <span className="error-message">
                                          {" "}
                                          *
                                        </span>
                                      )}
                                    </label>

                                    <br />
                                    <Controller
                                      name="paidDate" // Field name in the form data
                                      control={control}
                                      defaultValue={paidDate}
                                      render={({ field }) => (
                                        <DatePicker
                                          value={field.value}
                                          onChange={(date) =>
                                            field.onChange(date)
                                          }
                                          disabledDate={disabledDate}
                                          format="DD/MM/YYYY"
                                          allowClear={false}
                                        />
                                      )}
                                      {...register("paidDate", {
                                        required: watch("paid") && true,
                                      })}
                                    />
                                    <br></br>
                                    <span className="error-message">
                                      {errors.paidDate?.type === "required" &&
                                        "Paid date is required."}
                                      {errors.paidDate?.type === "maxLength" &&
                                        "It accepts maximum 10 characters."}
                                      {errors.paidDate &&
                                        errors.paidDate.message}
                                    </span>
                                    <br />
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                          {(isFieldAddableOrEditable(
                            fieldIds.simPackage.sold_card,
                            permissions?.permissions[simPackageModuleID]
                          ) ||
                            isFieldAddableOrEditable(
                              fieldIds.simPackage.sold_price_per_sim,
                              permissions?.permissions[simPackageModuleID]
                            ) ||
                            isFieldAddableOrEditable(
                              fieldIds.simPackage.selling_currency,
                              permissions?.permissions[simPackageModuleID]
                            )) && (
                            <fieldset>
                              <legend>Sold</legend>

                              <Row>
                                {isFieldAddableOrEditable(
                                  fieldIds.simPackage.sold_card,
                                  permissions?.permissions[simPackageModuleID]
                                ) && (
                                  <Col className="" md="4">
                                    <Form.Group>
                                      <label title="Sold Cards">
                                        <span className="label-text">
                                          Sold cards
                                        </span>
                                      </label>{" "}
                                      <Form.Control
                                        defaultValue=""
                                        placeholder=""
                                        type="text"
                                        onKeyPress={handleKeyPress}
                                        {...register("soldCards", {
                                          maxLength: 10,
                                        })}
                                      ></Form.Control>
                                      <span className="error-message">
                                        {errors.soldCards?.type ===
                                          "maxLength" &&
                                          "It accepts maximum 10 characters."}
                                        {errors.soldCards &&
                                          errors.soldCards.message}
                                      </span>
                                    </Form.Group>
                                  </Col>
                                )}
                                {isFieldAddableOrEditable(
                                  fieldIds.simPackage.sold_price_per_sim,
                                  permissions?.permissions[simPackageModuleID]
                                ) && (
                                  <Col className="" md="4">
                                    <Form.Group>
                                      <label title="Sold price per SIM">
                                        <span className="label-text">
                                          Sold price per SIM
                                        </span>
                                      </label>{" "}
                                      <Form.Control
                                        defaultValue=""
                                        placeholder=""
                                        type="text"
                                        onKeyPress={(e) =>
                                          handleKeyPress(e, true)
                                        }
                                        {...register("soldPricePerSim", {
                                          maxLength: 10,
                                        })}
                                      ></Form.Control>
                                      <span className="error-message">
                                        {errors.soldPricePerSim?.type ===
                                          "maxLength" &&
                                          "It accepts maximum 10 characters."}
                                        {errors.soldPricePerSim &&
                                          errors.soldPricePerSim.message}
                                      </span>
                                    </Form.Group>
                                  </Col>
                                )}
                                {isFieldAddableOrEditable(
                                  fieldIds.simPackage.selling_currency,
                                  permissions?.permissions[simPackageModuleID]
                                ) &&
                                  currencySellChoices && (
                                    <Col className="" md="4">
                                      <CustomDrodown
                                        label="Selling currency"
                                        selectedOption={selectedSellCurrency}
                                        options={currencySellChoicesData}
                                        handleSelect={(e) =>
                                          setSelectedSellCurrency(e)
                                        }
                                        control={control}
                                        errors={errors}
                                        name="sellingCurrency"
                                      />
                                    </Col>
                                  )}
                              </Row>
                            </fieldset>
                          )}
                          <fieldset>
                            <legend>Storage</legend>

                            <Row>
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.storage_before_use_id,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                storageChoices && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Storage before use"
                                      selectedOption={selectedBefUse}
                                      options={
                                        isEdit
                                          ? selectedBefUse.length != 0
                                            ? [
                                                ...storageChoices?.storege_not_in_use,
                                                selectedBefUse,
                                              ]
                                            : storageChoices?.storege_not_in_use
                                          : storageChoices?.storege_not_in_use
                                      }
                                      handleSelect={(e) => setSelectedBefUse(e)}
                                      control={control}
                                      errors={errors}
                                      name="storageBefUse"
                                      rules={{
                                        required:
                                          "Storage before use is required.",
                                      }}
                                    />
                                  </Col>
                                )}

                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.storage_after_use_id,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                storageChoices && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Storage after use"
                                      selectedOption={selectedAftUse}
                                      options={
                                        isEdit
                                          ? selectedAftUse.length != 0
                                            ? [
                                                ...storageChoices?.storege_not_in_use,
                                                selectedAftUse,
                                              ]
                                            : storageChoices?.storege_not_in_use
                                          : storageChoices?.storege_not_in_use
                                      }
                                      handleSelect={(e) => setSelectedAftUse(e)}
                                      control={control}
                                      errors={errors}
                                      name="storageAftUse"
                                    />
                                  </Col>
                                )}

                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.storage_for_defective_id,
                                permissions?.permissions[simPackageModuleID]
                              ) &&
                                storageChoices && (
                                  <Col className="" md="4">
                                    <CustomDrodown
                                      label="Storage for defective SIM cards"
                                      selectedOption={selectedDefUse}
                                      options={
                                        isEdit
                                          ? selectedDefUse.length != 0
                                            ? [
                                                ...storageChoices?.storege_not_in_use,
                                                selectedDefUse,
                                              ]
                                            : storageChoices?.storege_not_in_use
                                          : storageChoices?.storege_not_in_use
                                      }
                                      handleSelect={(e) => setSelectedDefUse(e)}
                                      control={control}
                                      errors={errors}
                                      name="storageAftUseDef"
                                    />
                                  </Col>
                                )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.description_before_use,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Note for storage before use">
                                      <span className="label-text">
                                        Note for storage before use
                                      </span>
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      as="textarea"
                                      row={4}
                                      {...register("noteStorageBefUse", {
                                        maxLength: 200,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.noteStorageBefUse?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.noteStorageBefUse &&
                                        errors.noteStorageBefUse.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.description_after_use,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Note for storage after sse">
                                      <span className="label-text">
                                        Note for storage after use
                                      </span>
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      as="textarea"
                                      row={4}
                                      {...register("noteStorageAftUse", {
                                        maxLength: 200,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.noteStorageAftUse?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.noteStorageAftUse &&
                                        errors.noteStorageAftUse.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                              {isFieldAddableOrEditable(
                                fieldIds.simPackage.description_for_defactive,
                                permissions?.permissions[simPackageModuleID]
                              ) && (
                                <Col className="" md="4">
                                  <Form.Group>
                                    <label title="Note for storage for defective">
                                      <span className="label-text">
                                        Note for storage for defective
                                      </span>
                                    </label>{" "}
                                    <Form.Control
                                      defaultValue=""
                                      placeholder=""
                                      as="textarea"
                                      row={4}
                                      {...register("noteStorageAftUseDef", {
                                        maxLength: 200,
                                      })}
                                    ></Form.Control>
                                    <span className="error-message">
                                      {errors.noteStorageAftUseDef?.type ===
                                        "maxLength" &&
                                        "It accepts maximum 50 characters."}
                                      {errors.noteStorageAftUseDef &&
                                        errors.noteStorageAftUseDef.message}
                                    </span>
                                  </Form.Group>
                                </Col>
                              )}
                            </Row>
                          </fieldset>
                        </Row>
                      </Col>
                    </Row>

                    {(apiError || displayServerMessage) &&(<Col md="12">
                      {apiError && (
                        <FormAlertMessage message={apiError} type="error" />
                      )}
                      {displayServerMessage && (
                        <FormAlertMessage
                          message={serverErrorMessage}
                          type="error"
                        />
                      )}
                    </Col>)}
                    <div className="mt-3 mr-3">
                      <Button
                        className="btn-fill pull-right  add-user-button"
                        type="submit"
                        variant="info"
                      >
                        Save
                      </Button>
                      {isEdit ? (
                        <CancelButton />
                      ) : (
                        <Button
                          className="btn-fill pull-right  mr-2 reset-user-button"
                          type="button"
                          variant="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      )}
                    </div>

                    <div className="clearfix"></div>
                  </Form>
                ) : (
                  "Loading"
                )}
              </Card.Body>
            </Card>{" "}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddSimPackageForm;

