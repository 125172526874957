import React, { useState, useRef, useEffect } from "react";
import {
  useLocation,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";

import sidebarImg from "assets/img/6503e40d48dee.jpg";
import routes from "routes";
import ProtectedRoute from "ProtectedRoute";
import usePermissions from "customHooks/usePermissions";
import { canAccessModule } from "helpers/permissionChecker";
import { loginRedirector } from "helpers/loginRedirector";

function InnerLayout() {
  const location = useLocation();
  const mainPanel = useRef(null);
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    permissions,
  } = usePermissions();
  const navigate = useNavigate();
  const [redirectRoute, setRedirectRoute] = useState();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.classList.contains("nav-open")
    ) {
      document.documentElement.classList.toggle("nav-open");
      const element = document.getElementById("bodyClick");
      if (element) {
        element.parentNode.removeChild(element);
      }
    }
  }, [location]);

  useEffect(() => {
    const route = async () => {
      const path = await loginRedirector();
      if (path) {
        setRedirectRoute(path);
      }
    };
    route();
  }, []);

  useEffect(() => {
    const checkAccess = async () => {
      const currentRoute = routes.find(
        (route) => route.path === location.pathname
      );
      if (currentRoute && permissions) {
        const hasAccess = await canAccessModule(currentRoute, permissions);
        if (!hasAccess) {
          // const route = await loginRedirector();
          // navigate(route);
          navigate("/404");
        }
      }
    };

    checkAccess(); // Call the async function immediately

    return () => {
      // Cleanup code (if needed)
    };
  }, [location, permissions]);

  return (
    <>
      <div className="wrapper">
        <Sidebar color={"black"} image={sidebarImg} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Routes>
              {routes.map((route, idx) => {
                return (
                  route.element && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={
                        <ProtectedRoute
                          permission="users.email"
                          role="employee"
                          name={[route.name]}
                        >
                          {permissions &&
                            canAccessModule(route, permissions) && (
                              <route.element />
                            )}
                        </ProtectedRoute>
                      }
                    />
                  )
                );
              })}
              <Route
                path="/"
                element={<Navigate to={redirectRoute} replace />}
              />

              <Route path="*" element={<Navigate to="/404/" replace />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default InnerLayout;
