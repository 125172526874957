import { useState } from 'react';

const useSuccessMessage = (duration = 10000) => {
  const successMessageDuration = parseInt(process.env.REACT_APP_SUCCESS_MESSAGE_DURATION, 10);
  const [showMessage, setShowMessage] = useState(false);

  const showSuccessMessage = () => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, successMessageDuration);
  };

  return [showMessage, showSuccessMessage];
};

export default useSuccessMessage;
