import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { isAuthenticated } from "../../helpers/authHelper";
import LoginForm from "components/LoginForm/LoginForm";

const LoginView = () => {
  const navigate = useNavigate();

  useEffect(()=>{
    if (isAuthenticated()==true) {
      navigate("/dashboard");
    }
  })
  
  return (
    <div>
      <LoginForm />
    </div>
  );
};

export default LoginView;
