import React, { useState } from "react";

import { Card, Container, Row, Col } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { UserAddOutlined } from "@ant-design/icons";
import "./TableComponent.css";
import { useNavigate } from "react-router-dom";
import usePermissions from "customHooks/usePermissions";
import { isAddAllowed } from "helpers/permissionChecker";
import GenericTable from "components/GenericTable/GenericTable";
import { serverErrorMessage } from "helpers/serverErrorMessage";
import { cacheDuration } from "helpers/apiHelper";
import { Breadcrumbs } from "Breadcrumb";
import {
  getServicePartners,
  editServicePartners,
  deleteServicePartners,
} from "API/servicePartner";
import LoadingOverlay from "components/Overlay";

import { getAllCountries } from "API/supplierApi";
import { fieldIds } from "constants/moduleFields";
import ConfirmModal from "components/Modal/Modal";
import AddButton from "components/Buttons/AddButton";
import SearchFilter from "components/SearchFilter/SearchFilter";
import EditButton from "components/Buttons/EditButton";
import DeleteButton from "components/Buttons/DeleteButton";
import PasswordHideShow from "components/PasswordHideShow/PasswordHideShow";
import CheckboxDropdown from "components/CustomDropdown/CheckboxDropdown";
import { changeShowHideColumns } from "API/userRoleAPI";

const ServicePartnerTableComponent = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [showColumnState, setShowColumnState] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState();
  const [isPermissionChanged,setPermissionChanged]=useState(true)

  const [loadings, setLoading] = useState(false);

  const servicePartnerModuleID = parseInt(
    process.env.REACT_APP_SERVICE_PARTNER_MODULE_ID,
    10
  );

  const navigate = useNavigate();
  const queryParams = {
    page_size: pageSize,
    page: currentPage,
    search_filter: searchInput,
  };
  const {
    isLoading: permissionsLoading,
    isError: isPermissionsError,
    error: permissionsError,
    refetch: permissionRefetch,
    permissions,
  } = usePermissions();

  const {
    isLoading,
    isError,
    error,
    data: servicePartners,
    refetch,
  } = useQuery(["servicePartners", queryParams], getServicePartners, {
    cacheTime: cacheDuration,
  });

  const {
    isLoading: isLoadingCountries,
    isError: isErrorCountries,
    error: errorCountries,
    data: allCountries,
  } = useQuery("allCountries", getAllCountries, {
    cacheTime: cacheDuration,
  });

  const allCountriesTransformed = allCountries?.map((innerArray) => {
    return {
      id: innerArray[0],
      name: innerArray[1],
    };
  });

  const handlePageChange = (newPage, newSize) => {
    setCurrentPage(newPage);
    setPageSize(newSize);
    refetch();
  };

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    setCurrentPage(1);

    refetch();
  };
  const servicePartnerEditor = useMutation(editServicePartners, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: (response) => {
      refetch();
      setLoading(false);
    },
  });
  const columnsEditor = useMutation(changeShowHideColumns, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async (response) => {
      await refetch();
      await permissionRefetch();
      setLoading(false);
      setPermissionChanged(true)
    },
  });
  const removeServicePartner = useMutation(deleteServicePartners, {
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: async () => {
      refetch();
      setLoading(false);
    },
  });

  const handleEdit = (box) => {
    navigate("/edit-service-partner", { state: { data: box } });
  };

  const handleDelete = async (box) => {
    const deleteApi = async () => {
      try {
        const response = await removeServicePartner.mutateAsync({ id: box.id });
        return response;
      } catch (error) {
        setLoading(false);
        return error;
      }
    };

    await ConfirmModal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      onConfirm: deleteApi,
      deleteErrorText: "Service Partner is linked with modems!",
      deleteSuccessMessage: "Service Partner deleted successfully.",
    });
  };

  const handleToggle = async (id, status) => {
    const response = await servicePartnerEditor.mutateAsync({
      id: id,
      data: { status: status },
    });
    setLoading(false);

  };
  const handleColumnDropdown = async (menuOpen) => {
    if (!menuOpen&& selectedColumns) {
      const response = await columnsEditor.mutateAsync({
        id: servicePartnerModuleID,
        data: { field_list: selectedColumns?.map(Number) },
      });
    }
    setLoading(false);

  };

  const actionButtons = (record) => {
    const buttons = [];
    if (permissions.permissions[servicePartnerModuleID].edit_access) {
      buttons.push(
        <EditButton
          key={`edit-${record?.id}`}
          onChangeHandler={() => handleEdit(record)}
          record={record}
        />
      );
    }
    if (permissions.permissions[servicePartnerModuleID].delete_access) {
      buttons.push(
        <DeleteButton
          key={`delete-${record?.id}`}
          onChangeHandler={() => handleDelete(record)}
          record={record}
        />
      );
    }
    return <div className="action-buttons">{buttons}</div>;
  };

  const columns = [
    // {
    //   perm_id: 0,
    //   title: "Index",
    //   dataIndex: "index",
    //   sorter: (a, b) => a.index - b.index,
    //   width: 100,
    // },
    {
      perm_id: fieldIds.servicePartner.company_name,
      title: "Company name",
      dataIndex: "company_name",
      sorter: (a, b) => {
        return a.company_name.localeCompare(b.company_name);
      },
    },
    {
      perm_id: fieldIds.servicePartner.short_name,
      title: "Short name",
      dataIndex: "short_name",
      sorter: (a, b) => {
        return a.short_name.localeCompare(b.short_name);
      },
    },
    {
      perm_id: fieldIds.servicePartner.country,
      title: "Country",
      dataIndex: "country",
      sorter: (a, b) =>
        String(a.country ?? "").localeCompare(String(b.country ?? "")),
    },
    {
      perm_id: fieldIds.servicePartner.username,
      title: "Username",
      dataIndex: "username",
      sorter: (a, b) =>
        String(a.username ?? "").localeCompare(String(b.username ?? "")),
    },
    {
      perm_id: fieldIds.servicePartner.password,
      title: "Password",
      dataIndex: "password",
      render: (password) => (
        <div
          className="password-box"
          style={{
            maxWidth: "150px",
            width: "150px",
            textOverflow: "ellipsis",
            border: "1px solid #ddd",
            padding: "5px 10px",
            minWidth: "150px",
            borderRadius: "5px",
          }}
        >
          {password ? <PasswordHideShow password={password} /> : "-"}
        </div>
      ),
    },
    {
      perm_id: fieldIds.servicePartner.address,
      title: "Address",
      dataIndex: "address",
      render: (address) => (
        <div
          style={{

            width: "100%",
            whiteSpace: "pre-wrap",
          }}
        >
          {address ? address : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.address ?? "").localeCompare(String(b.address ?? "")),
    },
    {
      perm_id: fieldIds.servicePartner.registration_no,
      title: "Registration number",
      dataIndex: "registration_no",
      render: (registration_no) => (
        <div>
          {registration_no ? registration_no : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.registration_no ?? "").localeCompare(
          String(b.registration_no ?? "")
        ),
    },
    {
      perm_id: fieldIds.servicePartner.vat_number,
      title: "VAT number",
      dataIndex: "vat_number",
      render: (vat_number) => (
        <div className="center-aligned-text">
          {vat_number ? vat_number : "-"}
        </div>
      ),
      sorter: (a, b) =>
        String(a.vat_number ?? "").localeCompare(String(b.vat_number ?? "")),
    },
    {
      perm_id: fieldIds.servicePartner.website1,
      title: "Website 1",
      dataIndex: "website1",
      render: (website1) => (website1 ? website1 : "-"),
      sorter: (a, b) =>
        String(a.website1 ?? "").localeCompare(String(b.website1 ?? "")),
    },
    {
      perm_id: fieldIds.servicePartner.website2,
      title: "Website 2",
      dataIndex: "website2",
      render: (website2) => (website2 ? website2 : "-"),
      sorter: (a, b) =>
        String(a.website2 ?? "").localeCompare(String(b.website2 ?? "")),
    },
    {
      perm_id: fieldIds.servicePartner.interval_before_reuse,
      title: "Interval before reuse (Days)",
      width: "10%",
      dataIndex: "interval_before_reuse",
      render: (interval_before_reuse) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
          }}
        >
          {interval_before_reuse ? interval_before_reuse : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.interval_before_reuse) - Number(b.interval_before_reuse),
    },
    {
      perm_id: fieldIds.servicePartner.gray_profit,
      title: "Grey profit (USD)",

      dataIndex: "gray_profit",
      render: (gray_profit) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
          }}
        >
          {gray_profit ? gray_profit : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.gray_profit) - Number(b.gray_profit),
    },
    {
      perm_id: fieldIds.servicePartner.yellow_profit,
      title: "Yellow profit (USD)",

      dataIndex: "yellow_profit",
      render: (yellow_profit) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
          }}
        >
          {yellow_profit ? yellow_profit : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.yellow_profit) - Number(b.yellow_profit),
    },
    {
      perm_id: fieldIds.servicePartner.green_profit,
      title: "Green profit (USD)",

      dataIndex: "green_profit",
      render: (green_profit) => (
        <div
          className="center-aligned-text"
          style={{
            maxWidth: "100px",
            width: "100px",
            textOverflow: "ellipsis",
          }}
        >
          {green_profit ? green_profit : "-"}
        </div>
      ),
      sorter: (a, b) =>
        Number(a.green_profit) - Number(b.green_profit),
    },

    {
      perm_id: 0,
      title: "Action",
      dataIndex: "action",
      width: 150,
      maxWidth: 150,
      buttons: actionButtons(),

      render: (_, record) => actionButtons(record),
    },
  ];

  const pagination = {
    currentPage: currentPage,
    pageSize: pageSize,
    count: servicePartners?.count,
    onPageChange: handlePageChange,
  };

  let content;
  if (isLoading || permissionsLoading) {
    content = <div className="centered-container">Loading....</div>;
  } else if (isError || isPermissionsError) {
    content = <p>{error.message}</p>;
    content = <div className="centered-container">{serverErrorMessage}</div>;
  } else {
    content = (
      <GenericTable
        data={servicePartners?.results}
        columns={columns}
        permissionSet={permissions.permissions[servicePartnerModuleID]}
        handleEdit={handleEdit}
        handleToggle={handleToggle}
        showPagination={true}
        paginationData={pagination}
        showColumnState={showColumnState}
        choiceData={{
          country: allCountriesTransformed,
        }}
      />
    );
  }

  return (
    <Container fluid>
       <LoadingOverlay isLoading={loadings} />
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover pt-3 pb-3">
            <Card.Header className="register-header">
              <Card.Title as="h4">
                <UserAddOutlined /> Service Partner
              </Card.Title>
              <Breadcrumbs />
            </Card.Header>
          </Card>
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-0 pl-2 pr-2">
              <div className="filters-row">
                <Row className="mb-3 gap-3 justify-space">
                  <Col
                    md="6"
                    className="d-flex align-items-center gap-2 filter-col-wrapper"
                  >
                    <CheckboxDropdown
                      title="Colunms"
                      items={
                        permissions?.permissions[servicePartnerModuleID].fields ||
                        []
                      }
                      onSelect={(e) => {
                        setSelectedColumns(e);
                      }}
                      filterName="Colunms"
                      moduleId={servicePartnerModuleID}
                      handleDropdownClose={handleColumnDropdown}
                      isPermissionChanged={isPermissionChanged} 
                      setIsPermissionChanged={setPermissionChanged}
                    />
                    <SearchFilter
                      onChangeHandler={handleSearch}
                      valueInput={searchInput}
                      placeholder="Search | Name"
                    />
                  </Col>
                  <Col
                    md="4"
                    className="d-flex justify-content-end align-items-center top-table-buttons"
                  >
                  
                    {isAddAllowed(
                      permissions.permissions[servicePartnerModuleID]
                    ) ? (
                      <AddButton
                        onClickHandler={(e) => navigate("/add-service-partner")}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </div>
              {content}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ServicePartnerTableComponent;
