import { makeRequest } from "helpers/apiHelper";

export const getCCMList = async (params) => {
  let queryParams = params.queryKey[1];
  const queryParamsString = Object.keys(queryParams)
    .filter((key) => queryParams[key] !== "")
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  const url = `/custommodule/log/?${queryParamsString}`;
  return makeRequest("get", url);
};

export const getCCMid = async (data) => {
  const dataset = data.queryKey[1] ?? '';
  const url = `/custommodule/log/${dataset}` + (dataset ? '/' : '');
  return makeRequest("get", url);
};

export const createCCMList = async (data) => {
  return makeRequest("post", "/custommodule/log/", data);
};

export const editCCMList = async ({ id, data }) => {
  return makeRequest("patch", `/custommodule/log/${id}/`, data);
};

export const deleteCCMList = ({ id }) => {
  return makeRequest("delete", `/custommodule/log/${id}/`);
};

