import React, { useState, useEffect } from "react";

import { Table } from "react-bootstrap";
import { Switch } from "antd";

const RolePermissionTable = ({
  modulePermissions,
  customFieldNames,
  onPermissionsChange,
  serialNumber,
}) => {
  const [permissions, setPermissions] = useState(modulePermissions);

  useEffect(() => {
    onPermissionsChange(permissions);
  }, [permissions]);

  useEffect(() => {
    // Set add_edit_access to true for fields with is_required initially
    const updatedPermissions = { ...permissions };

    if (updatedPermissions.fields) {
      Object.keys(updatedPermissions.fields).forEach((fieldId) => {
        const field = updatedPermissions.fields[fieldId];
        if (
          field.is_required &&
          (updatedPermissions.edit_access || updatedPermissions.add_access)
        ) {
          field.add_edit_access = true;
          field.is_add_editable = true;
        }
        if (field.add_edit_access || field.view_access) {
          field.view_access = true;
        }
      });
    }

    if (updatedPermissions.add_access || updatedPermissions.edit_access) {
      updatedPermissions.view_access = true;
    } else if (
      updatedPermissions.edit_access &&
      updatedPermissions.add_access &&
      !updatedPermissions.view_access
    ) {
      updatedPermissions.add_access = false;
      updatedPermissions.edit_access = false;
    }

    setPermissions(updatedPermissions);
  }, []);

  const isAnyColumnShowTure = (permissionsData) => {
    const retrunFlag = false;
    if (permissionsData.fields) {
      return Object.values(permissions.fields).some(
        (field) => field.is_column_visible
      );
    }
    return retrunFlag;
  }

  const handleTogglePermission = (permissionType, newStatus) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = {
        ...prevPermissions,
        [permissionType]: !prevPermissions[permissionType],
      };

      // If "Is Editable?" is turned on, turn on "Is Addable?" as well
      if (
        permissionType === "edit_access" &&
        !prevPermissions[permissionType] &&
        newStatus
      ) {
        updatedPermissions.add_access = true;
      }
      // If "Is Visible?" is turned off, turn off other permissions
      if (permissionType === "view_access" && !newStatus) {
        updatedPermissions.add_access = false;
        updatedPermissions.edit_access = false;
        updatedPermissions.delete_access = false;

        // Unselect all fields
        if (updatedPermissions.fields) {
          Object.keys(updatedPermissions.fields).forEach((fieldId) => {
            const field = updatedPermissions.fields[fieldId];
            field.view_access = false;
            field.add_edit_access = false;
            field.column_show = false;
          });
        }
      }

      // //one field should turn on when providing module view access
      const arrayOfFieldIdObjects = [1, 22, 38, 40, 51, 64, 74, 88];

      if (updatedPermissions.view_access) {
        const field = updatedPermissions.fields;

        arrayOfFieldIdObjects.forEach((val) => {
          if (field && field[val]) {
            // Grant view access to the specific field
            field[val].view_access = true;
            field[val].column_show = true;
          }
        });
      }
      // //

      if (
        (permissionType === "edit_access" ||
          permissionType === "add_access" ||
          permissionType === "delete_access") &&
        !prevPermissions[permissionType] &&
        newStatus
      ) {
        updatedPermissions.view_access = true;

        if (updatedPermissions.fields) {
          Object.keys(updatedPermissions.fields).forEach((fieldId) => {
            const field = updatedPermissions.fields[fieldId];

            if (
              field.is_required &&
              (updatedPermissions.edit_access || updatedPermissions.add_access)
            ) {
              field.add_edit_access = true;
              field.is_add_editable = true;
            }
            if (field.add_edit_access || field.view_access) {
              field.view_access = true;
            }
          });
        }
      } else if (
        updatedPermissions.edit_access &&
        updatedPermissions.add_access &&
        !updatedPermissions.view_access
      ) {
        updatedPermissions.add_access = false;
        updatedPermissions.edit_access = false;
      }

      return updatedPermissions;
    });
  };

  const handleToggleFields = (isChecked, fieldId, permissionType) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = { ...prevPermissions };

      if (
        updatedPermissions.fields &&
        updatedPermissions.fields[fieldId] &&
        typeof updatedPermissions.fields[fieldId][permissionType] !==
          "undefined"
      ) {
        updatedPermissions.fields[fieldId][permissionType] = isChecked;

        // Check if "View Access" is turned off for the field
        if (permissionType === "view_access" && !isChecked) {
          // Turn off "Column Show" for the field
          updatedPermissions.fields[fieldId].column_show = false;
        }

        // Check if all field view accesses are turned off
        const allFieldViewAccessOff = Object.values(updatedPermissions.fields)
          .filter((field) => field.is_viewable)
          .every((field) => !field.view_access);

        // If all field view accesses are turned off, turn off module view access
        if (allFieldViewAccessOff) {
          updatedPermissions.view_access = false;
        }
      }

      return updatedPermissions;
    });
  };

  return (
    <div className="user-role-container">
      <fieldset>
        <legend>
          {serialNumber}. {modulePermissions.name=="Sim Package"?"SIM Package":modulePermissions.name} permissions
        </legend>
        <span className="switch-secion" style={{gap:"50px"}}>
          <div style={{ display: "flex", alignItems: "center",justifyContent:"center",width:"150px" }}>
            <label htmlFor="view-access" className="role-permission-toggle">
              Is visible?
            </label>
            {"  "}
            <Switch
              checked={permissions.view_access}
              onChange={(newStatus) => {
                handleTogglePermission("view_access", newStatus);
              }}
            />
          </div>
          {modulePermissions.is_addable && (
                     <div style={{ display: "flex", alignItems: "center",justifyContent:"center",width:"180px" }}>

              <label htmlFor="add-access" className="role-permission-toggle">
                Is addable?
              </label>
              {"  "}
              <Switch
                checked={permissions.add_access || permissions.edit_access}
                disabled={permissions.edit_access} // Disable the switch when edit_access is true
                onChange={(newStatus) => {
                  handleTogglePermission("add_access", newStatus);
                }}
              />
            </div>
          )}{" "}
          {modulePermissions.is_editable && (
                     <div style={{ display: "flex", alignItems: "center",justifyContent:"center",width:"180px" }}>

              <label htmlFor="edit-access" className="role-permission-toggle">
                Is editable?
              </label>
              {"  "}
              <Switch
                checked={permissions.edit_access}
                onChange={(newStatus) => {
                  handleTogglePermission("edit_access", newStatus);
                }}
              />
            </div>
          )}
          {modulePermissions.is_deletable && (
                    <div style={{ display: "flex", alignItems: "center",justifyContent:"center",width:"180px" }}>

              <label htmlFor="delete-access" className="role-permission-toggle">
                Is deletable?
              </label>
              {"  "}
              <Switch
                checked={permissions.delete_access}
                onChange={(newStatus) => {
                  handleTogglePermission("delete_access", newStatus);
                }}
              />
            </div>
          )}{" "}
        </span>
        {permissions.view_access &&
        Object.keys(permissions.fields).length > 0 ? (
          <Table className="table-hover table-striped role-table">
            <thead>
              <tr>
                <th>Field name</th>
                <th>View access</th>
                <th>
                  {permissions?.add_access &&
                    !permissions?.edit_access &&
                    "Add access"}
                  {permissions?.add_access &&
                    permissions?.edit_access &&
                    "Add/Edit access"}
                </th>
                { isAnyColumnShowTure(permissions) && (<th>Default visible fields</th>)}
              </tr>
            </thead>
            <tbody>
              {Object.entries(permissions.fields).map(([fieldId, field]) => (
                <tr key={fieldId}>
                  <td>
                    {customFieldNames && customFieldNames[fieldId] ? (
                      <>
                        {`${customFieldNames[fieldId]}`}
                        {field?.is_required && (
                          <span className="error-message"> *</span>
                        )}
                      </>
                    ) : (
                      "Field name missing"
                    )}
                  </td>
                  <td>
                    {field.is_viewable &&
                      ({ fieldId },
                      (
                        <Switch
                          checked={field.view_access}
                          disabled={
                            field.add_edit_access &&
                            (permissions.edit_access || permissions.add_access)
                          } // Disable the switch when edit_access is true
                          onChange={(newStatus) => {
                            handleToggleFields(
                              newStatus,
                              fieldId,
                              "view_access"
                            );
                          }}
                        />
                      ))}
                  </td>
                  <td>
                    {field.is_add_editable &&
                      (permissions.add_access || permissions.edit_access) && (
                        <Switch
                          checked={field.add_edit_access}
                          disabled={field.is_required}
                          onChange={(newStatus) => {
                            handleToggleFields(
                              newStatus,
                              fieldId,
                              "add_edit_access"
                            );
                            newStatus &&
                              handleToggleFields(
                                newStatus,
                                fieldId,
                                "view_access"
                              );
                          }}
                        />
                      )}
                  </td>

                  <td>
                    {field.is_column_visible && (
                      <Switch
                        checked={field.column_show}
                        onChange={(newStatus) => {
                          handleToggleFields(newStatus, fieldId, "column_show");
                          newStatus &&
                            handleToggleFields(
                              newStatus,
                              fieldId,
                              "view_access"
                            );
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : null}
      </fieldset>
      <h4></h4>
    </div>
  );
};

export default RolePermissionTable;
